import * as Dictionary from "../../constants/dictionary";

import MegaphoneIcon from "../../atoms/MegaphoneIcon/MegaphoneIcon";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import Button from "../../atoms/Button/Button";

import "./checked-test-card.scss";

export default function CheckedTestCard({information, checked}) {
  let icon : any;
  information["name"] = information["name"]?.toLowerCase()
  switch (information["name"]) {
    default:
      icon = <MegaphoneIcon className="bi bi-megaphone ms-2 color-blue fs-checked-test-card-0"/>
  }

  return (
    <div className={"wr-checked-test-card hr-checked-test-card d-flex justify-content-end align-items-center border rounded-4"}>
      <div className='w-90 h-100'>
        <div className={ "w-100 h-100 d-flex align-items-center p-1" }>
          <div className="text-center p-0 me-4">
            {icon}
          </div>
          <div className="h-100">
            <div className="h-60">
              <Paragraph contentTwo={information["name"]} className={"fs-checked-test-card-1 fw-bold m-0 text-truncate mt-1"}/>
            </div>
            <div className="me-3">
              {
                information["updated_at"] !== null ?
                <div className="d-flex">
                  <Paragraph contentTwo={Dictionary.terms["updated_at"] + ":"} capital={false} className={"text-secondary fs-checked-test-card-2 m-0  me-1"}/>
                  <Paragraph contentTwo={'10/10/2022'} capital={false} className={"text-secondary fs-checked-test-card-2 m-0"}/>
                  {/* <Paragraph contentTwo={information["updated_at"]} capital={false} className={"text-secondary fs-checked-test-card-2 m-0"}/> */}
                </div>
                :
                <div className="d-flex">
                  <Paragraph contentTwo={Dictionary.terms["updated_at"] + ":"} capital={false} className={"text-secondary fs-checked-test-card-2 m-0 me-1"}/>
                  <Paragraph contentTwo={'10/10/2022'} capital={false} className={"text-secondary fs-checked-test-card-2 m-0"}/>
                  {/* <Paragraph contentTwo={information["created_at"]} capital={false} className={"text-secondary fs-checked-test-card-2 m-0"}/> */}
                </div>
              }
            </div>
          </div>
            <Button text={'Ver prueba'} className='btn color-blue fs-checked-test-card-1 p-0' />
        </div>
      </div>
    </div>
  )
}
