import EditTemplateTrigger from "../domain/templates/edit-template-trigger/edit-template-trigger";
import EditTemplateForm from "../domain/templates/edit-template-form/edit-template-form";

export default function SessionsEdit() {
  return (
    <>
    <EditTemplateTrigger />
    <div className="modal fade" id="sessionEdit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> 
      <EditTemplateForm />
    </div>
    </>
  )
}
