import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import FileInput from "../../../../common/domain/atoms/FileInput/FileInput";
import FormText from "../../../../common/domain/atoms/FormText/FormText";
import BoxesIcon from "../../../../common/domain/atoms/BoxesIcon/BoxesIcon";

import "./skills-edit-organism-data.scss";

export default function SkillsEditOrganismData() {
  const title : string = Dictionary.informationString + " " + Dictionary.ofString + " " + Dictionary.theOneString + " " + Dictionary.skillString;

  return (
    <div className="d-flex flex-column align-items-center w-25">
      <Paragraph contentTwo={title + ":"} className="m-0 fs-skills-edit-data-1 text-center border-bottom" />
      <div className="d-flex flex-column align-items-center justify-content-center mt-3 w-75">
        <BoxesIcon className="bi bi-boxes fs-skills-edit-data-0 color-blue" />
        <FileInput label={Dictionary.updataIconString} id='updata-skill-icon' accept='image/*' />
      </div>
      <div className="w-100 d-flex flex-column justify-content-around h-60">
        <FormText id={"name"} name={"name"} placeholder={Dictionary.nameString} label={Dictionary.nameString} />
      </div>
    </div>
  )
}