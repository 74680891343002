//Constants-Redux
import * as Constants from '../constants'
import {errorUserPassword} from "../../../auth/domain/constants/dictionary"

//Types-Redux
import {State, Action} from '../types'

const initialState: State = {
  userName: "",
  users : [],
  token: null,
  validateToken: null,
  created : "",
  table: {title: "", data: []},
  tableOptions: [],
  testQuestionTypes: [],
  detail_item_table: {},
  alertMsg : "",
  tabTests: [],
  testQuestionOptions: [],
  userTestResponse: [],
  userList : [],
  profilesTable : [],
  permissionsTable : [],
  optionsProfiles : [],
  optionsPermissions : [],
  optionsTests : [],
  skills: {},
  tests: {data:[]},
  testsGroup : [],
  candidates: {},
  candidatesListToTable: [],
  candidatesAmount: 0,
  report: "",
  sessions: [],
  sessionCandidates: [],
  evaluators: [],
  departments: [],
  documentTypes: [],
  createFeedback: '',
  business: [],
  municipalities: [],
  domainantHands: [],
  genders: [],
  headquarters: [],
  dashboardAllBusiness: { datasets: [], labels: [] },
  dashboardNextProcessBusiness: [],
  dashboardNextProcessBusinessChart: { datasets: [], labels: [] },
  dashboardNextProcessBusinessHeadquartersChart: { datasets: [], labels: [] },
  dashboardNormalDistributionChart: { datasets: [], labels: [] },
}


export default function rootReducer(state: State = initialState, action: Action) {
  switch (action.type) {
    case Constants.LOGIN:
      if (action.payload === errorUserPassword) {
        return {...state, token: false}
      } else {
        Constants.myStorage.setItem("token", action.payload)
        return {
          ...state,
          token: true
        };
      }

    case Constants.LOGIN_CONFIRM_TOKEN:
      if(action.payload === "Token valido"){
        return {...state, validateToken: true}
      } else {
        return {...state, validateToken : false}
      }

    case Constants.REGISTER:
      return {
        ...state,
        created: action.payload
      }

    case Constants.RECOVERY_PASSWORD:
      return {
        ...state,
        alertMsg: action.payload
      }

    case Constants.CHANGE_PASSWORD:
      return {
        ...state,
        alertMsg: action.payload
      }

    case Constants.REGISTER_CLEAR_STATE:
      return {
        ...state,
        created: action.payload
      }

    case Constants.GET_TABLE:
    if(action.payload === "La prueba no existe"){
      return{
        ...state
      }
    }else{
      return{
        ...state,
        table: action.payload
      }
    }
    
    case Constants.GET_TABLE_PERMISSIONS:
      return {
        ...state,
        permissionsTable : action.payload
      }
    
    case Constants.GET_TABLE_PROFILES:
      return {
        ...state,
        profilesTable : action.payload
      }
    
    case Constants.CREATE_ITEM_IN_TABLE:
      return {
        ...state,
        createFeedback: action.payload,
      };

    case Constants.UPDATA_ITEM_IN_TABLE:
      return state;

    case Constants.DELETE_ITEM_IN_TABLE:
      return state;
  
    case Constants.GET_ITEM_DETAIL_TABLE:
      return {
        ...state,
        detail_item_table: action.payload
      }

    case Constants.LOGOUT:
      Constants.myStorage.clear()
      return state;

    case Constants.GET_USER:
      return {
        ...state, 
        userName: action.payload
      }

    case Constants.UPDATE_USER:
      return {
        ...state,
        alertMsg: action.payload
      };

    case Constants.GET_TABLE_OPTIONS:
      if(action.payload["data"]){
        return{
          ...state, 
          tableOptions: action.payload["data"]
        }
      } else {
        return{
          ...state, 
          tableOptions: action.payload
        }
      }

    case Constants.GET_TEST_QUESTION_TYPES:
      return {
        ...state,
        testQuestionTypes: action.payload["data"]
      }

    case Constants.GET_TAB_TABLES:
      return {
        ...state,
        tabTests: action.payload
      }
    
    case Constants.GET_QUESTION_OPTIONS:
      return {
        ...state,
        testQuestionOptions: [...state["testQuestionOptions"], action.payload]
      }

    case Constants.GET_USER_TEST_RESPONSE:
      return {
        ...state,
        userTestResponse: action.payload
      }

    case Constants.CREATE_LINK_USER_TESTS:
      return state

    case Constants.GET_LIST_USER:
      return {
        ...state,
        userList : action.payload
      }

    case Constants.GET_OPTIONS_PROFILES:
      return {
        ...state,
        optionsProfiles : action.payload
      }

    case Constants.GET_OPTIONS_PERMISSIONS:
      return {
        ...state,
        optionsPermissions: action.payload
      }

    case Constants.GET_OPTIONS_TESTS:
      return {
        ...state,
        optionsTests: action.payload
      }

    case Constants.GET_ALL_SKILLS:
      return {
        ...state,
        skills: action.payload
      }

    case Constants.GET_ALL_TESTS:
      return {
        ...state,
        tests: action.payload
      }

    case Constants.GET_ALL_TESTS_GROUP:
      return {
        ...state,
        testsGroup: action.payload
      }

    case Constants.GET_ALL_TESTS_OF_SKILL:
      return {
        ...state,
        tests: action.payload
      }

    case Constants.CREATE_NEW_CANDIDATE:
      return state;

    case Constants.CREATE_NEW_USER:
      return state;
    
      case Constants.CREATE_NEW_CUSTOMER:
      return state;    

    case Constants.GET_CANDIDATES_WITH_FILTER:
      return {
        ...state, 
        candidates: action.payload,
      }
    
    case Constants.GET_CANDIDATES_FILTER_AND_PAGINATION:
      return {
        ...state,
        candidatesListToTable: action.payload
      }

    case Constants.GET_CANDIDATES_AMOUNT:
      return {
        ...state,
        candidatesAmount: action.payload
      }

    case Constants.GET_EXCEL_REPORT:
      return {
        ...state,
        report: action.payload
      }
      
    case Constants.GET_USERS:
      return{
        ...state,
        users: action.payload,
      }

    case Constants.GET_ALL_SESSIONS:
      return {
        ...state,
        sessions: action.payload
      }
    case Constants.GET_SESSION_CANDIDATES:
      return {
        ...state,
        sessionCandidates: action.payload
      }
    case Constants.GET_ALL_EVALUATORS:
      return {
        ...state,
        evaluators: action.payload
      }
    
    case Constants.CREATE_NEW_SESSION:
      return state;

    case Constants.GET_ALL_DEPARTMENTS:
      return {
        ...state,
        departments: action.payload,
      };

    case Constants.GET_ALL_DOCUMENT_TYPE:
      return {
        ...state,
        documentTypes: action.payload
      };

    case Constants.GET_ALL_BUSINESS:
      return {
        ...state,
        business : action.payload,
      }

    case Constants.GET_ALL_MUNICIPALITIES:
      return {
        ...state,
        municipalities: action.payload,
      };

    case Constants.GET_ALL_DOMAINANT_HAND:
      return{
        ...state,
        domainantHands: action.payload
      }
    
    case Constants.GET_ALL_GENDERS:
      return{
        ...state,
        genders: action.payload,
      };

    case Constants.GET_ALL_HEADQUARTERS:
      return{
        ...state,
        headquarters: action.payload,
      };

    case Constants.GET_MUNICIPALITIES_BY_DEPARTMENT:
      return{
        ...state,
        municipalities: action.payload,
      };

    case Constants.GET_DASHBOARD_ALL_BUSINESS:
      return{
        ...state,
        dashboardAllBusiness: action.payload
      };

    case Constants.GET_DASHBOARD_NEXT_PROCESS_BUSINESS:
      return{
        ...state,
        dashboardNextProcessBusiness: action.payload
      };

    case Constants.GET_DASHBOARD_NEXT_PROCESS_BUSINESS_CHART:
      return{
        ...state,
        dashboardNextProcessBusinessChart: action.payload
      };

    case Constants.GET_DASHBOARD_NEXT_PROCESS_BUSINESS_HEADQUARTERS_CHART:
      return{
        ...state,
        dashboardNextProcessBusinessHeadquartersChart: action.payload
      };

    case Constants.GET_DASHBOARD_NORMAL_DISTRIBUTION_CHART:
      return{
        ...state,
        dashboardNormalDistributionChart: action.payload
      };
      
    default:
      return state;
  }
}