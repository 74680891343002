import * as Dictionary from "../../constants/dictionary"
import SettingsIcon from "../SettingsIcon/SettingsIcon";

export default function SelectInput({id = "", name = "", text = "", options, required = false, defaultValue = "", firstOption = Dictionary.selectAnOptionString,
  classLabel = "fs-form-text-0 mx-4 mb-1 fw-bold", onChange = (e) => {}, classSelect = "text-center w-85 py-2 rounded-4 border fs-form-text-0 mb-2 mx-3", classDiv='d-flex flex-column'}) {
    
  let selected : boolean = false;
  if(defaultValue !== ""){
    selected = true;
    options = options?.filter((option:any) => {
      return option.uuid === `${defaultValue}`;
    });
  };
  return (
    <div className={classDiv}>
      {
        text !== "" &&
        <label className={classLabel}>{text}</label>
      }
      {
        required ?
        <select defaultValue={""} className={classSelect} name={name} id={id} onChange={e => onChange(e)} required >
          <option key={"default"} value={""}>{firstOption}</option>
            {
              options?.map((o : any) => (
                <option key={o.uuid} value={o.uuid} id={id} selected={selected}>{Dictionary.terms[o.name] ? Dictionary.terms[o.name] : o.name}</option>
              ))
            }
        </select>
        :
        <select defaultValue={""} className={classSelect} name={name} id={id} onChange={e => onChange(e)}>
          <option key={"default"} value={""}>{firstOption}</option>
            {
              options?.map((o : any) => (
                <option key={o.uuid} value={o.uuid} id={id} selected={selected}>{Dictionary.terms[o.name] ? Dictionary.terms[o.name] : o.name}</option>
              ))
            }
        </select>
      }
    </div>
  );
};
