export const FORM_CONSTANTS = {
    STATE: {name: 'state', type: 'selector', options:[{uuid: '123asdqwe321', name: 'Opción 1'},{uuid: '123asdewq321', name: 'Opción 2'},{uuid: '123qweqwe321', name: 'Opción 3'}]},
    DATE: {name: 'datetime', type: 'date'},
    CANDIDATES: {name: 'candidates', type: 'number', placeholder: 'ej. 5'},
    START_TIME: {name: 'statTime', type: 'text', placeholder: 'ej. 8:00 am'},
    FINISH_TIME: {name: 'finishTime', type: 'text', placeholder : 'ej. 10:00 am'},
    URL: {name: 'url', type: 'text', placeholder: 'ej. www.ejemplo.com.co'},
    HEADQUARTERS: {name: 'headquartersId', type: 'text', placeholder: 'ej. sede principal'},
    CITY: {name: 'city', type: 'selector', options:[{uuid: '789asdqwe321', name: 'Opción 1'},{uuid: '789asdewq321', name: 'Opción 2'},{uuid: '789qweqwe321', name: 'Opción 3'}]},
    ANDRESS: {name: 'andress', type: 'text', placeholder: 'ej. cl 1 # 2-3'},
    DEPARTMENT: {name: 'department', type: 'text', placeholder: 'Departamento'}
}