import * as Dictionary from "../../common/domain/constants/dictionary";

import { useState } from "react";

import AssignTestsGroupModalTemplateHeader from "../domain/templates/assign-testsGroup-modal-template-header/assign-testsGroup-modal-template-header";
import AssignTestsGroupModalTemplateBody from "../domain/templates/assign-testsGroup-modal-template-body/assign-testsGroup-modal-template-body";
import AssignTestsGroupModalTemplateFooter from "../domain/templates/assign-testsGroup-modal-template-footer/assign-testsGroup-modal-template-footer";

import "./feature-assign-testsGroup.scss";

export default function AssignTestsGroupModal({information}) {
  const [testGroup, setTestGroup] = useState("")
  const [assign, setAssign] = useState(false);   
  return (
    <>
    <button type="button" className="btn border rounded-4 shadow w-100 h-100 fs-assing-testgroup-0 fw-bold bg-color-blue text-white py-1" data-bs-toggle="modal" data-bs-target={`#assignTestsGroup${information.uuid}`}>
      {Dictionary.groupTestsString}
    </button>

    <div className="modal fade" id={`assignTestsGroup${information.uuid}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xl h-75 tl-assign-testGroup">
        <div className="modal-content border rounded-4 p-0 m-0 shadow-xl">
          <AssignTestsGroupModalTemplateHeader/>
          <AssignTestsGroupModalTemplateBody assign={assign} setTestGroup={setTestGroup} testGroup={testGroup}/>
          <AssignTestsGroupModalTemplateFooter setTestGroup={setTestGroup} setAssign={setAssign} information={information} testGroup={testGroup}/>         
        </div>
      </div>
    </div>
    </>
  )
}


