import Paragraph from "../../atoms/Paragraph/Paragraph";
import ProgressCircularTwo from "../../atoms/ProgressCircularTwo/ProgressCircularTwo";
import "./TestValorationBCards.scss";
export default function TestValorationBCard({title, data}) {
  return (
    <div className="rounded-4 bg-white w-80 shadow container py-3 position-relative d-flex flex-column justify-content-center">
      <Paragraph contentTwo={'Destreza que se evalúa: ' + title} className="m-0 text-primary fw-bold fs-7" />
      <Paragraph contentTwo={data?.concept} className="fs-6 m-0 lh-1 w-80 text-justify fw-bold mt-1" />
      <Paragraph contentTwo={data?.description} className="m-0 lh-1 w-80 text-justify mt-1" />
      <div id="hexagono2" className="position-absolute end-0 top-0 bottom-0 my-auto d-flex align-items-center justify-content-center">
        <ProgressCircularTwo value={Math.round(data?.score)}/>
      </div>
    </div>
  )
}
