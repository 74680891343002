export default function  Paragraph({contentOne = "", contentTwo = "", className = "", capital = true}) {
  if(contentOne !== "") {
    contentOne = contentOne?.toString().charAt(0).toUpperCase() + contentOne?.toString().slice(1) + ": "
  }
  if(capital){
    contentTwo = contentTwo?.toString().charAt(0).toUpperCase() + contentTwo?.toString().slice(1)
  }
  return (
    <p className={className}><b>{ contentOne }</b>{contentTwo}</p>
  )
}
