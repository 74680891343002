import * as React from "react";
import * as Dictionary from "../../../../common/domain/constants/dictionary";

import TemplateHeader from "../../../../common/domain/template/template-header/template-header";
import PositionSectionOrganismPositionCards from "../../organisms/positionSection-organism-positionCards/positionSection-organism-positionCards";

import "./position-section-template-positionCards.scss";

export default function PositionSectionTemplatePositionCards() {
  const [state, setState] = React.useState({
    searchPositions: undefined,
  })
  
  return (
    <div className="w-65">
      <TemplateHeader title={Dictionary.positionsString} subTitle={Dictionary.modifiesPositionsString} />
      <PositionSectionOrganismPositionCards state={state} setState={setState} />
    </div>
  )
}
