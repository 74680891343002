export const username: string = "Nombre";
export const password: string = "Contraseña";
export const confirmPassword: string = "Confirmar contraseña";
export const LogIn: string = "Iniciar Sesión";
export const loginInfo : string = "Ingresa tu correo y contraseña"
export const logOut: string = "Cerrar sesión"
export const singUp: string = "Crear una cuenta";
export const welcome : string = "Bienvenido"
export const registerMsg : string = "Somos expertos en productividad"
export const email: string = "Email";
export const createAccount : string = "Crear cuenta"
export const confirmEmail: string = "Confirmar la dirección e-mail";
export const recoveryPassWord: string = "Recuperar contraseña";
export const forgetPassword: string = "¿Olvidaste tu contraseña?";
export const alertRegister: string = "Usuario registrado con éxito";
export const alertRecovery: string = "Hemos enviado una nueva contraseña al correo "
export const alertChangePassword: string = "Contraseña actualizada con éxito";
export const changePassword: string = "Cambiar contraseña";
export const doYouHaveAccount: string = "¿Ya tienes una cuenta?";
export const toLogin: string = "Ir a Iniciar sesión";
export const toHome : string = "Home";
export const loginYet : string = "Ya iniciaste sesión. ir a ";
export const registerYet: string = "Ya existe una cuenta asociada con la direccion de correo suministrada. Ir a ";
export const errorUserPassword : string = "El correo o la contrasena son incorrectos";
export const noLoginYet : string = "No has iniciado sesión, Ir a "
export const newPassword : string = "Nueva contraseña"
export const confirmNewPassword : string = "Confirma la nueva contraseña"
export const actualPassword : string = "Contraseña actual"
export const logoutDone : string = "Sesión cerrada con éxito"
export const editProfile : string = "Editar perfil";



export const singUpText: string = "Registrarse";
export const singIn: string = "Ingresar";
export const recoveryText: string = "Enviar";

export const typeText: string = "text";
export const typePassword: string = "password";
export const typeEmail: string = "email";

export const usernameError: string = "Debe contener de 8 a 30 caracteres";
export const mailError: string = "Debe introducir un e-mail valido";
export const passwordError: string = "Debe contener de 8 a 16 caracteres, acepta mayúsculas, minúsculas, caracteres especiales y dígitos";
export const confirmPasswordError: string = "Las contraseñas no coinsiden";
export const confirmEmailError: string = "Los e-mail no coinciden"
