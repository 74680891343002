import * as React from "react";

import Spiner from "../../atoms/Spiner/Spiner";

export default function LoadingSpinner({div}) {  
  const [loading, setLoading] = React.useState(true);
  function Selector() {
    setTimeout(() => {
      setLoading(false)
    },4000)
    if(loading){
      return <div className="d-flex align-items-center justify-content-center vh-85 w-86" ><Spiner/></div> 
    } else {
      return div
    }
  }
  return (
    <>{Selector()}</>
  )
}
