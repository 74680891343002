import * as Dictionary from "../../constants/dictionary";
import * as RouterDom from "react-router-dom";

import BoxesIcon from "../../atoms/BoxesIcon/BoxesIcon";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import CheckIcon from "../../atoms/CheckIcon/CheckIcon";

import "./SkillCard.scss";

export default function SkillCard ({information = {}, detail = true}) {
  information["name"] = information["name"] ? information["name"].toLowerCase() : '';
  return (
    <div className="border wr-skill-card hr-skill-card d-flex flex-column align-items-center border-2 rounded-4 position-relative">
      <div className="w-70 d-flex flex-column align-items-center h-100 justify-content-center">
        <div className="fs-skill-card-0 d-flex color-blue mt-2">
          <BoxesIcon className="bi bi-boxes" />
        </div>
        <div className="w-100 h-30 overflow-hidden">
          <Paragraph contentTwo={information["name"]} className={"fs-skill-card-1 text-center fw-bold m-0 my-1 text-wrap text-truncate"}/>
        </div>
        {
          detail &&
          <>
          <div className="text-center mb-1">
            <RouterDom.Link to={`/dashboard/table/skills/detail/${information["uuid"]}`} className={"text-decoration-none fs-skill-card-1 text-dark fw-bold m-0 color-blue"} >
              {Dictionary.viewItemTable} {Dictionary.skillString.toLowerCase()}
            </RouterDom.Link>
          </div>
          </>
        }
      </div>
    </div>
  )
};