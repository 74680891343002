import SkillsEditOrganismData from "../../organisms/skills-edit-organism-data/skills-edit-organism-data";
import SkillsEditOrganismTests from "../../organisms/skills-edit-organism-tests/skills-edit-organism-tests";
import SkillsEditOrganismFooter from "../../organisms/skills-edit-organism-footer/skills-edit-organism-footer";

import "./skills-edit-template-form.scss";

export default function SkillsEditTemplateForm({tests, setTests}) {
  return (
    <>
    <div className="modal-body px-4 justify-content-between d-flex w-100 m-2 vh-70 vh-skills-edit vw-skills-edit">
      <SkillsEditOrganismData />
      <div className="border-start h-90 align-self-center"></div>
      <SkillsEditOrganismTests tests={tests} setTests={setTests} />
    </div>
    <SkillsEditOrganismFooter />
    </>
  )
}
