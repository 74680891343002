
import "./FileInput.scss";

export default function FileInput({id, label, accept}) {
  return (
    <>
    <label className="btn fs-file-input-0 color-blue fw-bold m-0 p-0" htmlFor={id}>{label}</label>
    <input id={id} className="item-hidden" type="file" accept={accept}/>
    </>
  )
}
