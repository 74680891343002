import * as React from "react";

import Paragraph from "../../atoms/Paragraph/Paragraph";
import QuestionOptionCard from "../../atoms/QuestionOptionCard/QuestionOptionCard";

import "./MultipleSelection.scss";

export default function MultipleSelection({ options, question, state, setState, stateKey, setActive, stateTimer, setStateTimer}) {
  (() => {    
    setTimeout(() => {
      setStateTimer([{
        ...stateTimer[0],
        [stateKey]: {
          timeResponse: (stateTimer[0][stateKey].timeResponse + 1)
        }
      }])
    }, 1000)
  })()
  
  const response = (uuid : string) : void => {    
    setActive(true)
    setTimeout(()=>{
      setState([{
        ...state[0],
        [stateKey]: {
          ...state[0][stateKey],
          questionResponseValue: uuid,
        }
      }])
    }, 500)
    setTimeout(()=>{
      setActive(false)
    }, 500)
  }  
  return (
    <div className="container h-100 d-flex flex-column text-center">
      <div dangerouslySetInnerHTML={{__html: question}} className={"fs-7 my-5 fw-bold"} />
      <div className="row pt-3 px-3">
        {options?.map((option: any) => (
          <div key={options.uuid} onClick={() => response(option.uuid)} className={"pointer bg-hover-yellow shadow-hover col-12 col-md-3 my-1 fs-7 border rounded-4 d-flex align-items-center justify-content-center py-4 px-3 shadow"}>
            <QuestionOptionCard element={option.name} />
          </div>
        ))}
      </div>
    </div>
  );
}