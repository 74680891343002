import { validTokenNoLogin } from "../../../../common/domain/services/token";
let count : number = 0;
const GuardedRouteNoRegister = ({isLoggedIn, children}) =>  {
  while (count === 0) {
    validTokenNoLogin('/dashboard');
    count++;
  }
  return children;
}

export default GuardedRouteNoRegister;
