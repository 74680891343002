import * as Dictionary from "../../constants/dictionary";
import * as RouterDom from "react-router-dom";
import * as Redux from "react-redux";
import * as React from "react";
import * as AuthServices from "../../../../auth/domain/services/AuthServices";

import UserIcon from "../../atoms/UserIcon/UserIcon";
import PersonIcon from "../../atoms/PersonIcon/PersonIcon";
import PersonBadgeIcon from "../../atoms/PersonBadgeIcon/PersonBadgeIcon";
import CheckIcon from "../../atoms/CheckIcon/CheckIcon";
import BoxesIcon from "../../atoms/BoxesIcon/BoxesIcon";
import BriefcaseIcon from "../../atoms/BriefcaseFillIcon/BriefcaseFillIcon";
import PcDisplayHorizontalIcon from "../../atoms/PcDisplayHorizontalIcon/PcDisplayHorizontalIcon";
import Collectionfill from "../../atoms/Collectionfill/Collectionfill";
import PersonWorkspaceIcon from "../../atoms/PersonWorkspaceIcon/PersonWorkspaceIcon";
import SettingsIcon from "../../atoms/SettingsIcon/SettingsIcon";
import QuestionsIcon from "../../atoms/QuestionsIcon/QuestionsIcon";
import SidebarUserCard from "../../molecule/sidebar-userCard/sidebar-userCard";

import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./sidebar-organism.scss";

export default function SideBar({section = ""}) {
  let userName = Redux.useSelector(state => state["userName"]);
  let classNameNoActiveOne = "text-sm-start nav-link text-light w-90 h-9 d-flex align-items-center fw-bold";
  let classNameNoActiveTwo = "text-sm-start nav-link text-light w-90 h-30 d-flex align-items-center fw-bold";
  let classNameActiveOne = "text-sm-start nav-link w-90 h-9 d-flex align-items-center link-active fw-bold";
  let classNameActiveTwo = "text-sm-start nav-link w-90 h-30 d-flex align-items-center link-active fw-bold";
  React.useEffect(() => {
    AuthServices.getUser();
  }, []);
  const sectionsOne = [
    {
      to: "/dashboard",
      icon: <PcDisplayHorizontalIcon className="bi bi-pc-display-horizontal me-3 font-size-icon" />,
      section: Dictionary.main
    },
    {
      to: "/clientes",
      icon: <Collectionfill className="bi bi-collection-fill me-3 font-size-icon" />,
      section: Dictionary.customersString
    },
    {
      to: "/sesiones",
      icon: <PersonWorkspaceIcon className="bi bi-person-workspace me-3 font-size-icon" />,
      section: Dictionary.sessionsString
    },
    {
      to: "/candidatos",
      icon: <PersonBadgeIcon className="bi bi-person-badge me-3 font-size-icon"/>,
      section: Dictionary.candidates_string
    },
    {
      to: "/evaluaciones",
      icon: <CheckIcon className="bi bi-check-circle-fill me-3 font-size-icon"/>,
      section: Dictionary.evaluationsString
    },
    {
      to : "/cargos",
      icon : <BriefcaseIcon className="bi bi-briefcase-fill me-3 font-size-icon" />,
      section: Dictionary.positionString
    },
    {
      to: "/destrezas",
      icon: <BoxesIcon className="bi bi-boxes me-3 font-size-icon" />,
      section: Dictionary.skills_string
    },
    // {
    //   to: "/dashboard/table/test_questions",
    //   icon: <QuestionsIcon className="bi bi-question-circle me-3 font-size-icon" />,
    //   section: Dictionary.test_questions_string
    // },
    {
      to: "/dashboard/tablas/selector",
      icon: <SettingsIcon className="bi bi-sliders2 me-3 font-size-icon" />,
      section: Dictionary.tables
    },
  ];
  const sectionTwo = [
    {
      to: "/perfiles",
      icon: <UserIcon className="bi bi-person-circle me-3 font-size-icon" />,
      section: Dictionary.profilesString
    },
    {
      to: "/usuarios",
      icon: <PersonIcon className="bi bi-person me-3 font-size-icon" />,
      section: Dictionary.users_string
    }
  ];
  return (
    <>
      <nav className="nav flex-column align-items-center vh-100 bg-navbar position-fixed pt-4 mt-0 w-14">
        <div className="w-100 h-75 d-flex flex-column align-items-end">
          <div className="w-90 mb-4">
            <SidebarUserCard data={userName}/>
          </div>
          {
            sectionsOne?.map((s) => (
            <RouterDom.Link key={s.section} className={section === s.section ? classNameActiveOne  : classNameNoActiveOne} to={s.to}>
              <div className="">
                {s.icon} 
              </div>
              <Paragraph contentTwo={s.section} className="m-0 ms-2 font-size-link"/>
            </RouterDom.Link>
            ))
          }
        </div>
        <div className="division"></div>
        <div className="w-100 h-20 d-flex flex-column align-items-end mt-3">
          {
            sectionTwo?.map((s) => (
            <RouterDom.Link key={s.section} className={section === s.section ? classNameActiveTwo  : classNameNoActiveTwo} to={s.to}>
              {s.icon} 
              <Paragraph contentTwo={s.section} className="m-0 ms-2 font-size-link"/>
            </RouterDom.Link>
            ))
          }
        </div>
      </nav>
    </>
  )
}
