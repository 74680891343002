import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";

import TestCard from "../../../../common/domain/molecule/test-card-one/test-card-one";
import CreateButton from "../../../../common/domain/molecule/create-button/create-button";
import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import TestEditModal from "../../../../tests/feature-test-edit/feature-test-edit";

import "./positionSection-organism-testCards.scss";

export default function PositionSectionOrganismTestCards({state, setState}) {
  const dataTests = Redux.useSelector(state =>  state["tests"]);
  const searchTestFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchPositions: undefined});
    } else {
      let newPositions = dataTests?.filter((position : any) => {
        position.name = position.name.toLowerCase();
        return position.name.includes(e.target.value.toLowerCase());
      });
      setState({...state, searchPositions: newPositions});
    };
  };
  return (
    <>
    <div className="d-flex justify-content-between mb-2">
      <SearchBar onKeyUp={searchTestFunction} placeholder={Dictionary.searchString} type="text" id={"searchBarTest"} name={"searchBarTest"} className='rounded-4 wr-position-test-searchbar ps-5 pe-2 py-2 fs-btn-label border' />
      <TestEditModal />
      {/* <RouterDom.Link to={"/dashboard/table/tests/create/noFather/1"}>
        <Button text={ Dictionary.create + " " + Dictionary.test_string.toLowerCase()}  className={"py-1 px-4 border rounded-3 bg-body shadow fs-8"}/>
      </RouterDom.Link> */}
    </div>
    <div className="overflow-auto vh-20 d-flex flex-column align-items-center">
      {
        dataTests["data"]?.length > 0 && dataTests["data"].map((d) => (
          <div className="my-2">
            <TestCard information={d} />
          </div>
        ))
      }
    </div>
    </>
  )
}
