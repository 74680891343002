import * as Services from "../../services"

import Navbar from "../../organism/navbar-organism/navbar-organism"
import SideBar from "../../organism/sidebar-organism/sidebar-organism"
import FormsGenerator from "../../organism/FormsGenerator/FormsGenerator"

export default function UserUpdate () {
  const path = `user`;
  const json = {
    title: "Editar perfil",
    data: [
      {
        "tests_questions_type.name": "InputFormText",
        text: "Actualizar nombre",
        strict: false,
        name: "newName"
      },
    ]
  };  
  return (
    <>
    <Navbar />
    <SideBar />
    <div className="">
      <FormsGenerator json={json} path={path} method={Services.updateProfile} reload={true}/>
    </div>
    </>
  )
}