import * as Dictionary from "../../../../common/domain/constants/dictionary";
import TemplateHeader from "../../../../common/domain/template/template-header/template-header"
import SessionsOrganismTable from "../../organisms/sessions-organism-table/sessions-organism-table"
import SessionsEdit from "../../../feature-sessions-edit/feature-sessions-edit";

export default function SessionsTemplateContent() {
  return (
    <>
    <TemplateHeader title={Dictionary.sessionsString} subTitle={Dictionary.hereYouWillFindAllActiveSessions} />
    <SessionsEdit />
    <SessionsOrganismTable />
    </>
  )
}
