import Input from "../../atoms/Input/Input";
import SearchIcon from "../../atoms/SearchIcon/SearchIcon";

import "./search-bar.scss";

export default function SearchBar({onKeyUp, placeholder, type, id, name, className="rounded-4 wr-searchbar ps-5 pe-2 py-2 fs-btn-label border"}) {
  return (
    <div className="position-relative index-0">
      <SearchIcon className="bi bi-search position-absolute top-50 mx-3 translate-50 color-blue fw-bold"/>
      <Input onChange={e => onKeyUp(e)} onKeyUp={e => onKeyUp(e)} placeholder={placeholder} type={type} id={id} name={name} className={className}  />
    </div>
  )
}
