import * as Constants from "../../../common/redux/constants";
import * as Entities from "../entities/user";
import  store  from "../../../common/redux/store/index";
import { post, get, put, deleted } from "../../../common/domain/services/api";

export function logout (){
  get('logout')
  .then(res => {store.dispatch({type: Constants.LOGOUT, payload: res.data})})
  .catch(error => {throw new Error(error)})
};

export function getUser(){
  get('user')
  .then(res => {
    Constants.myStorage.setItem("isLoggin", "true");
    store.dispatch({type: Constants.GET_USER, payload: res.data});
  })
  .catch(error => {
    Constants.myStorage.setItem("isLoggin", "false");
    throw new Error(error);
  });
};

export function login(user: Entities.User) {
  post('login', user)
  .then((res) => {
    Constants.myStorage.setItem("isLoggin", "true")
    store.dispatch({type: Constants.LOGIN, payload: res.data});
  })
  .catch(error => {throw new Error(error)})
};

export function register(registerUser: Entities.RegisterUser){
  post('user', registerUser)
  .then(res => store.dispatch({type: Constants.REGISTER, payload: res.data}))
  .catch(error => {throw new Error(error)})
};

export function recoveryPassword(data: Entities.RecoveryPassword){
  put('recovery_password', data)
  .then(res => store.dispatch({type: Constants.RECOVERY_PASSWORD, payload: res.data}))
  .catch(error => {throw new Error(error)});
};

export function registerClear(){
  return store.dispatch({type: Constants.REGISTER_CLEAR_STATE, payload: ""});
};

export function changePassword ( data : Entities.ChangePassword ) {
  put('change_password', data)
  .then(res => store.dispatch({type: Constants.CHANGE_PASSWORD, payload: res.data}))
  .catch(error => {throw new Error(error)})
}