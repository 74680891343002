import * as Dictionary from "../../common/domain/constants/dictionary";

import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import TemplateHeader from "../../common/domain/template/template-header/template-header";
import EvaluationsTemplateBody from "../domain/organisms/evaluations-organism-body/evaluations-template-body";
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";

import "./feature-evaluations.scss";

export default function FeatureEvaluations() {
  return (
    <>
    <MenuTemplate section={Dictionary.evaluationsString}/>
    <div className="d-flex flex-column align-items-end w-100">
      <div className="vh-15 w-86"></div>
      <LoadingSpinner div={<div className="w-86"><TemplateHeader title={Dictionary.evaluationsString} subTitle={Dictionary.findTheReportsString} /> <EvaluationsTemplateBody/></div>} />   
    </div>
    </>
  )
}
