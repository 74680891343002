import * as Dictionary from "../../common/domain/constants/dictionary";

import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import CandidatesTemplateHeader from "../domain/templates/candidates-template-header/candidates-template-header";
import CandidatesTemplateCardList from "../domain/templates/candidates-template-cardList/candidates-template-cardList";
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";
import TemplateHeader from "../../common/domain/template/template-header/template-header";

import "./feature-candidates.scss";

export default function CandidatesSection() {
  return (
    <>
    <MenuTemplate section={Dictionary.candidates_string}/>
    <div className="d-flex flex-column align-items-end w-100">
      <div className="vh-15 w-86"></div>
      <LoadingSpinner div={<div className="w-86"><TemplateHeader title={Dictionary.candidateString} subTitle={Dictionary.candidatesRegisteredString}/><CandidatesTemplateCardList/></div>} />        
    </div>
    </>
  )
}
