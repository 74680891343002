import ProfileEditTemplateForm from "../domain/templates/profile-edit-template-form/profile-edit-template-form";
import ProfileEditTemplateTrigger from "../domain/templates/profile-edit-template-trigger/profile-edit-template-trigger";

export default function ProfileEditModal() {
  return (
    <>
    <ProfileEditTemplateTrigger/>
    <ProfileEditTemplateForm/>
    </>
  )
}
