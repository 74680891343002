
import MegaphoneIcon from "../../atoms/MegaphoneIcon/MegaphoneIcon";
import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./tests-group-card-two.scss";

export default function TestsGroupCardTwo({information = {}}) {
  information = {
    uuid: information["uuid"],
    name: "Nombre grupo de pruebas",
    created_at : information["created_at"],
    updated_at : information["updated_at"],
    tests : [
      {
        uuid: "nombredeprueba1",
        name: "Nombre de Prueba"
      },
      {
        uuid: "nombredeprueba2",
        name: "Nombre de Prueba"
      },
      {
        uuid: "nombredeprueba3",
        name: "Nombre de Prueba"
      },
    ]
  };
  return (
    <div className="border shadow wr-tests-group-card hr-tests-group-card rounded-4 d-flex flex-column align-items-center">
      <MegaphoneIcon className="bi bi-megaphone mt-3 color-blue fs-tests-group-card-two-0" />
      <div className="w-90 h-30">
        <Paragraph contentTwo={information["name"]} className="fs-tests-group-card-two-1 m-0 fw-bold text-wrap text-center" />
      </div>
      <div>
      {
        information["tests"]?.map((test) => (
          <div className="">
            <Paragraph contentTwo={test.name} className="m-0 text-secondary fs-tests-group-card-two-2 text-truncate"/>
          </div>          
        ))
      }
      </div>
    </div>
  )
}
