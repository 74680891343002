import * as Dictionary from "../../../../common/domain/constants/dictionary";

import "./candidate-detail-organism-trigger.scss";

export default function CandidateDetailOrganismTrigger({information}) {
  return (
    <button type="button" className="btn bg-color-blue text-white rounded-4 border fs-candidate-card-2 w-100" data-bs-toggle="modal" data-bs-target={`#user${information.uuid}`}>
      {Dictionary.viewItemTable} {Dictionary.profile.toLowerCase()}
    </button>
  )
}
