import { get, put, post, deleted } from "../../../common/domain/services/api";
import * as Constants from "../../../common/redux/constants";
import store from "../../../common/redux/store";

export function getProfiles (path:string) {
  get(path)
  .then((res) => {store.dispatch({type: Constants.GET_TABLE_PROFILES, payload: res.data})})
  .catch(error => {throw new Error(error)})
};
export function createNewProfile (data : any){
  post('profiles', data)
  .then(res => store.dispatch({TYPE: Constants.CREATE_ITEM_IN_TABLE, payload : res.data}))
  .catch(error => {throw new Error(error)})
};