import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";
import * as React from "react";
import * as TestServices from "../../services/TestServices";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import Button from "../../../../common/domain/atoms/Button/Button";
import Input from "../../../../common/domain/atoms/Input/Input";
import TestsGroupCard from "../../../../common/domain/molecule/TestsGroupCard/TestsGroupCard";

let count : number = 0;

export default function AssignTestsGroupModalOrganismBody({assign, setTestGroup, testGroup}) {
  const testsGroupInformation = Redux.useSelector(state => state["testsGroup"]);
  const [searchTestGroup, setsearchTestGroup] = React.useState(undefined)
  const searchTestGroupFunction = (e : any) => {
    let newTestGoupsInformation = testsGroupInformation.filter((testGroup : any) => {
      testGroup.name = testGroup.name.toLowerCase();
      return testGroup.name.includes(e.target.value);
    })
    setsearchTestGroup(newTestGoupsInformation);
  };
  const selectTestGroup = (uuid : string) => {
    setTestGroup(uuid);
  };
  
  if(count === 0) {
    TestServices.getAllTestsGroup();
    count++;
  };
  
  return (
    <div className="modal-body p-0">
      {
        assign
        ? <div className="alert alert-success container h-90 d-flex justify-content-center align-items-center">
            <Paragraph contentTwo={Dictionary.testAssignedSuccessfully} className="" />
          </div>
        :
        <div className="container h-100">
          <Paragraph contentTwo={Dictionary.selectTestsGroupToAssignString} className="m-0 fs-6 my-3 border-bottom pb-2"/>
          <Paragraph contentTwo={Dictionary.testsGroupMatrixString} className="m-0 fs-7 fw-bold mb-2" />
          <div className="row w-100">
            <div className="d-flex justify-content-between col-6">
              <Button text={ Dictionary.create + " " + Dictionary.testsGroupStringTwo.toLowerCase()}  className={"py-2 px-4 border rounded-4 bg-body fs-8 text-wrap fw-bold"}/>
              <div className="border"></div>
              <Input onChange={e => searchTestGroupFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchBarSkill"} name={"searchBarSkill"} className={"rounded-4 ps-3 pe-5 py-2 fs-8 border"}/>
            </div>
          </div>
          <div className="h-60 mt-2 overflow-auto w-100 d-flex flex-wrap justify-content-between">
            {
              searchTestGroup === undefined ? 
              (
                testsGroupInformation?.map((d) => (
                  <div onClick={() => selectTestGroup(d.uuid)} key={d.uuid} className={ "my-2 w-48 mx-1 rounded-4 pointer"}>
                    <TestsGroupCard information={d} testGroup={testGroup}/>
                  </div>
                ))
              ) 
              :
              (
                searchTestGroup?.map((d) => (
                  <div onClick={() => selectTestGroup(d.uuid)} key={d.uuid} className={"my-2 w-48 mx-1 rounded-4 pointer"}>
                    <TestsGroupCard information={d} testGroup={testGroup}/>
                  </div>
                ))
              )
            }
          </div>
        </div>
      }
    </div>
  )
}