import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";

export default function AssignTestsGroupModalOrganismHeader() {
  return (
    <div className="modal-header rounded-bottom rounded-4 bg-dark py-1 px-3">
      <Paragraph contentTwo={Dictionary.assignTestsGroupString} className="m-0 text-white fw-bold"/>
    </div>
  )
}
