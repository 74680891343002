import * as Crud from "../../organism/crud-CrudGenerator/crud-CrudGenerator"
import * as Dictionary from "../../constants/dictionary"

import FormsGenerator from "../../organism/FormsGenerator/FormsGenerator"

export default function CreatePermissions ({path}) {
  const json = { 
    title: Dictionary.createNewPermissionsString,
    data: [{
      "tests_questions_type.name": "InputFormText",
      text: Dictionary.nameString,
      name: "name",
      strict: true,
    }]
  };
  
  return (
    <>
    <FormsGenerator json={json} path={path} method={Crud.createNewItem}  />
    </>
  )
}