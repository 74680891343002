import * as React from "react";

import ProfilesOrganismMainMenu from "../../organisms/profiles-organism-mainmenu/profiles-organism-mainmenu";
import ProfilesOrganismCards from "../../organisms/profiles-organism-cards/profiles-organism-cards";

export default function ProfilesTemplateBody() {
  const states = {order: false, searchProfile: undefined};
  const [state, setState] = React.useState(states);
  return (
    <>
    <ProfilesOrganismMainMenu state={state} setState={setState}/>
    <ProfilesOrganismCards state={state} />
    </>
  )
}
