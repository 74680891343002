import * as React from "react"
import * as Dictionary from "../../common/domain/constants/dictionary";

import AssignTestsModalTemplateHeader from "../domain/templates/assign-tests-template-header/assign-tests-template-header";
import AssignTestModalTemplateBody from "../domain/templates/assign-test-modal-template-body/assign-test-modal-template-body";
import AssignTestModalTemplateFooter from "../domain/templates/assign-test-modal-template-footer/assign-test-modal-template-footer";

import "./feature-assign-tests-modal.scss";

export default function AssignTestsModal({information}) {
  const [test, setTest] = React.useState("");
  const [skill, setSkill] = React.useState("");
  const [assign, setAssign] = React.useState(false); 
  return (
    <>
    <button type="button" className="btn border bg-color-blue text-white rounded-4 shadow w-100 h-100 fs-assing-tests-modal-0 py-1 fw-bold" data-bs-toggle="modal" data-bs-target={`#assignTest${information.uuid}`}>
      {Dictionary.individualTestString}
    </button>

    <div className="modal fade" id={`assignTest${information.uuid}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered modal-xl h-75">
        <div className="modal-content border rounded-4 p-0 m-0 shadow-xl">
          <AssignTestsModalTemplateHeader/>
          <AssignTestModalTemplateBody assign={assign} setSkill={setSkill} setTest={setTest} skill={skill} test={test} />
          <AssignTestModalTemplateFooter setTest={setTest} setAssign={setAssign} information={information} test={test}/>        
        </div>
      </div>
    </div>
    </>
  )
}
