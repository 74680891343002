import * as RouterDom from "react-router-dom";
import * as Dictionary from "../../constants/dictionary";

import Paragraph from "../../atoms/Paragraph/Paragraph";
import BriefcaseIcon from "../../atoms/BriefcaseFillIcon/BriefcaseFillIcon";

import "./PositionCard.scss";

export default function PositionCard({information = {}}) {
  let newInformation = [];
  for(let key in information){
    if(Dictionary.terms.hasOwnProperty(key) && key !== "skills" && key !== "name"){
      newInformation.push({name: Dictionary.terms[key], value: information[key]})
    };
  };
  
  return (
    <div className="w-100 wr-position-card hr-position-card d-flex flex-column align-items-center border rounded-4 ">
      <div className="w-80 d-flex flex-column justify-content-center h-100">
        <div className="text-center">
          <BriefcaseIcon className="bi bi-briefcase color-blue fs-position-card-0"/>
        </div>
        <div className="border-bottom mt-3 pb-3">
          <Paragraph contentTwo={information["name"]} className={"text-center fw-bold mb-3 fs-position-card-4"}/>
          <Paragraph contentOne={Dictionary.skills_string} contentTwo={information["skills"]?.length} className={"text-center text-secondary fs-position-card-1"} capital={false}/>
        </div>
        <div className="d-flex flex-column mt-4">
          <Paragraph contentTwo={Dictionary.informationString} className="fs-position-card-1 fw-bold" />
          <div className="d-flex">
            {
              newInformation?.map((info) => (
                <div className="text-break fs-position-card-3 mb-3 w-50">
                  <Paragraph contentTwo={info.name} className={"m-0 fw-bold text-secondary"}/>
                  <Paragraph contentTwo={info.value} className={"m-0 text-secondary"} capital={false}/>
                </div>
              ))
            }

          </div>
        </div>
        <div className="text-center mb-3 mt-4">
          <RouterDom.Link to={`/dashboard/table/position/detail/${information["uuid"]}`} className={"text-decoration-none text-dark fw-bold fs-position-card-2 border p-2 px-4 rounded-4 bg-color-blue text-white"} >
            {Dictionary.viewItemTable} {Dictionary.positionString.toLowerCase()}
          </RouterDom.Link>
        </div>
      </div>
    </div>
  )
}
