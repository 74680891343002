import axios from "axios";

import * as Entities from "../../../auth/domain/entities/user";
import * as Constants from "../../redux/constants";


// GET -------------------------------------------------------------------------------------------
export function loginConfirmToken () {
  return function(dispatch) {
    return axios.get(`${process.env.REACT_APP_API_URL}/verification_token`, {
      headers: {
        'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
      }
    })
    .then(res => dispatch({type: Constants.LOGIN_CONFIRM_TOKEN, payload: res.data}))
    .catch(error => {throw new Error (error)});
  };
};

export function logout(){
  return function (dispatch : Function) {
    return axios.get(`${process.env.REACT_APP_API_URL}/logout`, {
      headers: {
        'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
      }
    })
    .then(res => dispatch({type: Constants.LOGOUT, payload: res.data}))
    .catch(error => {throw new Error(error)})
  }
}

export function getUser(){
  return function (dispatch : Function){
    return axios.get(`${process.env.REACT_APP_API_URL}/user`, {
      headers: {
        'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
      } 
    })
    .then(res => dispatch({type: Constants.GET_USER, payload: res.data}))
    .catch(error => {throw new Error(error)})
  }
}

// POST ------------------------------------------------------------------------------------------
export function login(user: Entities.User) {
    return function (dispatch: Function) {
      return axios.post(`${process.env.REACT_APP_API_URL}/login`, user)
      .then((res) => {dispatch({type: Constants.LOGIN, payload: res.data})})
      .catch(error => {throw new Error(error)})
   };
}
  
export function register(registerUser: Entities.RegisterUser) {
    return function (dispatch: Function) { 
      return axios.post(`${process.env.REACT_APP_API_URL}/user`, registerUser)
      .then(res => dispatch({type: Constants.REGISTER, payload: res.data}))
      .catch(error => {throw new Error(error)})
  };
}


// PUT -------------------------------------------------------------------------------------------
export function recoveryPassword(data: Entities.RecoveryPassword) {
    return function (dispatch:Function) {
      return axios.put(`${process.env.REACT_APP_API_URL}/recovery_password`, data)
      .then(res => dispatch({type: Constants.RECOVERY_PASSWORD, payload: res.data}))
      .catch(error => {throw new Error(error)})
    }
}

export function changePassword ( data : Entities.ChangePassword ) {
  return function (dispatch : Function) {
    return axios.put(`${process.env.REACT_APP_API_URL}/change_password`, data, {
      headers: {
        'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
      }
    })
    .then(res => dispatch({type: Constants.CHANGE_PASSWORD, payload: res.data}))
    .catch(error => {throw new Error(error)})
  }
}

export function updateProfile (url : string, data : any) {
  return function (dispatch : any) {
    return axios.put(url, data, {
      headers: {
        'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
      }
    })
    .then(res => dispatch({type: Constants.UPDATE_USER, payload: res.data}))
    .catch(error => {throw new Error(error)})
  }
}

export function register_clear(){
  return function(dispatch:Function){
    return dispatch({type: Constants.REGISTER_CLEAR_STATE, payload: ""})
  }
} 
// DELETE ----------------------------------------------------------------------------------------