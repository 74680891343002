import { get, post } from "../../../common/domain/services/api";
import * as Constants from "../../../common/redux/constants";
import store from "../../../common/redux/store";

export function getUsers (data) {
  post('users', data)
  .then((res) => {store.dispatch({type: Constants.GET_USERS, payload: res.data})})
  .catch(error => {throw new Error(error)})
};

export function createNewUser (data: any) {
  post('user', data)
  .then(res => store.dispatch({type: Constants.CREATE_NEW_USER, paryload: res.data}))
  .catch(error => {throw new Error(error)});
};

export function getProfiles (path:string) {
  get(path)
  .then((res) => {store.dispatch({type: Constants.GET_TABLE_PROFILES, payload: res.data})})
  .catch(error => {throw new Error(error)})
};