import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./edit-button.scss";

export default function EditButton({paragraph, className='fs-icon-profile-card-two-1 border rounded-4 py-1 px-4 text-white bg-color-blue', target}) {
  return (
    <button type="button" className={className} data-bs-toggle="modal" data-bs-target={`#${target}`}>
      <Paragraph contentTwo={paragraph} className="m-0" />
    </button>
  )
}
