import * as Redux from "react-redux";
import * as React from "react";
import * as SkillServices from "../../services/SkillServices";

import SkillCard from "../../../../common/domain/molecule/SkillCard/SkillCard";
import CheckIcon from "../../../../common/domain/atoms/CheckIcon/CheckIcon";

import "./skills-section-organism-body-skill.scss";

export default function SkillsSectionOrganismBodySkill({state, setSkillSelected, skillSelected}) {
  const data = Redux.useSelector(state => state["skills"]);
  function skillSelectedFunction (uuid : string){
    setSkillSelected(uuid);
  };
  React.useEffect(() => {
    SkillServices.getAllSkills();
    skillSelected !== "" && SkillServices.getAllTestsSkill(skillSelected);
  }, [skillSelected]);
  
  return (
    <div className="d-flex w-96 flex-wrap h-99 mt-3 overflow-auto">
    {
      state.searchSkills !== undefined ?
        state.searchSkills?.map((d : any) => (
          <div onClick={()=>skillSelectedFunction(d.uuid)} className={skillSelected === d.uuid ? "wr-skill-card hr-skill-card m-2 rounded-4 pointer shadow position-relative" : "wr-skill-card hr-skill-card m-2 rounded-4 pointer"}>
            {
              skillSelected === d.uuid && <CheckIcon className="bi bi-check-circle-fill color-blue position-absolute top-0 end-0 tl-check-skill-card fs-skills-section-organism-body-skill-0" />
            }
            <SkillCard information={d} detail={true} />
          </div>
        ))
        :
        data?.data?.length > 0 && (typeof data !== "string") && data.data?.map((d : any) => (
          <div onClick={()=>skillSelectedFunction(d.uuid)} className={skillSelected === d.uuid ? "wr-skill-card hr-skill-card  m-2 mx-3 rounded-4 pointer shadow position-relative" : "mx-3 wr-skill-card hr-skill-card  m-2 rounded-4 pointer"}>
            <SkillCard information={d} detail={true} />
            {
              skillSelected === d.uuid && <CheckIcon className="bi bi-check-circle-fill color-blue position-absolute top-0 end-0 tl-check-skill-card" />
            }
          </div>
        ))
    }
  </div>
  )
}
