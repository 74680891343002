import * as Crud from "../../organism/crud-CrudGenerator/crud-CrudGenerator";
import * as Redux from "react-redux";
import * as React from "react";
import * as Dictionary from "../../constants/dictionary";
import * as Router from "react-router";

import FormsGenerator from "../../organism/FormsGenerator/FormsGenerator";

export default function CreateTest ({path, defaultValue = ""}) {
  const params = Router.useParams();
  let url2 : string;
  let name : string;
  let text : string;
  if(params.father === "users"){
    url2 = `users`
    name = "userId"
    text = Dictionary.selectUserString
  } else if (params.father === "profiles") {
    url2 = `profiles`
    name = "profileId"
    text = Dictionary.selectProfilesString
  } else {
    url2 = `skills`
    name = "skillId"
    text = Dictionary.selectSkillString
  };
  const optionsSelector = Redux.useSelector(state => state["tableOptions"]);
  React.useEffect(()=>{
    Crud.getTableOptions(url2)
  },[url2]);
  
  const json = {
    title: "Crear prueba",
    data: [
      {
        "tests_questions_type.name": "InputFormText",
        text : Dictionary.nameString,
        name: "name",
        strict: true
      },
      {
        "tests_questions_type.name": "SelectInput",
        text,
        options: optionsSelector,
        name,
        strict: true,
        defaultValue
      }
    ]
  }
  
  return(
    <div>
      <FormsGenerator json={json} path={path} method={Crud.createNewItem} />
    </div>
  )
}