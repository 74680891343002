import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";

import SkillCard from "../../../../common/domain/molecule/SkillCard/SkillCard";
import CreateButton from "../../../../common/domain/molecule/create-button/create-button";
import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import SkillsEditModal from "../../../../skills/feature-skills-edit/feature-skills-edit";

import "./positionSection-organism-skillCards.scss";

export default function PositionSectionOrganismSkillCards({state, setState}) {
  const dataSkills = Redux.useSelector(state => state["skills"]);
  const searchSkillFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchPositions: undefined});
    } else {
      let newPositions = dataSkills?.filter((position : any) => {
        position.name = position.name.toLowerCase();
        return position.name.includes(e.target.value.toLowerCase());
      });
      setState({...state, searchPositions: newPositions});
    };
  };
  return (
    <>
    <div className="d-flex justify-content-between mb-2 mt-1 ">
      <SearchBar onKeyUp={searchSkillFunction} placeholder={Dictionary.searchString} type="text" id={"searchBarSkill"} name={"searchBarSkill"} className='rounded-4 wr-position-skill-searchbar ps-5 pe-2 py-2 fs-btn-label border' />
      <SkillsEditModal />
    {/* <RouterDom.Link to="/dashboard/table/skills/create/noFather/1">
        <Button text={ Dictionary.create + " " + Dictionary.skillString.toLowerCase()}  className={"py-1 px-4 border rounded-3 bg-body shadow fs-8"}/>
    </RouterDom.Link> */}
    </div>
    <div className="overflow-auto vh-35 row">
    {
        dataSkills["data"]?.length > 0 && dataSkills["data"].map((d) => (
        <div className="col-12 col-xl-4 my-2">
            <SkillCard information={d} />
        </div>
        ))
    }
    </div>
    </>
  )
}
