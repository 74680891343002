import * as Dictionary from "../../constants/dictionary";

import "./ModalFooter.scss";

export default function ModalFooter() {
  return (
    <div className="modal-footer d-flex w-100 justify-content-center">
      <div className="d-flex w-50 justify-content-between">
        <button type="submit" className="btn border rounded-4 w-40 fs-modal-footer-0 fw-bold color-blue py-2">{Dictionary.acceptChangesString}</button>
        <div className="border"></div>
        <button type="button" className="btn border rounded-4 w-40 fs-modal-footer-0 fw-bold color-blue py-2" data-bs-dismiss="modal">{Dictionary.cancelString}</button>
      </div>
    </div>
  )
}
