import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph"
import CandidateDetailResume from "../../../../common/domain/molecule/CandidateDetailResume/CandidateDetailResume";
import CandidateDetailTests from "../../../../common/domain/molecule/CandidateDetailTests/CandidateDetailTests";
import CandidateDetailSkills from "../../../../common/domain/molecule/CandidateDetailSkills/CandidateDetailSkills";

import "./candidate-detail-organism-details.scss";

export default function CandidateDetailOrganismDetails({information}) {
  information.skills = [
    {
      value: 50,
      name: "trabajo en equipo"
    },
  ]
  return (
    <>
      <div className="vw-80 overflow-auto">
        <div className="container w-90 mt-4 ms-4">
          <Paragraph contentTwo={Dictionary.resumeString + " :"}  className={"fs-candidate-detail-details-0 mb-2"}/>        
          <CandidateDetailResume information={information} />
          <Paragraph contentTwo={Dictionary.testsString + " :"}  className={"mt-3 fs-candidate-detail-details-0 mb-2"}/>        
          <CandidateDetailTests information={information} />
          <Paragraph contentTwo={Dictionary.skills_string + " :"} className="mt-3 fs-candidate-detail-details-0 mb-2"/>
          {
            information?.skills?
            information.skills.map((skill)=>(
              <div key={skill.uuid} className="my-1">
                <CandidateDetailSkills information={skill} />
              </div>
            ))
            : 
            (
              <Paragraph contentTwo={Dictionary.noSkillsAssessed} className="" />
            )
          }
        </div>
      </div>
    </>
  )
}
