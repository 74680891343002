import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as DocumentTypeServices from "../../../../document-type/domain/services/documenteTypeServices";
import * as CustomerServices from "../../../../customers/domain/services/customerServices";
import * as DepartmentServices from "../../../../departments/domain/services/DepartamentSevices";
import * as MunicipalityServices from "../../../../municipalities/domain/services/MunicipalityServices";
import * as DomainantHandServices from "../../../../dominant-hands/domain/services/DomainantHandServices";
import * as GenderServices from "../../../../gender/domain/services/GenderServices";
import * as React from "react";
import * as Redux from "react-redux";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import SelectInput from "../../../../common/domain/atoms/SelectInput/SelectInput";
import FileInput from "../../../../common/domain/atoms/FileInput/FileInput";
import FormText from "../../../../common/domain/atoms/FormText/FormText";

import "./candidate-edit-modal-organism-body.scss";
import InputDate from "../../../../common/domain/atoms/input-date/input-date";

export default function CandidateEditModalOrganismBody() {
  React.useEffect(() => {
    DocumentTypeServices.getAllDocumentType();
    CustomerServices.getCustomers();
    DepartmentServices.getAllDepartments();
    MunicipalityServices.getAllMunicipalities();
    DomainantHandServices.getAllDomainantHands();
    GenderServices.getAllGenders();
  },[])

  const optionsIdentificationType = Redux.useSelector((state)=>state['documentTypes']);
  const optionsMunicipalities = Redux.useSelector((state)=>state['municipalities']);
  const optionsBusiness = Redux.useSelector((state)=>state['business']);
  const optionsDepartament = Redux.useSelector((state)=>state['departments']);
  const optionsDomainantHands = Redux.useSelector((state)=>state['domainantHands']);
  const optionsGenders = Redux.useSelector((state)=>state['genders']);
  
  return (
    <div className="candidate-edit-data-size">
      <Paragraph contentTwo={Dictionary.generalDataString + " :"} className="ms-2 fs-general-title mb-2" />
      <div className="border-top d-flex w-100 h-80 align-items-center">
        <div className="w-20 d-flex flex-column aling-items-center justify-content-end mt-4">
          <img className="rounded-4 wr-previewImg mb-2 mx-previewImg" src="https://picsum.photos/id/823/300/300" alt="Img not found" />
          <FileInput label={Dictionary.updataPhotoString} id='updata-candidate-picture' accept='image/*' />
        </div>
        <div className="w-80 mt-5">
          <div className="row">
            <div className="col-3">
              <FormText id={"name"} name={"name"} label={Dictionary.nameString} placeholder={Dictionary.nameString} classDiv='d-flex flex-column mb-2' required={true} />
            </div>
            <div className="col-3">
              <SelectInput id="uuidUserDocumentType" name="documentType" text={Dictionary.typeString + " " + Dictionary.ofString + " " +  Dictionary.identificationString} options={optionsIdentificationType}
                firstOption={Dictionary.typeString + " " + Dictionary.ofString + " " + Dictionary.identificationString} classDiv='d-flex flex-column mb-2' required={true}/>
            </div>
            <div className="col-3">
              <FormText id={"documentNumber"} name={"documentNumber"} label={Dictionary.identificationString} placeholder={Dictionary.identificationString} classDiv='d-flex flex-column mb-2' required={true} />
            </div>
            <div className="col-3">
              <SelectInput id="businessId" text={Dictionary.customerString} name="uuidBusinessLineEntities" options={optionsBusiness} firstOption={Dictionary.customerString} classDiv='d-flex flex-column mb-2' />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <InputDate id={"dateBirth"} name={"dateBirth"} label={Dictionary.dateBirthString} placeholder={'DD/MM/AAAA'} />
            </div>
            <div className="col-3">
              <FormText id={"email"} name={"email"} label={Dictionary.emailString} placeholder={Dictionary.emailString} required={true} />
            </div>
            <div className="col-3">
              <FormText id={"confirmEmail"} name={"confirmEmail"} label={Dictionary.confirmEmailString} placeholder={Dictionary.confirmEmailString} required={true} />
            </div>
            <div className="col-3">
              <FormText id={"phone"} name={"phone"} label={Dictionary.phoneString} placeholder={Dictionary.phoneString} required={true} />
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-3">
              <FormText id={"academicLevel"} name={"academicLevel"} label={Dictionary.academicLevelString} placeholder={Dictionary.academicLevelString}  />
            </div>
            <div className="col-3">
              <FormText id={"graduationYear"} name={"graduationYear"} label={Dictionary.graduationYearString} placeholder={Dictionary.graduationYearString}/>
            </div>
            <div className="col-3">
              <FormText id={"experience"} name={"experience"} label={Dictionary.experienceString} placeholder={Dictionary.experienceString}  />
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-3">
              <SelectInput id="department" text={Dictionary.departamentString} name="uuidGeoDepartament" options={optionsDepartament} firstOption={Dictionary.departamentString} classDiv='d-flex flex-column mb-2'/>
            </div>
            <div className="col-3">
              <SelectInput id="municipality" text={Dictionary.municipalityString} name="uuidGeoMunicipaly" options={optionsMunicipalities} firstOption={Dictionary.municipalityString} classDiv='d-flex flex-column mb-2'/>
            </div>
            <div className="col-3">
              <FormText id={"city"} name={"city"} label={Dictionary.cityString} placeholder={Dictionary.cityString}/>
            </div>
            <div className="col-3">
              <FormText id={"neighborhood"} name={"neighborhood"} label={Dictionary.neighborhoodString} placeholder={Dictionary.neighborhoodString}  />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <FormText id={"address"} name={"address"} label={Dictionary.andressString} placeholder={Dictionary.andressString}  />
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-3">
              <FormText id={"height"} name={"height"} label={Dictionary.heightString} placeholder={Dictionary.heightString}  />
            </div>
            <div className="col-3">
              <FormText id={"familyNucleus"} name={"familyNucleus"} label={Dictionary.familyNucleusString} placeholder={Dictionary.familyNucleusString}/>
            </div>
            <div className="col-3">
              <FormText id={"weight"} name={"weight"} label={Dictionary.weightString} placeholder={Dictionary.weightString}  />
            </div>
            <div className="col-3">
              <SelectInput id="dominantHand" text={Dictionary.domainantHandString} name="uuidUserDominantHands" options={optionsDomainantHands} firstOption={Dictionary.domainantHandString} classDiv='d-flex flex-column mb-2'/>
            </div>
            <div className="col-3">
              <SelectInput id="gender" text={Dictionary.genderString} name="uuidGeoMunicipaly" options={optionsGenders} firstOption={Dictionary.genderString} classDiv='d-flex flex-column mb-2'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
