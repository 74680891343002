import { get, post, put, deleted } from "../../../common/domain/services/api";
import store from "../../../common/redux/store";
import * as Constants from "../../../common/redux/constants";

export function createNewDocumentType ( data : any){
    post('document_type', data)
    .then(res => store.dispatch({type: Constants.CREATE_ITEM_IN_TABLE, payload : res.data}))
    .catch(error => {throw new Error(error)})
};
export function getAllDocumentType (){
    get('/document_type')
    .then(res => store.dispatch({type: Constants.GET_ALL_DOCUMENT_TYPE, payload : res.data}))
    .catch(error => {throw new Error (error)})
};