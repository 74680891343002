import * as dictionary from "../../../../common/domain/constants/dictionary";
import FormText from "../../../../common/domain/atoms/FormText/FormText";
import FormNumber from "../../../../common/domain/atoms/form-number/form-number";
import SelectInput from "../../../../common/domain/atoms/SelectInput/SelectInput";
import * as MunicipalityServices from "../../../../municipalities/domain/services/MunicipalityServices";
import * as DepartmentServices from "../../../../departments/domain/services/DepartamentSevices";
import * as SessionServices from "../../services/SessionServices";
import * as React from "react";
import * as Redux from "react-redux";
import * as apiServices from "../../../../common/domain/services/api";

import { FORM_CONSTANTS } from "../../constants/form-constants";
import InputDate from "../../../../common/domain/atoms/input-date/input-date";

export default function EditOrganismInformation() {
  React.useEffect(() => {
    SessionServices.getMunicipalityByDepartment('')
    MunicipalityServices.getAllMunicipalities()
    DepartmentServices.getAllDepartments();
    SessionServices.getHeadquarters()
  },[])

  let headquarters = Redux.useSelector(state => state['headquarters']);
  const departments = Redux.useSelector((state)=>state['departments']);
  let optionsMunicipalities = Redux.useSelector((state)=>state['municipalities']);

  function getAllMunicipalities(departmentId: string){
    console.log(departmentId)
    
    apiServices.get("/municipality/departament/" + departmentId).then(response => {
      optionsMunicipalities = response.data
    })
  }

  return (
    <div className="row w-100">
      <div className="col-12 row">
      <div className="col-4">
          <FormText id="name" name="name" label="Nombre" />
        </div>
        <div className="col-4">
          <InputDate id={FORM_CONSTANTS.DATE.name} name={FORM_CONSTANTS.DATE.name} label={dictionary.date} />
        </div>
        <div className="col-4">
          <FormText id="timeStart" name={FORM_CONSTANTS.START_TIME.name} label={dictionary.startTime} placeholder={FORM_CONSTANTS.START_TIME.placeholder} />
        </div>
        <div className="col-4">
          <FormText id="timeEnd" name={FORM_CONSTANTS.FINISH_TIME.name} label={dictionary.finishTime} placeholder={FORM_CONSTANTS.FINISH_TIME.placeholder}/>
        </div>
        <div className="col-4">
          <SelectInput id={FORM_CONSTANTS.HEADQUARTERS.name} name={FORM_CONSTANTS.HEADQUARTERS.name} options={headquarters} text={dictionary.terms.ubicate} />
        </div>
      </div>
    </div>
  )
}
