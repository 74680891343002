import "./input-date.scss";
export default function InputDate({id, name, label = "", placeholder = "", defaultValue = "", classLabel = "fs-form-date-0 mx-4 mb-1 fw-bold", classInput = "text-center w-85 py-2 rounded-4 border fs-form-date-0 mb-2 mx-3", classDiv= "d-flex flex-column" }) {
    if(defaultValue.length === 0){
      defaultValue = null
     };
    
    return (
      <div className={classDiv}>
        <label htmlFor={`${id}`} className={classLabel}>{label}</label>
        <input id={`${id}`} name={`${name}`} placeholder={placeholder} defaultValue={defaultValue} className={classInput} type="date" />
      </div>
    )
  }
