import * as Redux from "react-redux";


export default function AuthOrganismErrorMsgRecovery() {
  const alertMsg = Redux.useSelector(state => state["alertMsg"]);  
  return (
    <>
    {
      alertMsg === "Correo invalido" &&
      <div className="alert alert-danger mt-2 text-center">{alertMsg}</div>
    }
    </>
  )
}
