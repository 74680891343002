import Navbar from "../../organism/navbar-organism/navbar-organism";
import SideBar from "../../organism/sidebar-organism/sidebar-organism";
import MenuTemplate from "../../template/menu-template/menu-template";
import ItemDetailTemplateDetailCard from "../../template/item-detail-template-detailCard/item-detail-template-detailCard";
import ItemDetailTemplateTableDetails from "../../template/item-detail-template-tableDetails/item-detail-template-tableDetails";
import ItemDetailTemplateLinkUser from "../../template/item-detail-template-linkUser/item-detail-template-linkUser";

export default function ItemDetail() {
  return (
    <>
    <MenuTemplate/>
    <div className="d-flex flex-column align-items-end w-100">
        <div className="vh-15 w-86"></div>
        <div className="w-86">
          <ItemDetailTemplateDetailCard/>
          <ItemDetailTemplateTableDetails/>
        </div>
    </div>
    {/* <ItemDetailTemplateLinkUser/>       */}
    </>
  )
}
