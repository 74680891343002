import * as React from "react";

import CandidatesReportTableBody from "../../molecule/CandidatesReportTableBody/CandidatesReportTableBody";
import CandidatesReportTableHead from "../../molecule/CandidatesReportTableHead/CandidatesReportTableHead";

export default function CandidatesReportTable({data}) {
  const [state, setState] = React.useState("all");  
  return (
    <table className="table table-striped">
      <CandidatesReportTableHead setState={setState} state={state} />
      <CandidatesReportTableBody data={data} state={state} />
    </table>
  )
}
