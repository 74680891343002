import * as React from "react";

import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import AuthTemplateFormChangePassword from "../domain/templates/auth-template-form-changePassword/auth-template-form-changePassword";
import AuthTemplateSuccessMsgChangePassword from "../domain/templates/auth-template-successMsg-changePassword/auth-template-successMsg-changePassword";

export default function ChangePassword() {
  const [complete, setComplete] = React.useState(false);
  return (
    <>
    <MenuTemplate/>
    <div className="d-flex flex-column align-items-end w-100">
      <div className="vh-15 w-86"></div>
    <div className="container align-items-center w-50">
    {
      !complete ? <AuthTemplateFormChangePassword setComplete={setComplete}/> : <AuthTemplateSuccessMsgChangePassword/>
    }
    </div>
    </div>
    </>
  );
}

