import * as Crud from "../../organism/crud-CrudGenerator/crud-CrudGenerator";
import * as Dictionary from "../../constants/dictionary";

import Button from '../../atoms/Button/Button';
import Modal from '../../atoms/Modal/Modal';

export default function TableOptions({columns, data, url, title}) {
  return (
    <table className="table table-striped">
      <thead>
        <tr>
          <th className='fs-8' scope="col" colSpan={1}>#</th>
          {
            columns?.map((c: string) => (
              Dictionary.terms.hasOwnProperty(c) 
              ? <th key={c} className='text-start fs-8' colSpan={2} scope="col">{Dictionary.terms[c].charAt(0).toUpperCase()}{Dictionary.terms[c].slice(1)}</th>
              : <th key={c} className='text-start' colSpan={2} scope="col">{c.charAt(0).toUpperCase()}{c.slice(1)}</th>
            ))
          }
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          data?.map((d : any)=>(
            
            <tr key={d.count}>
              <th className='fs-8 p-2'>{d.count}</th>
              {
                columns?.map((c : string)=>(
                  Dictionary.terms.hasOwnProperty(d[c])
                  ? <th colSpan={2} className='text-start fs-8 p-0 align-middle' key={d[c]}> {Dictionary.terms[d[c]]?.charAt(0).toUpperCase()}{Dictionary.terms[d[c]]?.slice(1)} </th>
                  : <th colSpan={2} className='text-start fs-8 p-0 align-middle' key={d[c]}> {d[c]?.charAt(0).toUpperCase()}{d[c]?.slice(1)} </th>
                ))
              }
              <th className='d-flex m-0 justify-content-end p-1' colSpan={1}>
                <a href={`/dashboard/table/${title}/detail/${d.uuid}`}>
                  <Button text={d.view} className="fs-8" />
                </a>
                <a href={`/dashboard/table/${title}/update/${d.uuid}`}>
                  <Button text={d.updata} className="fs-8" />
                </a>
                <Modal method={Crud.deleteItem} url={url} uuid={d.uuid} id={"id" + d.count} textBtnP={d.delete} textBtnO={Dictionary.close} textBtnT={Dictionary.deleteWord} textTitle={Dictionary.deleteWord} textBody={Dictionary.deleteQuestion} />
              </th>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}
