
import Paragraph from "../../atoms/Paragraph/Paragraph";
import ImageQuestionOptionCard from "../../atoms/ImageQuestionOptionCard/ImageQuestionOptionCard";
import Image from "../../atoms/Image/Image";

import "./ImageMultipleSelection.scss";

export default function ImageMultipleSelection({ options, question, image, state, setState, stateKey, setActive, stateTimer, setStateTimer}) {
  (() => {    
    setTimeout(() => {
      setStateTimer([{
        ...stateTimer[0],
        [stateKey]: {
          timeResponse: (stateTimer[0][stateKey].timeResponse + 1)
        }
      }])
    }, 1000)
  })()
  let count : number = 0;
  options = options?.map((o) => {
    count++;
    return {
      ...o,
      count
    };
  });
  
  const response = (uuid : string) : void => {    
    setActive(true)
    setTimeout(()=>{
      setState([{
        ...state[0],
        [stateKey]: {
          ...state[0][stateKey],
          questionResponseValue: uuid,
        }
      }])
    }, 500)
    setTimeout(()=>{
      setActive(false)
    }, 500)
  }
  
  return (
    <div className="container h-100 d-flex flex-column text-center">
      <div className="mb-3 mt-5 pb-2">
        <Paragraph contentTwo={question} className={"fs-7 fw-bold"} />
        <Image src={image} className="img-question align-self-center" alt="Img not found"/>
      </div>
      <div className="row pt-3 px-3">
        {options?.map((option: any) => (
          <div key={options.uuid} onClick={() => response(option.uuid)} className={"pointer bg-hover-yellow shadow-hover col-12 col-md-3 my-1 fs-7 border rounded-4 d-flex align-items-center justify-content-center py-4 px-3 shadow"}>
            <ImageQuestionOptionCard element={option.file} number={option.count}/> 
          </div>
        ))}
      </div>
    </div>
  );
}
