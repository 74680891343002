import * as Dictionary from "../../common/domain/constants/dictionary";

import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import SkillsSectionBody from "../domain/templates/skills-section-template-body/skills-section-template-body";
import SkillsSectionTemplateHeader from "../domain/templates/skills-section-template-header/skills-section-template-header";
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";

import "./feature-skills-section.scss";

export default function SkillsSection() {
  return (
    <>
    <MenuTemplate section={Dictionary.skills_string}/>
    <div className="d-flex flex-column align-items-end w-100 vh-100">
      <div className="h-15 w-86"></div>      
      <LoadingSpinner div={<div className="w-86 h-85"><SkillsSectionTemplateHeader /><SkillsSectionBody /></div>}/>        
    </div>
    </>
  )
}