import * as React from 'react';
import * as RouterDom from "react-router-dom"
import * as Dictionary from "../../constants/dictionary"

import Pagination from '../../atoms/Pagination/Pagination';
import TableOptions from '../../molecule/table-options/table-options';
import Button from '../../atoms/Button/Button';

import './crud-generator.scss';

export default function CrudGenerator({json, path, pathDelete = path, uuidFather = ""}) {
  const [actualPage, setActualpage] = React.useState(1);
  let title : "";
  let count: number = 1, startData : number = 1, itemsPerPage : number = 10;
  let numberPage : number = 0;
  if(json.data?.length > 0){numberPage = Math.ceil(json.data?.length/itemsPerPage)};
  let data = json.data?.length > 0 && json.data?.map((d : any) => (
    {
      ...d,
      "count": count++,
      "view": Dictionary.viewItemTable,
      "updata": Dictionary.updataItemTable,
      "delete": Dictionary.deleteItemTable,
    }
  ));
  let columns : string[] = [];
  if(json.data){
    for( let key in json.data[0]){
      if(key !== "uuid" && key !== "created_at" && key !== "updated_at" && key !== "test_id" && key !== "test_question_type_id" && key !== "strict"
      && key !== "text" && key !== "trash" && key !== "enable" && key  !== 'tests.uuid' && key !== "key" && key !== "Accept_habeas_data") {
        columns.push(key)
      };
    };
  };
  let dataToShow = data?.length > 0 && data?.slice(startData*actualPage*itemsPerPage - itemsPerPage, itemsPerPage*actualPage);
  if(numberPage === 1){numberPage = 0}else if(Number.isNaN(numberPage)){numberPage = 0};
  if( Dictionary.terms.hasOwnProperty(json.title)){title = Dictionary.terms[json.title]};
  
  return (
    <>
    <div className='d-flex justify-content-center'>
      <div className={"w-95"}>
        <div>
          <h2 className='fs-6'>{title?.toUpperCase()}</h2> 
          {
            json.title !== "users" && json.title !== "user_permissions" && json.title !== "profiles" &&
            (uuidFather.length === 0
            ?
            <RouterDom.Link className='d-inline-flex mb-2' to={`/dashboard/table/${json.title}/create/noFather/1`}>
              <Button text={Dictionary.create} className="btn btn-primary fs-8"/>
            </RouterDom.Link>
            :
            <RouterDom.Link className='d-inline-flex mb-2' to={`/dashboard/table/${json.title}/create/${json.father}/${uuidFather}`}>
              <Button text={Dictionary.create} className="btn btn-primary fs-8"/>
            </RouterDom.Link>)
          }
          {
            data.length > 0 &&
            <TableOptions columns={columns} data={dataToShow} url={pathDelete} title={json.title}/>
          }
        </div>
        <div>
          {
            numberPage !== 0 && 
            <Pagination pages_number={numberPage} state={actualPage} setState={setActualpage} />
          }
        </div>
      </div>
    </div>
    </>
  )
}