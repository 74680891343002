import * as React from "react";
import * as SkillsServices from "../domain/services/SkillServices";

import SkillsEditTemplateForm from "../domain/templates/skills-edit-template-form/skills-edit-template-form";
import SkillsEditTemplateTrigger from "../domain/templates/skills-edit-template-trigger/skills-edit-template-trigger";

import SkillsEditTemplateHeader from "../domain/templates/skills-edit-template-header/skills-edit-template-header";

import "./feature-skills-edit.scss";

export default function SkillsEditModal() {
  const [tests, setTests] = React.useState([]);
  function createSkill (e : any){
    let data = {
      name: e.target[1].value,
      tests : tests,
    };
    SkillsServices.createNewSkill(data);
  }
  
  return (
    <>
    <SkillsEditTemplateTrigger/>
    <div className="modal fade" id="editSkills" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen tl-skills-edit">
        <form onSubmit={e => createSkill(e)} className="modal-content rounded-4 vh-skills-edit vw-skills-edit">
          <SkillsEditTemplateHeader />
          <SkillsEditTemplateForm tests={tests} setTests={setTests} />
        </form>
      </div>
    </div>
    </>
  )
}
