import Button from "../../atoms/Button/Button";
import SortAlphaDown from "../../atoms/SortAlphaDown/SortAlphaDown";

import "./order-button.scss"

export default function OrderButton({onClick, text, className='btn border rounded-4 fs-btn-label py-2 px-5 fw-bold color-blue wr-btn-12 d-flex align-items-center justify-content-center'}) {
  return (
    <button onClick={onClick} className={className}>
      {text} <SortAlphaDown className="bi bi-sort-alpha-down ms-1" />
    </button>
  )
}
