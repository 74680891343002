import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";

import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import FilterSelector from "../../../../common/domain/molecule/filter-selector/filter-selector";
import SkillsEditModal from "../../../feature-skills-edit/feature-skills-edit";

import "./skills-section-organims-mainManu-skills.scss";

export default function SkillsSectionOrganismMainMenu({state, setState}) {
  const data = Redux.useSelector(state => state["skills"]);

  //BUSCADOR----------------------------------------------------------------------------------------
  const searchSkillsFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchSkills: undefined});
    } else {
      let newSkills = data?.data?.filter((user : any) => {
        user.name = user.name.toLowerCase();
        return user.name.includes(e.target.value.toLowerCase());
      });
      setState({...state, searchSkills: newSkills});
    };
  };
  //------------------------------------------------------------------------------------------------
  
  //FILTRADO----------------------------------------------------------------------------------------
  const optionsSelectFilter = [
    {
      uuid: "asddfasfassddf",
      name: "Filtro 1"
    },
    {
      uuid: "asddfasfasdsdf",
      name: "Filtro 2"
    },
    {
      uuid: "asddfasafsfasdf",
      name: "Filtro 3"
    },
  ]
  //------------------------------------------------------------------------------------------------
  
  return (
    <div className="d-flex justify-content-between">
      <SkillsEditModal />
      <div className="border"></div>
      <SearchBar onKeyUp={e => searchSkillsFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchBarUser"} name={"searchBarUser"}/>
      <FilterSelector onChange={null} options={optionsSelectFilter} firstOption={Dictionary.filterString}/>
    </div>
  )
}
