import SelectInput from "../../atoms/SelectInput/SelectInput";
import SettingsIcon from "../../atoms/SettingsIcon/SettingsIcon";

import "./filter-selector.scss";

export default function FilterSelector({onChange, options, firstOption,className="text-center w-100 py-2 wr-btn-12 rounded-4 color-blue fw-bold fs-btn-label m-0 border"}) {  
  return (
    <div className="position-relative index-0 wr-btn-12">
      <SelectInput onChange={onChange} options={options} firstOption={firstOption} classSelect={className}/>
      <SettingsIcon className="bi bi-sliders2 position-absolute end-50 top-50 translate-align color-blue fw-bold"/>
    </div>
  )
}