import AuthTemplateFormLogin from "../domain/templates/auth-template-form-login/auth-template-form-login";
import AuthTemplateErrorMsgLogin from "../domain/templates/auth-template-errorMsg-login/auth-template-errorMsg-login";

export default function FeatureLogin() {
  return (
    <>
    <div className="container-fluid vh-100 vw-100 align-items-center justify-content-center">
      <div className="row h-100">
        <div className="col-12 col-xl-12 d-flex flex-column justify-content-center align-items-center mb-5 p-0">
          <AuthTemplateFormLogin />
          <AuthTemplateErrorMsgLogin />
        </div>
      </div>
    </div>
    </>
  );
}
