import AuthOrganismErrorMsgRecovery from "../../organisms/auth-organism-errorMsg-recovery/auth-organism-errorMsg-recovery";


export default function AuthTemplateErrorMsgRecovery() {
  return (
    <div className="w-25 p-5">
      <AuthOrganismErrorMsgRecovery/>
    </div>
  )
}
