export const LOGIN: string = "LOGIN";
export const REGISTER: string = "REGISTER";
export const RECOVERY_PASSWORD: string = "RECOVERY_CHANGE_PASSWORD";
export const REGISTER_CLEAR_STATE:string = "REGISTER_CLEAR_STATE";
export const GET_TABLE : string = "GET_TABLE";
export const GET_TABLE_PROFILES : string = "GET_TABLE_PROFILES";
export const GET_TABLE_PERMISSIONS : string = "GET_TABLE_PERMISSIONS";
export const GET_TAB_TABLES : string = "GET_TAB_TABLES";
export const GET_TABLE_OPTIONS : string = "GET_TABLE_OPTIONS";
export const GET_OPTIONS_PROFILES : string = "GET_OPTIONS_PROFILES";
export const GET_OPTIONS_PERMISSIONS : string = "GET_OPTIONS_PERMISSIONS";
export const GET_OPTIONS_TESTS : string = "GET_OPTIONS_TESTS";
export const CREATE_ITEM_IN_TABLE : string = "CREATE_ITEM_IN_TABLE";
export const GET_CANDIDATES_WITH_FILTER : string = "GET_CANDIDATES_WITH_FILTER";
export const GET_CANDIDATES_FILTER_AND_PAGINATION : string = "GET_CANDIDATES_FILTER_AND_PAGINATION";
export const CREATE_NEW_CANDIDATE : string = "CREATE_NEW_CANDIDATE";
export const CREATE_NEW_USER: string = "CREATE_NEW_USER";
export const CREATE_NEW_CUSTOMER : string = "CREATE_NEW_CUSTOMER";
export const UPDATA_ITEM_IN_TABLE : string = "UPDATA_ITEM_IN_TABLE";
export const DELETE_ITEM_IN_TABLE : string = "DELETE_ITEM_IN_TABLE";
export const GET_ITEM_DETAIL_TABLE : string = "GET_ITEM_DETAIL_TABLE";
export const LOGOUT : string = "LOGOUT";
export const LOGIN_CONFIRM_TOKEN : string = "LOGIN_CONFIRM_TOKEN";
export const GET_USER : string = "GET_USER";
export const GET_USER_PICTURE : string = "GET_USER_PICTURE";
export const UPDATE_USER : string = "UPDATE_USER";
export const GET_TEST_QUESTION_TYPES : string = "TEST_QUESTION_TYPES";
export const CHANGE_PASSWORD : string = "CHANGE_PASSWORD";
export const CREATE_RESPONSES : string = "CREATE_RESPONSES";
export const GET_QUESTION_OPTIONS : string = "GET_QUESTION_OPTIONS";
export const GET_USER_TEST_RESPONSE : string = "GET_USER_TEST_RESPONSE";
export const CREATE_LINK_USER_TESTS : string = "CREATE_LINK_USER_TESTS";
export const GET_LIST_USER : string = "GET_LIST_USER";
export const GET_ALL_SKILLS : string = "GET_ALL_SKILLS";
export const GET_ALL_TESTS : string = "GET_ALL_TESTS";
export const GET_ALL_TESTS_GROUP : string = "GET_ALL_TESTS_GROUP";
export const GET_ALL_TESTS_OF_SKILL : string = "GET_ALL_TESTS_OF_SKILL";
export const GET_CANDIDATES_AMOUNT : string = "GET_CANDIDATES_AMOUNT";
export const GET_EXCEL_REPORT : string = "GET_EXCEL_REPORT";
export const GET_USERS : string = 'GET_USERS';
export const GET_ALL_SESSIONS : string = 'GET_ALL_SESSIONS';
export const GET_SESSION_CANDIDATES : string = 'GET_SESSION_CANDIDATES';
export const GET_ALL_EVALUATORS : string = 'GET_ALL_SESSIONS';
export const CREATE_NEW_SESSION : string = 'CREATE_NEW_SESSION';
export const GET_ALL_DEPARTMENTS : string = 'GET_ALL_DEPARTMENTS';
export const GET_ALL_DOCUMENT_TYPE : string = 'GET_ALL_DOCUMENT_TYPE';
export const GET_ALL_BUSINESS : string = 'GET_ALL_BUSINESS';
export const GET_ALL_MUNICIPALITIES : string = 'GET_ALL_MUNICIPALITIES';
export const GET_ALL_DOMAINANT_HAND : string = 'GET_ALL_DOMAINANT_HAND';
export const GET_ALL_GENDERS : string = 'GET_ALL_GENDERS';
export const GET_ALL_HEADQUARTERS : string = 'GET_ALL_HEADQUARTERS';
export const GET_MUNICIPALITIES_BY_DEPARTMENT : string = 'GET_MUNICIPALITIES_BY_DEPARTMENT';

export const GET_DASHBOARD_ALL_BUSINESS: string = "GET_DASHBOARD_ALL_BUSINESS"
export const GET_DASHBOARD_NEXT_PROCESS_BUSINESS: string = "GET_DASHBOARD_NEXT_PROCESS_BUSINESS"
export const GET_DASHBOARD_NEXT_PROCESS_BUSINESS_CHART: string = "GET_DASHBOARD_NEXT_PROCESS_BUSINESS_CHART"
export const GET_DASHBOARD_NEXT_PROCESS_BUSINESS_HEADQUARTERS_CHART: string = "GET_DASHBOARD_NEXT_PROCESS_BUSINESS_HEADQUARTERS_CHART"
export const GET_DASHBOARD_NORMAL_DISTRIBUTION_CHART: string = "GET_DASHBOARD_NORMAL_DISTRIBUTION_CHART"

export const myStorage = window.localStorage;
export const mySessionStorage = window.sessionStorage;