import * as Redux from "react-redux";
import * as Router from "react-router";
import * as Crud from "../crud-CrudGenerator/crud-CrudGenerator";

import { myStorage } from "../../../redux/constants";

import Tabs from "../../molecule/Tabs/Tabs"

let count : number = 0;
export default function TestsUserOrganismTabs({method}) {
  const params = Router.useParams();
  if(count < 1) {
    Crud.getTabTests(`test_attempt/${params.attemptId}`);
    count++;
  };
  const data = Redux.useSelector(state => state["tabTests"]);
  const dataTabs = data["tests"]?.map((test : any) => {
    if(data["tests"].length === 1){
      test.questions.map((question : any) => {
        method(question, test);
      })
    } else {
      test.questions.map((question : any) => {
        method(question, test);
      })
    }
    return {
      name: test["name"],
      href: `/attempt/${params.attemptId}/test/${test["uuid"]}`,
      uuid: test["uuid"]
    };
  });
  return (
    <Tabs data={dataTabs}/>
  )
}
