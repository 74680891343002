import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as React from "react";

import CreateButton from "../../../../common/domain/molecule/create-button/create-button";

export default function CandidateEditOrganismTrigger() {
  return (
    <CreateButton target={'candidateEdit'} paragraph={Dictionary.create + " " + Dictionary.candidateString.toLowerCase()+" +"} />
  )
}
