import * as CustomerServices from "../../services/customerServices";
import * as React from "react";

import CustomersEditOrganismHeader from "../../organisms/customers-edit-organism-header/customers-edit-organism-header";
import CustomersEditOrganismGeneralData from "../../organisms/customers-edit-organism-generalData/customers-edit-organism-generalData";
import CustomersEditOrganismContanctData from "../../organisms/customers-edit-organism-contanctData/customers-edit-organism-contanctData";
import CustomersEditOrganismFooter from "../../organisms/customers-edit-organism-footer/customers-edit-organism-footer";

import "./customers-edit-template-body.scss";

export default function CustomersEditTemplateBody() {
  const [selected, setSelected] = React.useState("")
  function onSubmit (e : any) {
    if(selected !== ""){
      let data = {
        profileId: selected,
        password: "",
        confirmEmail: "",
        trash: false,
        enable: false,
      };
      for(let i = 1; i < 7; i++){
        data[e.target[i].id] = e.target[i].value
      } 
      CustomerServices.createNewCustomer(data);
      alert("Enviado");
    } else {
      e.preventDefault();
    }
  }  
  return (
    <form onSubmit={(e : any) => onSubmit(e)} className="modal-dialog modal-lg modal-dialog-centered">
      <div className="modal-content container px-5 rounded-4 vh-85">
        <CustomersEditOrganismHeader/>
        <div className="modal-body h-75 ">
          <CustomersEditOrganismGeneralData />
          <CustomersEditOrganismContanctData />
        </div>
        <CustomersEditOrganismFooter/>
      </div>
    </form>
  )
}