import * as Dictionary from "../../../../common/domain/constants/dictionary";
export default function EditOrganismFooter() {
  return (
    <div className="d-flex h-10 w-100 justify-content-center">
      <div className="d-flex w-60 justify-content-evenly align-items-center">
        <button type="submit" className="btn border rounded-4 w-40 fs-btn-user-edit fw-bold color-blue py-2">{Dictionary.saveChangesString}</button>
        <button type="button" className="btn border rounded-4 w-40 fs-btn-user-edit fw-bold color-blue py-2" data-bs-dismiss="modal">{Dictionary.cancelString}</button>
      </div>
    </div>
  )
}
