import CandidateDetailOrganismDetails from "../../organisms/candidate-detail-organism-details/candidate-detail-organism-details";
import CandidateDetailOrganismData from "../../organisms/candidate-detail-organism-data/candidate-detail-organism-data";

import "./candidate-detail-template-body.scss";

export default function CandidateDetailTemplateBody({information}) {
  return (
    <div className="modal fade" id={`user${information.uuid}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen top-0 start-0 tl-candidate-detail">
        <div className="modal-content rounded-4 vh-candidate-detail vw-candidate-detail overflow-auto">
          <div className="d-flex modal-body p-3">
            <CandidateDetailOrganismData information={information}/>
            <div className="border-start"></div>
            <CandidateDetailOrganismDetails information={information}/>
          </div>
        </div>
      </div>
    </div>
  )
}
