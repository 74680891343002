import { myStorage } from "../../common/redux/constants";

import TestCandidateTemplateHeader from "../domain/templates/test-candidate-template-header/test-candidate-template-header";
import TestCandidateTemplateBody from "../domain/templates/test-candidate-template-body/test-candidate-template-body";

import "./feature-tests-candidates.scss";

export default function TestsUser () {
  return (
    <>
    <div className="w-100 vh-100 position-relative">
      <TestCandidateTemplateHeader/>
      <div className="bg-body shadow-lg border rounded-4 position-absolute top-35 start-12-5 w-75 min-vh-50 h-auto">
      <TestCandidateTemplateBody/>
      </div>
    </div>
    </>
  )
}