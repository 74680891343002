import {get, post} from "../../../common/domain/services/api";
import store from "../../../common/redux/store";
import * as Constants from "../../../common/redux/constants";

export function getAllSkills (){
  get(`skills`)
  .then(res => store.dispatch({type: Constants.GET_ALL_SKILLS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getAllTestsSkill (skillUuid : string) {
  get(`skills/${skillUuid}`)
  .then(res => store.dispatch({type: Constants.GET_ALL_TESTS_OF_SKILL, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function createNewSkill ( data : any){
  post('skills', data)
  .then(res => store.dispatch({TYPE: Constants.CREATE_ITEM_IN_TABLE, payload : res.data}))
  .catch(error => {throw new Error(error)})
};