import * as React from "react";
import * as Redux from "react-redux";
import * as CustomerServices from "../../services/customerServices";

import Pagination from "../../../../common/domain/atoms/Pagination/Pagination";
import CustomerCard from "../../../../common/domain/molecule/customer-card/customer-card";

import "./customers-organism-cards.scss";


export default function CustomersOrganismCards({state}) {
  let data = Redux.useSelector(state => state["business"]);
  React.useEffect(()=>{
    CustomerServices.getCustomers();
  },[]);
  let windowHeight = window.innerHeight * 73 / 100;
  let windowWidth = window.innerWidth;
  let cardHeight : number = 384;
  let cardWidth : number = 336;
  if(windowWidth >= 1700 && windowWidth < 1900) {
    cardHeight = 432;
    cardWidth = 496;
  } else if (windowWidth >= 1900) {
    cardHeight = 592;
    cardWidth = 512; 
  };
  const [actualPage, setActualPage] = React.useState(1)
  let startData : number = 1, itemsPerPage : number = Math.floor(windowHeight / cardHeight) * Math.floor((windowWidth * 86 / 100)/cardWidth);
  let numberPage : number = 0;
  let imgNumber : number = 0;
  if(data?.length > 0){
    numberPage = Math.ceil(data?.length/itemsPerPage)
    data = data.map((customer:any) => {
      let img : string;
      switch(imgNumber){
        case (0):
          img = "https://picsum.photos/id/168/300/300";
          imgNumber++;
        break;
        case (1):
          img = "https://picsum.photos/id/180/300/300"
          imgNumber++;
        break;
        case (2):
          img = "https://picsum.photos/id/183/300/300"
          imgNumber++;
        break;
        case (3):
          img = "https://picsum.photos/id/192/300/300"
          imgNumber++;
        break;
        case (4):
          img = "https://picsum.photos/id/20/300/300"
          imgNumber = 0;
        break;
      }      
      return {
        ...customer,
        img : img
      }
    })
  };
  let dataToShow = data?.length > 0 && data?.slice(startData*actualPage*itemsPerPage - itemsPerPage, itemsPerPage*actualPage);
  if(numberPage === 1)numberPage = 0
  else if(Number.isNaN(numberPage))numberPage = 0;
  
  return (
    <>
    <div className="mx-4 mt-2 d-flex flex-wrap">
      {
        state.searchCustomers !== undefined ?
          state.searchCustomers?.map((d : any) => (
            <div className="m-4">
              <CustomerCard information={d} />
            </div>
          )) 
          :
          dataToShow?.length > 0 && (typeof data !== "string") && dataToShow.map((d : any) => (
            <div className="m-4">
              <CustomerCard information={d} />
            </div>
          ))
      }
    </div>
    <div className="mx-4">
    {
      numberPage !== 0 && state.searchCustomers === undefined &&
      <Pagination pages_number={numberPage} state={actualPage} setState={setActualPage} />
    }
    </div>
    </>
  )
}
