import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as React from "react";
import * as Redux from "react-redux";
import * as TestServices from "../../services/TestServices";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import Input from "../../../../common/domain/atoms/Input/Input";
import SearchIcon from "../../../../common/domain/atoms/SearchIcon/SearchIcon";
import SelectInput from "../../../../common/domain/atoms/SelectInput/SelectInput";
import Button from "../../../../common/domain/atoms/Button/Button";
import SkillCard from "../../../../common/domain/molecule/SkillCard/SkillCard";
import TestCard from "../../../../common/domain/molecule/test-card-one/test-card-one";


let count : number = 0
export default function AssignTestModalOrganismBody({assign, setSkill, setTest, skill, test}) {
  const skillsInformation = Redux.useSelector(state => state["skills"]);
  const testsInformation = Redux.useSelector(state => state ["tests"]);
  const [searchSkill, setSearchSkill] = React.useState(skillsInformation?.data);
  const [searchTest, setSearchTest] = React.useState(testsInformation?.tests);

  if (count === 0) {
    TestServices.getAllSkills();
  count++;
  };

  const searchTestsFunction = (e : any) : void => {
    let newTestsInformation = testsInformation.tests.filter((test : any) => {
      test.name = test.name.toLowerCase();
      return test.name.includes(e.target.value);
    })
    setSearchTest(newTestsInformation);
  };

  const searchSkillsFunction = (e : any) : void => {
    let newSkillsInformation = skillsInformation.data.filter((skill : any) => {
      skill.name = skill.name.toLowerCase();
      return skill.name.includes(e.target.value);
    });
    setSearchSkill(newSkillsInformation);
  };

  const selectSkill = (uuid : string) => {
    TestServices.getAllTestsSkill(uuid);
    setSkill(uuid);
    setSearchTest(undefined);
  };

  const optionsSelectFilter = []
  
  return (
    <div className="modal-body">
      {
        assign 
        ?
        <div className="alert alert-success container h-90 d-flex justify-content-center align-items-center">
          <Paragraph contentTwo={Dictionary.testAssignedSuccessfully} className="" />
        </div>
        :
      <div className="container h-90">
        <Paragraph contentTwo={Dictionary.selectTestsToAssignString} className="m-0 fs-6 my-3 border-bottom pb-2"/>
        <div className="h-90 justify-content-between align-items-center ">

          <div className="d-flex w-100 justify-content-between w-100">
            <div className="w-50">
              <Paragraph contentTwo={Dictionary.skillsMatrix} className="m-0 fs-7 fw-bold mb-2"/>
              <div className="w-90 d-flex justify-content-between">
                <div className="w-50">
                  <Input onChange={(e)=> searchSkillsFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchBarSkill"} name={"searchBarSkill"} className={"w-90 m-0 rounded-4 ps-3 pe-5 py-2 fs-8 border"}/>
                  <SearchIcon className="bi bi-search text-secondary m-0" />
                </div>
                <SelectInput options={optionsSelectFilter} firstOption={Dictionary.filterString} classSelect={"text-center w-45 py-2 rounded-4 text-secondary fs-8 m-0"}/>
              </div>
            </div>
            <div className="w-45">
              <Paragraph contentTwo={Dictionary.testMatrixString} className="m-0 fs-7 fw-bold mb-2" />
                <div className="d-flex w-90 justify-content-between w-100">
                  <Button text={ Dictionary.create + " " + Dictionary.test_string.toLowerCase()}  className={"py-2 px-5 border rounded-4 bg-body fs-8"}/>
                  <div className="w-50">
                    <Input onChange={(e) => searchTestsFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchBarSkill"} name={"searchBarSkill"} className={"w-90 rounded-4 px-3 py-2 fs-8 border"}/>
                    <SearchIcon className="bi bi-search text-secondary m-0" />
                  </div>
                </div>
            </div>
          </div>

          <div className="h-90 w-100">
            <div className="d-flex h-100 justify-content-between">
              <div className="h-80 mt-3 w-45 overflow-auto d-flex flex-wrap">
                {
                  searchSkill !== undefined ? (
                    searchSkill?.map((d : any) => {
                      return (
                        <div onClick={() => selectSkill(d.uuid)} className={skill === d.uuid ? "shadow m-2 w-20 h-35 rounded-4 pointer bg-color-light-blue" : "m-2 w-20 h-35 rounded-4 pointer"} >                            
                          <SkillCard information={d} detail={false}/> 
                        </div>
                      )
                    })
                  )
                  : 
                  (
                    skillsInformation?.data?.map((d : any) => {
                      return (
                        <div onClick={() => selectSkill(d.uuid)} className={skill === d.uuid ? "shadow m-2 w-20 h-35 rounded-4 pointer bg-color-light-blue" : "m-2 w-20 h-35 rounded-4 pointer"} >                            
                          <SkillCard information={d} detail={false}/> 
                        </div>
                      )
                    })
                  )
                }
              </div>
              <div className="border h-60 mt-5"></div>
              <div className="h-80 mt-3 w-45 overflow-auto">
                {
                  searchTest === undefined ? (
                    testsInformation.tests?.map((d : any) => (
                      <div className={test === d.uuid ? "my-3 rounded-4 shadow bg-color-light-blue pointer" : "my-3 pointer"}>
                        <div className="pointer" onClick={() => {setTest(d.uuid)}}>
                          <TestCard information={d} detail={false} />
                        </div>
                      </div>
                    ))
                  )
                  :
                  (
                    searchTest?.map((d : any) => (
                      <div className={test === d.uuid ? "my-3 rounded-4 shadow bg-color-light-blue pointer" : "my-3 pointer"}>
                        <div className="pointer" onClick={() => {setTest(d.uuid)}}>
                          <TestCard information={d} detail={false} />
                        </div>
                      </div>
                    ))
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  )
}