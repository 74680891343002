import * as Dictionary from "../../constants/dictionary";
import { myStorage } from "../../../redux/constants";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import HammerIcon from "../../atoms/HammerIcon/HammerIcon";
import EditButton from "../edit-button/edit-button";
import "./person-card.scss"

export default function PersonCard({information = {}}) {
  function sendInformationToLocalStorage () {
    for(let key in information){
      myStorage.setItem(`${key}`, `${information[key]}`);
    }
  }
  information["phone"] = "300 000 0000";
  return (
    <div className="wr-user-card hr-user-card d-flex flex-column align-items-center justify-content-center border rounded-4 shadow-user-card">
      <div className="w-75">
        <div className="w-100 d-flex align-items-center my-2">
          <img className="mx-picture-user-card wr-picture-user-card rounded-circle" src={information["img"]} alt="Img not found" />
        </div>
        <div className="text-center my-2 mt-3">
          <HammerIcon className="bi bi-hammer m-0 color-blue fs-user-card-0"/>
          <Paragraph contentTwo="Administrador" className="fs-user-card-1 m-0 fw-bold" />
          <Paragraph contentTwo={information["name"]} className={"border-dark text-truncate fs-user-card-1"}/>
          <div onClick={sendInformationToLocalStorage}>
            <EditButton paragraph={Dictionary.editString} className="bg-blue text-white border rounded-4 fs-user-card-2 fw-bold padding-edit-button" target={'userEdit'}/>
          </div>
        </div>
        <div className="border-top">
          <Paragraph contentTwo={Dictionary.informationString} className="fs-user-card-2 my-2 fw-bold" />
          <div className="d-flex justify-content-between">
            <div className="w-50">
              <Paragraph contentTwo="Email" className="m-0 fs-user-card-2 text-secondary text-truncate"/>
              <Paragraph contentTwo={information["email"]} className="m-0 fs-user-card-3 text-secondary text-truncate"/>
            </div>
            <div>
              <Paragraph contentTwo={Dictionary.contactString} className="m-0 fs-user-card-2 text-secondary text-truncate"/>
              <Paragraph contentTwo={information["phone"]} className="m-0 fs-user-card-3 text-secondary text-truncate"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
