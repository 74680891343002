import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import FormText from "../../../../common/domain/atoms/FormText/FormText";

import "./customers-edit-organism-contanctData.scss";

export default function CustomersEditOrganismContanctData() {
  const classDiv = "d-flex flex-column w-33";
  const classInput = "text-center w-85 py-2 rounded-4 border fs-label-customer-edit mb-2 mx-3";
  const classLabel = "fs-label-customer-edit mx-4 mb-1 fw-bold";
  
  return (
    <div className="h-40">
      <Paragraph contentTwo={Dictionary.contactPersonString + " :"} className="mb-2 ms-2 fs-general-title" />
      <div className="h-85 w-100 border-top d-flex flex-wrap pt-3">
        <FormText id={"contanct-name"} name={"contanct-name"} label={Dictionary.nameString} placeholder={Dictionary.nameString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
        <FormText id={"contanct-phone"} name={"contanct-phone"} label={Dictionary.phoneString} placeholder={Dictionary.phoneString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
        <FormText id={"contanct-email"} name={"contanct-email"} label={Dictionary.emailString} placeholder={Dictionary.emailString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
        <FormText id={"contanct-documentNumber"} name={"contanct-documentNumber"} label={Dictionary.identificationString} placeholder={Dictionary.identificationString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
        <div className="d-flex w-33 align-items-center justify-content-center">
          <button type="button" className="w-85 py-2 fs-label-customer-edit rounded-4 border color-blue fw-bold bg-transparent" >Agregar contacto +</button>
        </div>
      </div>
    </div>
  )
}