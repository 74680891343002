import * as Dictionary from "../../common/domain/constants/dictionary";

import UsersSectionTemplatesHeader from "../domain/templates/users-section-templates-header/users-section-templates-header";
import TemplateHeader from "../../common/domain/template/template-header/template-header";
import UserSectionTemplatesBody from "../domain/templates/users-section-templates-body/users-section-templates-body";
import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";

import "./feature-users-section.scss";

export default function UsersSection() {
  return (
    <>
    <MenuTemplate section={Dictionary.users_string}/>
      <div className="d-flex flex-column align-items-end w-100">
        <div className="vh-15 w-86"></div>
        <LoadingSpinner div={<div className="w-86 position-relative"><TemplateHeader title={Dictionary.users_string} subTitle={Dictionary.modifyRolesString + " :"}/><UserSectionTemplatesBody/></div>}/> 
      </div>
    </>
  )
};
