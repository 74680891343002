import * as Constants from "../../../common/redux/constants";

import {get, put, post, deleted} from "../../../common/domain/services/api";
import store from "../../../common/redux/store/index";


export function getAllSkills (){
  get(`skills`)
  .then(res => store.dispatch({type: Constants.GET_ALL_SKILLS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getAllTestsSkill (skillUuid : string) {
  get(`skills/${skillUuid}`)
  .then(res => store.dispatch({type: Constants.GET_ALL_TESTS_OF_SKILL, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getAllTestsGroup () {
  get(`test_group`)
  .then(res => store.dispatch({type: Constants.GET_ALL_TESTS_GROUP, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function linkUserTest (path : string, data : any){
  post(path, data)
  .then(res => store.dispatch({type: Constants.CREATE_LINK_USER_TESTS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function createNewTest(data : any){
  post('tests', data)
  .then(res => store.dispatch({type: Constants.CREATE_ITEM_IN_TABLE, payload: res.data}))
  .catch(error => {throw new Error})
}