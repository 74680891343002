import * as Dictionary from "../../../../common/domain/constants/dictionary";

import CreateButton from "../../../../common/domain/molecule/create-button/create-button";

import "./customers-edit-organism-trigger.scss";

export default function CustomersEditOrganismTrigger() {
  return (
    <CreateButton target={'customerEdit'} paragraph={Dictionary.create + " " + Dictionary.customerString.toLowerCase()+" +"}  />
  )
}