import Input from "../Input/Input";
import Label from "../Label/Label";

export default function InputForm({
  required,
  id= "",
  infoMsg = "",
  text = "",
  type = "",
  expression,
  classNameI = "form-control fs-8 mb-3 shadow-sm bg-body",
  classNameL = "form-label fs-8",
  name="name",
  defaultValue = "",
  }) {
  return (
    <div>
      <Label htmlFor={id} msg={text} className={classNameL} />
      <Input
        className = {classNameI}
        required = {required}
        defaultValue={defaultValue}
        id={id}
        name={name}
        // placeholder={text}
        type={type}
        expression={expression}
        title={infoMsg}
        />
    </div>
  );
}
