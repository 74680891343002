import Paragraph from "../../atoms/Paragraph/Paragraph";
import ResultsReportModal from "../../../../cadidates/feature-results-report/feature-results-report";

export default function CandidatesReportTableBody({data, state}) {
  const newData = data?.map((d)=>{
    let status : boolean = true;
    d.attemptTests.forEach((test)=>{
      if(test.status === false) status = false;
    });
    return {
      ...d,
      status,
    };
  });
  let dataToshow = [];
  switch (state) {
    case "all":
      dataToshow = newData;
    break;

    case "noDone":
      dataToshow = newData.filter((d)=> {
        return d.status === false;
      });
    break;

    case "done":
      dataToshow = newData.filter((d)=>{
        return d.status === true;
      });
    break;
  }
  return (
    <tbody>
      {
        dataToshow?.map((d)=>(
          <>
          <tr className="fs-7">
            <th>{d.name}</th>
            <th>
              {
                d.status 
                ? <Paragraph contentTwo="Realizado" className="text-success m-0" />
                : <Paragraph contentTwo="Pendiente" className="text-danger m-0" />
              }
            </th>
            <th><ResultsReportModal attemptUuid={d.attemptUuid} status={d.status}/></th>
          </tr>
          </>
        ))
      }
    </tbody>
  )
}
