export default function SelectRadio({id, options, name, text, required = true}) {  
  return (
    <div>
      <h6 className="fs-8">{text.toUpperCase()}</h6>
      {
        options?.map((o: any) => (
          <div key={o} className='form-check'>
            <input className='form-check-input fs-8' type={"radio"} id={id} name={name} value={o.value} required={required}/>
            <label className='form-check-label fs-8' htmlFor={o.value} >{o.name}</label>
          </div>
        ))
      }
    </div>
  );
};
