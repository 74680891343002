import * as React from "react";

import SkillsSectionOrganismMainMenuSkills from "../../organisms/skills-section-organims-mainManu-skills/skills-section-organims-mainManu-skills";
import SkillsSectionOrganismBodySkill from "../../organisms/skills-section-organism-body-skill/skills-section-organism-body-skill";
import SkillsSectionOrganismBodyTest from "../../organisms/skills-section-organism-body-test/skills-section-organism-body-test";
import SkillsSectionOrganismMainMenuTests from "../../organisms/skills-section-organism-mainMenu-tests/skills-section-organism-mainMenu-tests";

import "./skills-section-template-body.scss";

export default function SkillsSectionTemplateBody() {
  const [skillSelected, setSkillSelected] = React.useState("");
  const states = {order: false, searchSkills: undefined};
  const [state, setState] = React.useState(states);
  return (
    <div className="d-flex h-74 ms-5 w-95 justify-content-between">
      <div className="w-65 h-100">
        <SkillsSectionOrganismMainMenuSkills state={state} setState={setState} />
        <div className="d-flex justify-content-center w-100 h-100">
          <SkillsSectionOrganismBodySkill state={state} setSkillSelected={setSkillSelected} skillSelected={skillSelected}/>
        </div>
      </div>
      <div className="border border-start h-100 mt-3"></div>
      <div className="w-30 h-100">
        <SkillsSectionOrganismMainMenuTests />
        <SkillsSectionOrganismBodyTest/>
      </div>
    </div>
  )
}
