import * as Dictionary from "../../constants/dictionary";
import * as RouterDom from "react-router-dom";
import * as AuthServices from "../../services/AuthServices";

import InputForm from "../../../../common/domain/atoms/InputForm/InputForm";
import Button from "../../../../common/domain/atoms/Button/Button";
import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";

import "./auth-organism-form-login.scss";

const logo = require("../../../../assets/logoBlanco.png");

export default function AuthOrganismFormLogin(){
  const onSubmit = (e: any) => {
    e.preventDefault();
    let user = {
      email: e.target[0].value,
      password: e.target[1].value
    }
    AuthServices.login(user)
    for (let i = 0; i < e.target.length - 1; i++) {
      e.target[i].value = "";
    }
  };
  return (
    <>
      <img className="img-login-logo mt-5" src={logo} alt="Img not found" />
        <Paragraph contentTwo={Dictionary.LogIn} className="text-center fs-4 m-0 mt-5 m-0" />
        <Paragraph contentTwo={Dictionary.loginInfo} className="text-center fs-8 text-secondary" />
        <form className={"container-fluid d-flex flex-column align-items-center mt-4"} onSubmit={(e) => onSubmit(e)}>
          <div className="w-50 mb-4">
            <InputForm
              classNameI={"form-control shadow-none mb-0 bg-light rounded py-2 mb-1 fs-9"}
              classNameL={"text-secondary fs-9"}
              text={Dictionary.email.toUpperCase()}
              expression={null}
              type={Dictionary.typeText}
              required={true}
            />
          </div>
          <div className="w-50 mb-4">
            <InputForm
              classNameI={"form-control shadow-none mb-0 bg-light rounded py-2 mb-1 fs-9"}
              classNameL={"text-secondary fs-9"}
              text={Dictionary.password.toUpperCase()}
              expression={null}
              type={Dictionary.typePassword}
              required={true}
            />
            <div className="d-flex justify-content-end">
              <RouterDom.Link className="text-decoration-none" to="/recovery">
                <Paragraph contentTwo={Dictionary.forgetPassword.toUpperCase()} className="m-0 fs-10 fw-bold d-inline" />
              </RouterDom.Link>
            </div>
          </div>
          <div className={"w-100 text-center mb-3"}>
            <Button className={"w-50 p-3 btn btn-primary fs-8"} text={Dictionary.singIn}/>
          </div>
          {/* <div className={"mt-2"}>
            <p className="fs-8">
              {Dictionary.doYouHaveAccount}{" "}
              <RouterDom.Link className="text-decoration-none" to="/register">
                {Dictionary.createAccount}
              </RouterDom.Link>
            </p>
          </div> */}
        </form>
    </>
  )
}