import * as Dictionary from "../../constants/dictionary";
import * as React from "react";

import Button from "../../atoms/Button/Button";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import InputForm from "../../atoms/InputForm/InputForm";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import SelectRadio from "../../atoms/SelectRadio/SelectRadio";

export default function FormsGenerator({json, path, method, reload = false, preValues={}}) {
  const switchAtoms = (d : any) => {
    switch (d["tests_questions_type.name"]) {
      case "InputFormText":
        let textInputFormText = d.text
        if (d.strict === true){
          textInputFormText = textInputFormText + " *"
        } 
        return (
          <div className="m-4">
            <InputForm id={d.uuid} name={d.name} text={textInputFormText} type={"text"} expression={null} infoMsg={d.infoMsg} required={d.strict} defaultValue={d.defaultValue} />
          </div>
        )

      case "InputFormNumber":
        let textInputFormNumber = d.text
        if (d.strict === true){
          textInputFormNumber = textInputFormNumber + " *"
        } 
        return (
          <div className="m-4">
            <InputForm id={d.uuid} name={d.name} text={textInputFormNumber} type={"number"} expression={null} infoMsg={d.infoMsg} required={d.strict} defaultValue={d.defaultValue} />
          </div>
        )

      case "SelectInput":
        let textSelectInput : string = d.text
        if(d.strict){
          textSelectInput = textSelectInput + " *"
        }
        return (
          <div className="m-4">
            <SelectInput id={d.uuid} name={d.name} text={textSelectInput} options={d.options} required={d.strict} defaultValue={d.defaultValue}/>
          </div>
        )
        
      case "SelectRadio":
        let titleRadio: string = d.text
        if(d.strict) titleRadio = titleRadio + " *"
        return (
          <div className="m-4 mt-0">
            <SelectRadio id={d.uuid} text={titleRadio} options={d.options} name={d.name} required={d.strict} />
          </div>
        )

      case "Checkbox":
        let textCheckbox : string = d.text
        if(d.strict) textCheckbox = textCheckbox + " *"
        return (
          <div className="m-4">
            <Checkbox id={d.uuid} name={d.name} text={textCheckbox} required={d.strict}/>
          </div>
        )

      default:
        return null
    }
  }  
  const [complete, setComplete] = React.useState(null)
  const onSubmit = (e : any) => {
    e.preventDefault();
    let data : any;
    if(Object.keys(preValues).length > 0){
      data = []
      for( let i = 0; i < e.target.length - 1; i++){
        let newData = {
          ...preValues
        } 
        if(e.target[i].hasOwnProperty("checked")){
          if(e.target[i].checked && e.target[i].name !== "checkbox") {
            if(e.target[i].value === "true"){
              newData["questionId"] = e.target[i].id
              (newData["value"] = true)
            } else if(e.target[i].value === "false"){
              newData["questionId"] = e.target[i].id
              (newData["value"] = false)
            } else {
              newData["questionId"] = e.target[i].id
              (newData["value"] = e.target[i].value)
            }
          }
          data.push(newData)
        } else {
          newData["questionId"] = e.target[i].id
          newData["value"] = e.target[i].value
          data.push(newData)
        }
      }
    } else {
      data = {}
      for( let i = 0; i < e.target.length - 1; i++){
        if(e.target[i].hasOwnProperty("checked")){
          if(e.target[i].checked && e.target[i].name !== "checkbox") {  
            if(e.target[i].value === "true"){
              (data[e.target[i].name] = true)
            } else if(e.target[i].value === "false"){
              data[e.target[i].name] = false
            } else {
              (data[e.target[i].name] = e.target[i].value) 
            }
          }
        } else {
          data[e.target[i].name] = e.target[i].value
        }
      }
    }
    method(path, data)
    setComplete(true)
    if(reload){
      setTimeout(()=>{
        window.location.reload()
      },1500)
    }
  }
  return (
    <>
      {
        complete
        ?
        <div className="d-flex flex-column align-items-center mt-5 pt-5">
         <p className="alert alert-success p-5 mt-5">{Dictionary.alertCompleteForm}</p> 
        </div>
        :
        <div className="d-flex flex-column align-items-center pt-5 ps-5 ms-5 mt-5">
          <h3 className="fs-5">{json.title}</h3>
          <h6 className="fs-8">{Dictionary.strictModeForm}</h6>
          <form className="w-50 p-3" onSubmit={(e)=>onSubmit(e)}>
            {
              json.data?.map((d:any) => (
                  <div key={d["tests_questions_type.name"]}>
                    {switchAtoms(d)}
                  </div>
              ))
            }
            <Button text={Dictionary.buttonSend} className="btn btn-primary mx-4 px-5 mb-5 fs-8" />
          </form>
          {
            complete === false &&
            <div className="alert alert-danger mt-5 pt-5">{Dictionary.alertNocompleteForm}</div>
          }
        </div>
      }
    </>
  )
}