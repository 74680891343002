import * as React from "react";
import * as Redux from "react-redux";
import * as SessionServices from "../../services/SessionServices";
import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as apiServices from "../../../../common/domain/services/api";
import axios from "axios";

export default function SessionDetailsOrganismTable({sessionId}) {
  React.useEffect(() => {
    SessionServices.getSessionCandidates(sessionId);
  }, [])

  let sessionCandidates = Redux.useSelector(state => state['sessionCandidates']);

  function downloadFile(data, filename){
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = filename
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }

  function downloadIndividualReport(row){
    let filename = row.business + ' - ' + row.name + '.pdf';
    apiServices.get(Dictionary.individualUrl + row.attemptId).then(response => {
      let data = new Blob([response.data], {type: 'application/pdf'})
      downloadFile(data, filename)
    })
  }

  function downloadSISEReport(){
    let filename = 'Reporte SISE.xls';
    axios.get(process.env.REACT_APP_API_URL + Dictionary.siseUrl + sessionId, {
      responseType: 'blob',
      headers: {
        'Authorization' : `Bearer ${localStorage.getItem("token")}`
      }
    }).then((response: any) => {
      downloadFile(response.data, filename)
    })
  }

  function downloadAllIndividualReports(){
    sessionCandidates.map(item => {
      downloadIndividualReport(item)
    })
  }

  return (
    <>
      <div className="actions-container">
        <button className="btn btn-sm btn-primary ms-4" onClick={downloadSISEReport}>Reporte SISE</button>
        <button className="btn btn-sm btn-success ms-2" onClick={downloadAllIndividualReports}>Descarga masiva</button>
      </div>
      <div className="p-4">
        <>
        <table className="table table-striped">
          <th>Fecha de creación</th>
          <th>Documento</th>
          <th>Correo</th>
          {
            sessionCandidates.map(row => (
              <tr>
                <td>{ row.name }</td>
                <td>{ row.documentNumber }</td>
                <td>{ row.email }</td>
                <td>
                  <button className="btn btn-sm btn-primary text-dark" onClick={() => downloadIndividualReport(row)}>Informe</button>
                </td>
              </tr>
            ))
          }
        </table>
        </>
      </div>
    </>
  )
}
