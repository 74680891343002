import ResultReportOrganismUsername from "../../organisms/result-report-organism-username/result-report-organism-username"; 
import ResultReportOrganismValorationCards from "../../organisms/result-report-organism-valorationCards/result-report-organism-valorationCards";
import ResultReportOrganismValorationCardsB from "../../organisms/result-report-organism-valorarionCardB/result-report-organism-valorarionCardB";
import ResultReportOrganismRadarCard from "../../organisms/result-report-organism-radarCard/result-report-organism-radarCard";

import "./result-report-template-body.scss";
import { REPORT_TERMS } from "../../constants/reportsConstants";
import DiscCard from "../../../../common/domain/molecule/DiscCard/DiscCard";

export default function ResultReportTemplateBody({userResponses}) {
  return (
    <>
    <div className="mt-4">
      <ResultReportOrganismUsername userResponses={userResponses} />
    </div>
    <div className="container">
      <div className="mt-4">
        <ResultReportOrganismValorationCardsB information={userResponses.analysis.lateralThinkingTest} title={ REPORT_TERMS.lateralThinking }/>
      </div>
      <div className="mt-5">
        <ResultReportOrganismValorationCardsB information={userResponses.analysis.numericalEstimateTest} title={ REPORT_TERMS.numericEstimation }/>
      </div>
      <div className="mt-5">
        <ResultReportOrganismValorationCardsB information={userResponses.analysis.logicalThinkingTest} title={ REPORT_TERMS.logicalThinking }/>
      </div>
      <div className="mt-5">
        {
          userResponses.analysis.disc ?
            <div>
              <p className="fs-5">Prueba: Disc</p>
              <p className="fs-6 fw-normal m-0">Prueba psicotécnica; que evalúa la conducta es decir cómo nos comportamos</p>
              <p className="fs-6 fw-normal m-0">El estilo de comportamiento que la persona muestra en situaciones cotidianas, así como los cambios que realiza en función del contexto laboral actual.</p>
          
              <div className="mt-4">
                <DiscCard type={userResponses.analysis.disc.charAt(0)} title={ REPORT_TERMS.disc } description={userResponses.analysis.disc}/>
              </div>
            </div>
            :
            <div></div>
        }
      </div>
      <div className="mt-5 mb-5">
        <p className="fs-5">Prueba: Específica</p>
        <p className="fs-6 fw-normal">De acuerdo a su conocimiento la persona presenta capacidades para desempeñarse en:</p>
        <div className="d-flex justify-content-center mt-4">
          <ResultReportOrganismRadarCard valoration={userResponses} />
        </div>
      </div>
    </div>
    </>
  )
}
