import RadarCard from "../../../../common/domain/molecule/RadarCard/RadarCard";
import "./result-report-organism-radarCard.scss";

export default function ResultReportOrganismRadarCard({valoration}) {
  return (
      <div className="w-35 bg-white rounded-4 h-90">
        <RadarCard valoration={valoration} />
      </div>
  )
}
