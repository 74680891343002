import AuthTemplateFormRecovery from "../domain/templates/auth-template-form-recovery/auth-template-form-recovery";
import AuthTemplateErrorMsgRecovery from "../domain/templates/auth-template-errorMsg-recovery/auth-template-errorMsg-recovery";
import AuthTemplateSuccessMsgRecovery from "../domain/templates/auth-template-successMsg-recovery/auth-template-successMsg-recovery";

export default function Recovery() {
  return (
    <div className="bg-light vh-100 d-flex flex-column align-items-center justify-content-center">
      <AuthTemplateFormRecovery/>
      <AuthTemplateErrorMsgRecovery/>
      <AuthTemplateSuccessMsgRecovery/>
    </div>
  );
}

