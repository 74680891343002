import * as Dictionary from "../../constants/dictionary";
import "./table-notification.scss";

export default function TableNotification({columns, data}) {
  return (
    <table className="table table-striped table-bordered">
    <thead>
      <tr>
        {
          columns?.map((c: string) => (
            c !== 'uuid' &&(
              Dictionary.terms.hasOwnProperty(c) 
              ? <th key={c} className='text-start align-middle fs-th-table-notification-0 py-3 px-3' colSpan={1} scope="col">{Dictionary.terms[c].charAt(0).toUpperCase()}{Dictionary.terms[c].slice(1)}</th>
              : <th key={c} className='text-start align-middle fs-th-table-notification-0 py-3 px-3' colSpan={1} scope="col">{c.charAt(0).toUpperCase()}{c.slice(1)}</th>
            )
          ))
        }
      </tr>
    </thead>
    <tbody>
      {
        data?.map((d : any)=>(       
          <tr className="table-row-size" key={d.count}>
            <th className='text-start fs-th-table-notification-0 p-0 align-middle py-5 px-3'>{d.count}</th>
            {
              columns?.map((c : string)=>(
                c !== 'uuid' && c !== 'Sesiones No.' && (
                  Dictionary.terms.hasOwnProperty(d[c])
                  ? <th colSpan={1} className='text-start fs-th-table-notification-0 p-0 align-middle py-5 px-3' key={d[c]}> {Dictionary.terms[d[c]]} </th>
                  : <th colSpan={1} className='text-start fs-th-table-notification-0 p-0 align-middle py-5 px-3' key={d[c]}> {d[c]} </th>
                )
              ))
            }
          </tr>
        ))
      }
    </tbody>
  </table>
  )
}
