import PositionEditTemplateTrigger from "../domain/templates/position-edit-template-trigger/position-edit-template-trigger";
import PositionEditTemplateHeader from "../domain/templates/position-edit-template-header/position-edit-template-header";
import PositionEditTemplateForm from "../domain/templates/position-edit-template-form/position-edit-template-form";
import "./feature-position-edit.scss";
export default function PositionEdit() {
  return (
    <>
    <PositionEditTemplateTrigger/>
    <div className="modal fade" id="positionEdit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-fullscreen tl-position-edit">
        <PositionEditTemplateForm />
      </div>
    </div>
    </>
  )
}
