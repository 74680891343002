import * as Dictionary from "../../../../common/domain/constants/dictionary";
import TemplateHeader from "../../../../common/domain/template/template-header/template-header"
import SessionsEdit from "../../../feature-sessions-edit/feature-sessions-edit";
import { useParams } from "react-router";
import SessionDetailsOrganismTable from "../../organisms/session-details-organism-table/session-details-organism-table";

export default function SessionDetailsTemplateContent() {
  const { id } = useParams()

  return (
    <>
    <TemplateHeader title={Dictionary.sessionDetail} subTitle={Dictionary.hereYouWillFindAllActiveSessions} />
    <SessionDetailsOrganismTable sessionId={id} />
    </>
  )
}
