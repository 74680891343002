import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as React from "react";
import * as UserServices from "../../services/UserServices";
import * as Redux from "react-redux";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import ProfileCard from "../../../../common/domain/molecule/ProfileCard/ProfileCard";

import "./user-edit-organism-profiles.scss";

export default function UserEditOrganismProfiles({selected, setSelected}) {
  React.useEffect(()=>{
    UserServices.getProfiles('profiles');
  },[])
  const profilesData = Redux.useSelector(state => state['profilesTable']);

  (() => {
    let count : number = 0;
    profilesData?.data?.map((profile : any)=>{
      switch(count){
        case (0):
          profile['icon'] = "bi bi-robot";
          count++;
        break;
        case(1):
          profile['icon'] = "bi bi-chat-square-dots";
          count++;
        break;
        case(2):
          profile['icon'] = "bi bi-mic";
          count++;
        break;
        case(3):
          profile['icon'] = "bi bi-puzzle";
          count = 0;
        break;
      }
    })
  })()  
  return (
    <div className="h-40">
      <Paragraph contentTwo={Dictionary.profilesString + " :"} className="mb-2 ms-2 fs-general-title" />
      <div className="h-85 d-flex flex-wrap border-top overflow-auto">
        {
          profilesData?.data?.map((p : any) => (
            p.name !== 'candidate' &&
            <ProfileCard data={p} selected={selected} setState={setSelected} />
          ))
        }
      </div>
    </div>
  )
}
