import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import { resultsReportString } from "../../../../common/domain/constants/dictionary";
import { REPORT_TERMS } from "../../constants/reportsConstants";

export default function ResultReportOrganismHeader({setLoading}) {
  function loadingFunction(){
    setLoading(true);
  };  
  return (
    <>
      <div className="d-flex justify-content-end me-3">
        <button onClick={loadingFunction} type="button" className="btn fs-4" data-bs-dismiss="modal" aria-label="Close">X</button>
      </div>
      <div className="w-100 h-100 d-flex justify-content-center align-items-center modal-header pt-5">
        <div className="title-container bg-dark text-white fs-4 p-1 ps-4 pe-4">
          <span>{ REPORT_TERMS.title }</span>
        </div>
      </div>
    </>
  )
}
