import * as Dictionary from "../../common/domain/constants/dictionary";

import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import PositionSectionTemplatePositionCards from "../domain/templates/position-section-template-positionCards/position-section-template-positionCards";
import PositionSectionTemplateSkillCards from "../domain/templates/position-section-template-skillCards/position-section-template-skillCards";
import PositionSectionTemplateTestCards from "../domain/templates/position-section-template-testCards/position-section-template-testCards"
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";

import "./feature-position-section.scss";
import TemplateHeaderTwo from "../../common/domain/template/template-header-two/template-header-two";
import "./feature-position-section.scss";
export default function PositionSection() {
  return (
    <>
    <MenuTemplate section={Dictionary.positionString}/>
    <div className="d-flex flex-column align-items-end w-100">
    <div className="vh-15 w-86"></div>
    <LoadingSpinner div={
      <div className="d-flex justify-content-evenly w-86">
        <PositionSectionTemplatePositionCards/>
        <div className="w-30">
          <TemplateHeaderTwo title={Dictionary.skillsMatrixTests} subtitle={""} />
          <PositionSectionTemplateSkillCards/>
          <PositionSectionTemplateTestCards />
        </div>
      </div>
    }/>
    </div>
    </>
  )
}
