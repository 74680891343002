import * as Dictionary from "../../constants/dictionary";

import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./sidebar-userCard.scss";

export default function SidebarUserCard({data}) {  
  return (
    <div className="text-white d-flex h-100 align-items-center cursor-default">
      <div className="h-100">
      <img className="rounded-userImage me-2 w-userImage" src='https://picsum.photos/id/823/200/200' alt="Img not found" />
        {/* <UserIcon/> */}
      </div>
      <div className="h-100 d-flex flex-column justify-content-center w-60">
        <Paragraph contentTwo={Dictionary.hiString} className="m-0 fs-hi fw-bold" />
        <Paragraph contentTwo={data.name} className="m-0 fs-user-name text-truncate"/>
        <Paragraph contentTwo={"Administrador"} className='fs-user-roll m-0'/>
      </div>
    </div>
  )
}