import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import { ucfirst } from "../../../../common/domain/services/stringServices";

export default function ResultReportOrganismUsername({userResponses}) {
  let username = userResponses?.user?.name.split(' ').map(item => ucfirst(item)).join(' ')

  return (
    <div className="d-flex w-100 align-items-center justify-content-center">
      <Paragraph contentTwo={username} className="m-0 fs-3"/>
    </div>
  )
}
