import * as Dictionary from "../../constants/dictionary";

import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./ModalHeader.scss";

export default function ModalHeader({section}) {
    return (
        <div className="rounded-bottom rounded-4 d-flex align-items-end justify-content-center">
          <Paragraph contentTwo={Dictionary.editionString + " " + Dictionary.ofString + " " + section} className="m-0 fs-modal-header-0" />
        </div>
      )
}