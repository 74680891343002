import CandidateDetailTemplateTrigger from "../domain/templates/candidate-detail-template-trigger/candidate-detail-template-trigger";
import CandidateDetailTemplateBody from "../domain/templates/candidate-detail-template-body/candidate-detail-template-body";

export default function CandidateDetail({information}) {
  return (
    <>
    <CandidateDetailTemplateTrigger information={information}/>
    <CandidateDetailTemplateBody information={information} />
    </>
  )
}
