import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import EmailIcon from "../../../../common/domain/atoms/EmailIcon/EmailIcon";
import GeoAltIcon from "../../../../common/domain/atoms/GeoAltIcon/GeoAltIcon";
import BriefcaseIcon from "../../../../common/domain/atoms/BriefcaseIcon/BriefcaseIcon";
import PhoneIcon from "../../../../common/domain/atoms/PhoneIcon/PhoneIcon";
import PlusIcon from "../../../../common/domain/atoms/PlusIcon/PlusIcon";

import "./candidate-detail-organism-data.scss";

export default function CandidateDetailOrganismData ({information}) {
  return(
    <div className="h-100 vw-20 d-flex justify-content-center">
      <div className="w-90">
        <div className="text-center mt-2 mb-4">
          <div className="position-relative">
            <PlusIcon className="bi bi-plus color-blue position-absolute fs-5 tl-candidate-detail-data-0 start-0 top-0" />
            <img className="wr-candidate-detail-picture rounded-circle mt-5 mb-3" src="https://picsum.photos/id/1005/300/300" alt="Img not found" />
            <PlusIcon className="bi bi-plus color-blue position-absolute bottom-0 tl-candidate-detail-data-1 fs-candidate-card-0 fw-bold" />
          </div>
          <Paragraph contentTwo={information?.name} className="fs-candidate-detail-data-0 fw-bold m-0" />
          <Paragraph contentTwo={"profesión"} className="fs-candidate-detail-data-2 m-0"/>
          <Paragraph contentTwo={"edad"} className="fs-candidate-detail-data-2" />
        </div>
        <div className="border-top"></div>
        <div className="mt-4 mx-auto w-75">
          <div>
            <GeoAltIcon className="bi bi-geo-alt color-blue fs-candidate-detail-data-1 me-2" />
            <Paragraph className="m-0 d-inline fs-candidate-detail-data-1" contentTwo="Ciudad." />
          </div>
          <div>
            <BriefcaseIcon className="bi bi-briefcase color-blue fs-candidate-detail-data-1 me-2" />
            <Paragraph className="m-0 d-inline fs-candidate-detail-data-1" contentTwo="Modalidad" />
          </div>
          <div className="text-truncate">
            <EmailIcon className="bi bi-envelope color-blue fs-candidate-detail-data-1 me-2" />
            <Paragraph className="m-0 d-inline fs-candidate-detail-data-1" contentTwo={information["email"]} />
          </div>
          <div>
            <PhoneIcon className="bi bi-phone color-blue fs-candidate-detail-data-1 me-2" />
            <Paragraph className="m-0 d-inline fs-candidate-detail-data-1" contentTwo={`(+57) ${information["phone"]}`} />
          </div>
        </div>
        <div className="border-top mt-4"></div>
      </div>
    </div>
  )
}