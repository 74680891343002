import * as Redux from "react-redux";

import TestCard from "../../../../common/domain/molecule/test-card-one/test-card-one";

export default function SkillsSectionOrganismBodyTest() {
  const tests = Redux.useSelector(state => state["tests"]);
  return (
    <div className="mt-3 h-100 overflow-auto d-flex flex-column align-items-center">
      {
        tests?.tests?.map((t) => (
          <div className="my-2">
            <TestCard information={t} />
          </div>
        ))
      }
    </div>
  )
}