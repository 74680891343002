import * as React from "react";
import * as CandidatesServices from "../../services/CandidatesServices";
import * as Redux from "react-redux";

import Pagination from "../../../../common/domain/atoms/Pagination/Pagination";
import CandidateCard from "../../../../common/domain/molecule/candidate-card/candidate-card";

import "./candidates-organism-cardList.scss";

let data : any;
export default function CandidatesOrganismCardList({state}) {
  let information = Redux.useSelector(state => state['candidates']);
  information.candidates && (data = information.candidates);
  const [actualPage, setActualPage] = React.useState(1);
  let windowHeight = window.innerHeight * 73 / 100;
  let windowWidth = window.innerWidth;
  let cardHeight : number = 130;
  if(windowWidth >= 1700 && windowWidth < 1900) cardHeight = 192;
  else if (windowWidth >= 1900) cardHeight = 224;
  let startData : number = 1, itemsPerPage : number = Math.floor(windowHeight / cardHeight), pageAmount : number = 0, imgNumber : number = 0;
  let setData = {
    limit: itemsPerPage,
    offset: actualPage*itemsPerPage-itemsPerPage,
    withLinkSent: state.filter,
  }
  
  React.useEffect(() => {
    CandidatesServices.getCandidatesWithFilter(setData);
    CandidatesServices.getCandidatesAmount();
  }, [state.filter, actualPage]);

  if(information?.amount?.amount > 0){pageAmount = Math.ceil(information?.amount?.amount/itemsPerPage)};
  if(pageAmount === 1){pageAmount = 0}else if(Number.isNaN(pageAmount)){pageAmount = 0};

  // let dataToShow = data?.length > 0 && data?.slice(startData*actualPage*itemsPerPage - itemsPerPage, itemsPerPage*actualPage);
  if(data?.length > 0){
    data = data.map((user:any) => {
      let img : string;
      switch(imgNumber){
        case (0):
          img = "https://picsum.photos/id/1005/300/300";
          imgNumber++;
        break;
        case (1):
          img = "https://picsum.photos/id/177/300/300"
          imgNumber++;
        break;
        case (2):
          img = "https://picsum.photos/id/513/300/300"
          imgNumber++;
        break;
        case (3):
          img = "https://picsum.photos/id/602/300/300"
          imgNumber++;
        break;
        case (4):
          img = "https://picsum.photos/id/64/300/300"
          imgNumber = 0;
        break;
      }      
      return {
        ...user,
        img : img
      }
    })
  };
  return (
    <>
        <div className="ps-5 mt-3 d-flex flex-wrap mb-1">
      {
        state.searchCandidate !== undefined ?
          state.searchCandidate?.map((d : any) => (
            <div className="my-2">
              <CandidateCard information={d}/>
            </div>
          ))
          :
          data?.length > 0 && (typeof data !== "string") && data.map((d : any) => (
            <div className="my-2">
              <CandidateCard information={d}/>
            </div>
          ))
      }
    </div>
    <div className="ms-5">
      {
        pageAmount !== 0 && state.searchCandidate === undefined &&
        <Pagination pages_number={pageAmount} state={actualPage} setState={setActualPage} />
      }
    </div>
    </>
  );
};
