import * as Dictionary from "../../../../common/domain/constants/dictionary";

import CreateButton from "../../../../common/domain/molecule/create-button/create-button";
import TestEditModal from "../../../../tests/feature-test-edit/feature-test-edit";

import "./skills-section-organism-mainMenu-tests.scss";

export default function SkillsSectionOrganismMainMenuTests() {
  return (
    <div className="d-flex justify-content-between w-100">
        <TestEditModal />
        <CreateButton target={'#'} paragraph={Dictionary.createTestGroupString} className='btn border color-blue rounded-4 fs-btn-label fw-bold d-flex align-items-center justify-content-center' />
    </div>
  )
}
