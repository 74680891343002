import * as Dictionary from "../../../../common/domain/constants/dictionary";

import CreateButton from "../../../../common/domain/molecule/create-button/create-button";

export default function EditTemplateTrigger() {
  return (
    <div className="ms-4">
      <CreateButton paragraph={Dictionary.create + " " + Dictionary.sessionString.toLowerCase()} target={'sessionEdit'} />
    </div>
  )
}
