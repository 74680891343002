import * as Dictionary from "../../constants/dictionary";

import Icon from "../../atoms/Icon/Icon";

export default function CandidatesReportTableHead({setState, state}) {
  const icons = {
    done: "bi bi-check",
    noDone: "bi bi-x",
    all: "bi bi-dash",
  }
  
  function setStateFunction():void {
    switch (state) {
      case "done":
        setState("noDone");
      break;

      case "noDone":
        setState("all");
      break;

      case "all":
        setState("done");
      break;
    }

    state === "done" && setState("noDone");
    state === "noDone" && setState("all");
    state === "all" && setState("done");
  }
  
  const columns : string[] = [
    Dictionary.candidates_string,
    Dictionary.statusString,
    Dictionary.reportString
  ];
  
  return (
    <thead>
      <tr>
        {
          columns?.map((column) => (
            column === Dictionary.statusString
            ? <th scope="col" className="" >{column} <button onClick={setStateFunction} className="btn border m-0 py-1 ms-2 m-0 fs-8"> <Icon className={icons[state]} /></button> </th>
            : <th scope="col" >{column}</th>
          ))
        }
      </tr>
    </thead>
  )
}
