import * as Constants from "../../../common/redux/constants";
import { get, post, put, deleted } from "../../../common/domain/services/api";
import store from "../../../common/redux/store";
import moment from "moment";

export function getAllSessions(){
  post('sessions/filter', {
    "limit": 100,
    "offset": 0
  })
  .then(ress => store.dispatch({type: Constants.GET_ALL_SESSIONS, payload: ress.data}))
  .catch(error => {throw new Error(error)})
}
export function getAllEvaluators(){
    get('evaluators')
    .then(ress => store.dispatch({type: Constants.GET_ALL_EVALUATORS, payload: ress.data}))
    .catch(error => {throw new Error(error)})
}
export function createSession(data:any){
    post('sessions', data)
    .then(ress => store.dispatch({type: Constants.CREATE_NEW_SESSION, payload: ress.data}))
    .catch(error => {throw new Error(error)})
}
export function getSessionCandidates(sessionId: string){
  get('sessions/' + sessionId + '/candidates')
    .then(ress => store.dispatch({type: Constants.GET_SESSION_CANDIDATES, payload: ress.data}))
    .catch(error => {throw new Error(error)})
}
export function getAllDepartments(){
  get('headquarters')
    .then(ress => store.dispatch({type: Constants.GET_ALL_DEPARTMENTS, payload: ress.data}))
    .catch(error => {throw new Error(error)})
}
export function getMunicipalityByDepartment(departmentId: string){
  get('/municipality/departament/' + departmentId)
    .then(ress => store.dispatch({type: Constants.GET_MUNICIPALITIES_BY_DEPARTMENT, payload: ress.data}))
    .catch(error => {throw new Error(error)})
}
export function getHeadquarters(){
  get('headquarters')
    .then(ress => store.dispatch({type: Constants.GET_ALL_HEADQUARTERS, payload: ress.data}))
    .catch(error => {throw new Error(error)})
}