import * as React from "react";
import * as Redux from "react-redux";
import * as CandidatesServices from "../../services/CandidatesServices";

import TimeSetToCandidatesData from "../evaluations-organism-setData/evaluations-organism-setData";
import CandidatesReportTable from "../../../../common/domain/template/CandidatesReportTable/CandidatesReportTable";
import Pagination from "../../../../common/domain/atoms/Pagination/Pagination";

export default function EvaluationsTemplateBody() {
  let informationForTable = Redux.useSelector(state=>state['candidatesListToTable']);
  let report = Redux.useSelector(state=>state['report']);
  let itemsPerPage : number = 10
  const [actualPageTable, setActualPageTable] = React.useState(1);
  const [date, setDate] = React.useState([]);
  const [completeTest, setCompleteTest] = React.useState(true)
  let pageAmountForTable : number = 0;
  if(informationForTable?.candidates?.length > 0){
    pageAmountForTable = Math.ceil(informationForTable?.amount?.amount/itemsPerPage);
  };
  if(pageAmountForTable === 1){pageAmountForTable = 0}else if(isNaN(pageAmountForTable)){pageAmountForTable = 0};

  function getCandidatesFunction (initialDate = date[0], finalDate = date[1], actualPage= actualPageTable) : void {
    let data = {
      initialDate: initialDate ? initialDate : date[0],
      finalDate: finalDate ? finalDate : date[1],
      limit: 10,
      offset: (actualPage * 10) - 10,
      daysFromUnattendedLink: 0,
      completeTest: completeTest,
    };
    CandidatesServices.getExcelReport(data);
    CandidatesServices.getCandidatesFilterAndPagination(data);
  }

  function downloadFile(data, filename){
    const link = document.createElement('a');
    link.href = URL.createObjectURL(data);
    link.download = filename
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  }
  
  function downloadFileFunction(){
    downloadFile(report, "Reporte de evaluación.xlsx")
  }

  return (
    <div className="container mb-5">
      <TimeSetToCandidatesData method={getCandidatesFunction} setDate={setDate} setCompleteTest={setCompleteTest} completeTest={completeTest} date={date}/>
      <div className="text-end">
        <button onClick={downloadFileFunction}>Descargar excel</button>
      </div>
      <CandidatesReportTable data={informationForTable?.candidates}/>
      <div>
        {
          pageAmountForTable !== 0 &&
          <Pagination pages_number={pageAmountForTable} state={actualPageTable} setState={setActualPageTable} method={getCandidatesFunction}/>
        }
      </div>
    </div>
  )
}