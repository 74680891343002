import * as React from "react";
import * as Redux from "react-redux";
import * as SessionServices from "../../services/SessionServices";
import * as Dictionary from "../../../../common/domain/constants/dictionary";

import TableNotification from "../../../../common/domain/molecule/table-notification/table-notification";

export default function SessionsOrganismTable() {
  React.useEffect(() => {
    SessionServices.getAllSessions();
  }, [])

  let sessions = Redux.useSelector(state => state['sessions']);
  let columns = [`${Dictionary.sessionsString} No.`];
  let data : [];

  function createColumnsAndData(){
    for(let key in sessions[0]) {
      columns.push(key);
    }
    columns.push('actions')
    let count : number = 1;
    data = sessions.map((session : any) => {
      var href = "/sesiones/" + session.uuid
      return {
        ...session,
        count: count++,
        actions: <a className='btn btn-sm btn-outline-secondary fs-7' href={href} >Detalles</a>
      };
    });
  }

  return (
    <div className="p-4">
      <>
      {createColumnsAndData()}
      <TableNotification columns={columns} data={data} />
      </>
    </div>
  )
}
