import * as Dictionary from "../../constants/dictionary";

import ProgressCircular from "../../atoms/ProgressCircular/ProgressCircular";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import Button from "../../atoms/Button/Button";
import TestsGroupCardTwo from "../tests-group-card-two/tests-group-card-two";

import "./CandidateDetailSkills.scss";

export default function CandidateDetailSkills({information}) {
  information.testsGroups = [
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]

  return (
    <div className="border p-3 rounded-bottom rounded-4">
      <div className="d-flex">
        <div className="w-20 d-flex flex-column align-items-center">
          <div className="d-flex flex-column align-items-center my-auto">
            <Paragraph contentTwo={information["name"]} className="m-0 fw-bold fs-candidate-detail-skills-1 color-blue mb-2" />
            <ProgressCircular value={information?.value} icon={information.icon} />
            <Paragraph contentTwo={`${information?.value}/100`} className="fw-bold fs-candidate-detail-skills-1 mt-2"/>
            <Button text={Dictionary.assignTestsString} className="btn border rounded-4 shadow py-1 px-4 fs-candidate-detail-skills-2 fw-bold bg-color-blue text-white"/>
          </div>
          {/* <div className="w-100 my-1">
            <Paragraph contentTwo={`${information?.value}/100`} className="fw-bold fs-candidate-detail-skills-0 text-center"/>
              {
                information?.data?.map((d) => (
                  <div key={d.name}>
                    <Paragraph contentTwo={d.name} className="m-0 fs-candidate-detail-skills-0 "/>
                    <Paragraph contentTwo={d.value} className="fw-bold fs-candidate-detail-skills-0"/>
                  </div>
                ))
              }
          </div> */}
        </div>
        <div className="w-80 overflow-auto">
          <div className="ms-5 d-flex">
            {
              information.testsGroups?.length > 0 &&
              information.testsGroups?.map((testsGroup) => (
                <div className="me-3">
                  <TestsGroupCardTwo  information={testsGroup} />
                </div>
              ))
            }
          </div>             
        </div>
      </div>
    </div>
  )
}
