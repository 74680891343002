import * as Constants from "../../../common/redux/constants";
import { get, post, put, deleted } from "../../../common/domain/services/api";

import store from "../../../common/redux/store";

export function getCustomers () {
  get('business')
  .then((res) => {store.dispatch({type: Constants.GET_ALL_BUSINESS, payload: res.data})})
  .catch(error => {throw new Error(error)})
};


export function createNewCustomer (data: any) {
  post('business', data)
  .then(res => store.dispatch({type: Constants.CREATE_NEW_CUSTOMER, paryload: res.data}))
  .catch(error => {throw new Error(error)});
};