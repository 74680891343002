import * as Dictionary from "../../constants/dictionary";

import Icon from "../../atoms/Icon/Icon";
import Paragraph from "../../atoms/Paragraph/Paragraph";

export default function TestsGroupCard({information = {}, testGroup = ""}) {
  information = {
    uuid: information["uuid"],
    name: information["name"],
    created_at : information["created_at"],
    updated_at : information["updated_at"],
    tests : [
      {
        uuid: "nombredeprueba1",
        name: "Nombre de Prueba"
      },
      {
        uuid: "nombredeprueba2",
        name: "Nombre de Prueba"
      },
      {
        uuid: "nombredeprueba3",
        name: "Nombre de Prueba"
      },
      {
        uuid: "nombredeprueba4",
        name: "Nombre de Prueba"
      },
    ]
  };
  const icons = [
    "moon-fill",
    "emoji-smile-fill",
    "emoji-laughing-fill",
    "magic",
  ];
  return (
    <div className={information["uuid"] === testGroup ? "w-auto border border-1 border-dark rounded-4 shadow bg-color-light-blue pointer" : "w-auto border border-1 border-dark rounded-4 shadow"}>
      <div className="d-flex align-items-center m-3">
        <div className="w-10 d-flex justify-content-center">
          <div className="w-100">
            {
              icons.map((icon) => (
              <div className="m-0 d-inline">
                <Icon icon={icon} className={'mx-1 fs-7'} />
              </div>
              ))
            }
          </div>
        </div>
        <div className="w-35 ms-3">
          <Paragraph contentTwo={information["name"]} className="mb-3 fw-bold fs-7"/>
          {
            information["updated_at"] === null 
            ?
            <>
            <Paragraph contentTwo={Dictionary.terms["updated_at"]} className="m-0 fs-8"/>
            <Paragraph contentTwo={information["created_at"]} className="m-0 text-secondary fs-9"/>
            </>
            :
            <>
            <Paragraph contentTwo={Dictionary.terms["updated_at"]} className="m-0 fs-8"/>
            <Paragraph contentTwo={information["updated_at"]} className="m-0 text-secondary fs-9"/>
            </>
          }
        </div>
        <div className="border-start py-4 px-0 m-3"></div>
        <div className="w-35">
          <div className="d-flex justify-content-between">
            <Paragraph contentTwo={Dictionary.testsGroupString}  className="fs-7 fw-bold"/>
            <Paragraph contentTwo={information["tests"]?.length} capital={false} className="fs-7 fw-bold"/>
          </div>
          <div className="row">
            {
              information["tests"]?.map((test) => (
                <div className="col-6">
                  <Paragraph contentTwo={test.name} className="m-0 text-secondary fs-9 text-truncate"/>
                </div>
                
              ))
            }
          </div>
        </div>
      </div>
    </div>
  )
}
