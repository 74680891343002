import * as React from "react";
import * as Redux from "react-redux";
import * as UserServices from "../../services/UserServices";

import UserEditOrganismData from "../../organisms/user-edit-organism-data/user-edit-organism-data";
import UserEditOrganismProfiles from "../../organisms/user-edit-organism-profiles/user-edit-organism-profiles";
import UserEditOrganismHeader from "../../organisms/user-edit-organism-header/user-edit-organism-header";
import UserEditOrganismFooter from "../../organisms/user-edit-organism-footer/user-edit-organism-footer";

import "./user-edit-template-form.scss";

export default function UserEditTemplateForm() {
  const [selected, setSelected] = React.useState("")
  const createFeedback = Redux.useSelector(state => state['createFeedback']);
  function onSubmit (e : any) {
    if(selected !== ""){
      let data = {
        profileId: selected,
        password: "",
        confirmEmail: "",
        trash: false,
        enable: false,
        documentType : "",
      };
      for(let i = 1; i < 6; i++){
        data[e.target[i].id] = e.target[i].value
      } 
      UserServices.createNewUser(data);
      // let message : boolean = false
      // while(message === false){
      //   if(createFeedback !== ''){
      //     alert(createFeedback);
      //     message = true;
      //   }
      // }
    } else {
      e.preventDefault();
    }
  }  
  return (
    <form onSubmit={(e : any) => onSubmit(e)} className="modal-dialog modal-fullscreen tl-user-edit">
      <div className="modal-content vh-user-edit vw-user-edit px-5 rounded-4">
        <UserEditOrganismHeader/>
        <div className="modal-body h-75">
          <UserEditOrganismData />
          <UserEditOrganismProfiles selected={selected} setSelected={setSelected} />
        </div>
        <UserEditOrganismFooter/>
      </div>
    </form>
  )
}
