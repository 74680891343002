import * as React from "react";
import * as Redux from "react-redux";
import * as Crud from "../../../../common/domain/organism/crud-CrudGenerator/crud-CrudGenerator";

import SkillsEditModalBodyTestsHeader from "../../../../common/domain/molecule/SkillsEditModalBodyTestsHeader/SkillsEditModalBodyTestsHeader";
import SkillsEditModalBodyTestsMainMenu from "../../../../common/domain/molecule/SkillsEditModalBodyTestsMainMenu/SkillsEditModalBodyTestsMainMenu";
import SkillsEditModalBodyTestsBody from "../../../../common/domain/molecule/SkillsEditModalBodyTestsBody/SkillsEditModalBodyTestsBody";

import "./skills-edit-organism-tests.scss";

export default function SkillsEditOrganismTests({tests, setTests}) {
  const data = Redux.useSelector(state => state["tests"])
  React.useEffect(() => {
    Crud.getAllTests();
  },[])

  //ESTADOS-------------------------------------------------------------------------------------------
  const states = {
    searchTests: undefined,
  };
  const [state, setState] = React.useState(states);
  //--------------------------------------------------------------------------------------------------
  
  return (
    <div className="w-70 h-100">
      <SkillsEditModalBodyTestsHeader />
      <SkillsEditModalBodyTestsMainMenu state={state} setState={setState}  data={data?.data}/>
      <SkillsEditModalBodyTestsBody data={data?.data} dataToShow={data?.data} state={state} tests={tests} setTests={setTests} />
    </div>
  )
}
