import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";

import ProfileEditModal from "../../../feature-profile-edit/feature-profiel-edit";
import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import FilterSelector from "../../../../common/domain/molecule/filter-selector/filter-selector";
import OrderButton from "../../../../common/domain/molecule/order-button/order-button";

import "./profiles-organism-mainmenu.scss";

export default function ProfilesOrganismMainMenu({state, setState}) {
  const profiles = Redux.useSelector(state => state["profilesTable"]);
  //BUSCADOR----------------------------------------------------------------------------------------
  const searchProfilesFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchProfiles: undefined});
    } else {
      let newProfiles = profiles?.data.filter((profile : any) => {
        profile.name = profile.name.toLowerCase();
        return profile.name.includes(e.target.value.toLowerCase());
      });
      setState({...state, searchProfiles: newProfiles}); 
    };
  };
  //------------------------------------------------------------------------------------------------
 
  //ORDENAR-----------------------------------------------------------------------------------------
  const orderFunction = () => {
    if(state.order) {
      profiles?.data?.sort((a : any, b : any) => {
        if (a.name > b.name) {return -1}
        if (a.name < b.name) {return 1}
        return 0;
      })
      setState({...state, order: false});
    } else {
      profiles?.data?.sort((a : any, b : any) => {
        if (a.name > b.name) {return 1}
        if (a.name < b.name) {return -1}
        return 0;
      })
      setState({...state, order: true});
    };
  };
  //------------------------------------------------------------------------------------------------
  
  //FILTRADO----------------------------------------------------------------------------------------
  const optionsSelectFilter = [];
  //------------------------------------------------------------------------------------------------
  
  return (
    <div className="ps-5 d-flex w-100">
      <div className="me-3">
        <ProfileEditModal />
      </div>
      <div className="border me-3"></div>
      <div className="me-3">
        <SearchBar onKeyUp={e => searchProfilesFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchbarProfiles"} name={"searchbarProfiles"}/>
      </div>
      <div className="me-3">
        <FilterSelector onChange={()=>{}} options={optionsSelectFilter} firstOption={Dictionary.filterString} />
      </div>
      <OrderButton onClick={(e) => orderFunction()} text={Dictionary.orderString} />
    </div>
  )
}
