import Paragraph from "../../atoms/Paragraph/Paragraph";
import "./template-header-two.scss";
export default function TemplateHeaderTwo({title, subtitle}) {
  return (
    <div className="mb-2 m-0 d-flex align-items-center ms-2">
      <Paragraph contentTwo={title} className="fs-header-title-two-0" />
      <Paragraph contentTwo={subtitle} className="fs-header-title-two-1 border-start ps-4"/>
    </div>
  )
}
