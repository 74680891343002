import * as Redux from "react-redux";
import * as CandidatesServices from "../domain/services/CandidatesServices";

import CandidateEditModalTemplateHeader from "../domain/templates/candidate-edit-modal-template-header/candidate-edit-modal-template-header";
import CandidateEditModalTemplateBody from "../domain/templates/candidate-edit-modal-template-body/candidate-edit-modal-template-body";
import CandidateEditModalTemplateFooter from "../domain/templates/candidate-edit-modal-template-footer/candidate-edit-modal-template-footer";
import CandidateEditTemplateTrigger from "../domain/templates/candidate-edit-template-trigger/candidate-edit-template-trigger";
import CandidateEditTemplateForm from "../domain/templates/candidate-edit-template-form/candidate-edit-template-form";
export default function CandidateEditModal() {
  const profiles = Redux.useSelector(state => state["profilesTable"]);
  const filterProfiles = profiles?.data?.filter((p) => {
    return p.name === "candidate";
  });
  function submit (e : any) {
    e.preventDefault();
    let data = {
      password: "",
      confirmEmail: "", 
      profileId: filterProfiles[0].uuid,
      trash: false,
      enable: false,
    };
    for(let i : number = 1; i < 7; i++){
      data[e.target[i].name] = e.target[i].value
    };
    CandidatesServices.createNewCandidate(data);
    alert("Enviado");
    // window.location.reload()
  };  
  return (
    <>
    <CandidateEditTemplateTrigger />
    <div className="modal fade" id="candidateEdit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> 
      <CandidateEditTemplateForm />
    </div>
    {/* <form onSubmit={e => submit(e)} className="modal fade" id="candidateEdit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <div className="modal-content rounded-4 vh-60">
          <CandidateEditModalTemplateHeader/>
          <CandidateEditModalTemplateBody/>
          <CandidateEditModalTemplateFooter/>
        </div>
      </div>
    </form> */}
    </>
  )
}
