import * as Router from "react-router"
import * as Redux from "react-redux"
import * as React from "react"
import * as Crud from "../../organism/crud-CrudGenerator/crud-CrudGenerator";
import * as Dictionary from "../../constants/dictionary";

import { SELECTION_OPTIONS as options } from "../../constants/constants";
import CrudGenerator from "../../organism/CrudGenerator/CrudGenerator";
import MenuTemplate from "../../template/menu-template/menu-template";
import SelectInput from "../../atoms/SelectInput/SelectInput";

export default function SelectorCrudGenerator () {
  const params = Router.useParams();
  let json = Redux.useSelector(state => state["table"]);
  React.useEffect(()=>{
    selectorHandle();
  },[params.section]);
  if(params.section === "users" || params.section === "user_permissions"){
    json = {
      title: params.section,
      data: json
    } ;
  };
  
  function selectorHandle(){
    if(params.section === 'selector'){
      
    }else{
      Crud.getTableGenerator(params.section);
    }
  }

  function redirectionHandle (e:any) {
    window.location.replace(`/dashboard/tablas/${e.target.value}`)
  }
  return (
    <>
    <MenuTemplate section={Dictionary.tables}/>
    <div className="d-flex flex-column align-items-end w-100">
      <div className='vh-15 w-56' ></div>
        <div className="w-86 position-relative">
          <div className="w-25">
            <SelectInput onChange={(e)=>redirectionHandle(e)} options={options.tableSelector} text={Dictionary.selectTable}  />
          </div>
          {
            json["data"].length > 0 &&
            <CrudGenerator json={json} path={params.section} />
          }
        </div>
    </div>
    </>
  )
}