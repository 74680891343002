import * as Constants from "../../../common/redux/constants";
import { get, post } from "../../../common/domain/services/api";
import store from "../../../common/redux/store";

export function getAllBusinessChart(){
    post('/pie_chart/all_business', {})
    .then(ress => store.dispatch({type: Constants.GET_DASHBOARD_ALL_BUSINESS, payload: ress.data}))
    .catch(error => {throw new Error(error)})
}

export function getNextProcessBusiness(){
  post('/next_process_concept', {})
  .then(ress => store.dispatch({type: Constants.GET_DASHBOARD_NEXT_PROCESS_BUSINESS, payload: ress.data}))
  .catch(error => {throw new Error(error)})
}

export function getNextProcessBusinessChart(){
  post('/bar/next_process_concept', {})
  .then(ress => store.dispatch({type: Constants.GET_DASHBOARD_NEXT_PROCESS_BUSINESS_CHART, payload: ress.data}))
  .catch(error => {throw new Error(error)})
}

export function getNextProcessBusinessHeadquartersChart(){
  post('/bar/next_process_concept/headquarters', {})
  .then(ress => store.dispatch({type: Constants.GET_DASHBOARD_NEXT_PROCESS_BUSINESS_HEADQUARTERS_CHART, payload: ress.data}))
  .catch(error => {throw new Error(error)})
}

export function getNormalDistributionChart(){
  post('/normal_distribution', {})
  .then(ress => store.dispatch({type: Constants.GET_DASHBOARD_NORMAL_DISTRIBUTION_CHART, payload: ress.data}))
  .catch(error => {throw new Error(error)})
}