import {post, get, postBlob} from "../../../common/domain/services/api";
import store from "../../../common/redux/store";
import * as Constants from "../../../common/redux/constants";

export function getCandidatesAmount () {
  get('user/candidate/length')
  .then(res => store.dispatch({type: Constants.GET_CANDIDATES_AMOUNT, payload: res.data}))
  .catch(error => {throw new Error(error)})
};

export function getUserTestResponse (attemptUuid: string) {
  get(`valoration_development/${attemptUuid}`)
  .then(res => store.dispatch({type: Constants.GET_USER_TEST_RESPONSE, payload: res.data}))
  .catch(error => {throw new Error ( error )})
};

export function getExcelReport(data : any){
  postBlob('user/candidate/excel', data)
  .then(res => store.dispatch({type: Constants.GET_EXCEL_REPORT, payload: res.data }))
  .catch(error => {throw new Error(error)})
}

export function getCandidatesWithFilter (data : any) {
  post('user/candidate', data)
  .then( res => store.dispatch({type: Constants.GET_CANDIDATES_WITH_FILTER, payload: res.data}))
 .catch( error => {throw new Error(error)}) 
};

export function getCandidatesFilterAndPagination (data: any){
  post('user/candidate/filter', data)
  .then(res => {store.dispatch({type: Constants.GET_CANDIDATES_FILTER_AND_PAGINATION, payload: res.data})})
  .catch(error => {throw new Error(error)})
};

export function getTabTests ( path:string ){
  get(`test_attempt/${path}`)
  .then(res => store.dispatch({type: Constants.GET_TAB_TABLES, payload: res.data}))
  .catch(error => {throw new Error(error)})
};

export function createNewCandidate (data: any) {
  post('user', data)
  .then(res => store.dispatch({type: Constants.CREATE_NEW_CANDIDATE, paryload: res.data}))
  .catch(error => {throw new Error(error)});
};

export function createResponses (path : string, data : any) {
  post(path, data)
  .then(res => store.dispatch({type: Constants.CREATE_RESPONSES, payload: res.data}))
  .catch(error => {throw new Error(error)})
};