import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as React from "react";
import * as DocumentTypeServices from "../../../../document-type/domain/services/documenteTypeServices";
import * as Redux from "react-redux";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import FormText from "../../../../common/domain/atoms/FormText/FormText";
import FileInput from "../../../../common/domain/atoms/FileInput/FileInput";
import SelectInput from "../../../../common/domain/atoms/SelectInput/SelectInput";

import "./user-edit-organism-data.scss";

export default function UserEditOrganismData() {
  React.useEffect(() => {
    DocumentTypeServices.getAllDocumentType();
  },[])

  // const optionsIdentificationType = Redux.useSelector((state)=>state['documentTypes']);
  
  const optionsIdentificationType = [
    {
      uuid: "Cédula de ciudadanía",
      name: "Cédula de ciudadanía"
    },
    {
      uuid: "Cédula de extranjeria",
      name: "Cédula de extranjeria",
    },
    {
      uuid: "Permiso especial de permanencia",
      name: "Permiso especial de permanencia",
    },
    {
      uuid: 'Tarjeta de identidad',
      name: 'Tarjeta de identidad'
    }
  ]
  return (
    <div className="user-edit-data-size">
      <Paragraph contentTwo={Dictionary.generalDataString + " :"} className="ms-2 fs-general-title mb-2" />
      <div className="border-top d-flex w-100 h-80">
        <div className="w-30 d-flex flex-column aling-items-center justify-content-end mt-4">
          <img className="rounded-4 wr-previewImg mb-2 mx-previewImg" src="https://picsum.photos/id/823/300/300" alt="Img not found" />
          <FileInput label={Dictionary.updataPhotoString} id='updata-user-picture' accept='image/*' />
        </div>
        <div className="w-70 d-flex justify-content-center mt-5">
          <div className="w-33">
            <FormText id={"name"} name={"name"} label={Dictionary.nameString} placeholder={Dictionary.nameString} classDiv='d-flex flex-column mb-2'  />
            <SelectInput text={Dictionary.typeString + " " + Dictionary.ofString + " " +  Dictionary.identificationString} name="documentType" options={optionsIdentificationType} firstOption={Dictionary.typeString + " " + Dictionary.ofString + " " + Dictionary.identificationString} classDiv='d-flex flex-column mb-2'/>
            <FormText id={"email"} name={"email"} label={Dictionary.emailString} placeholder={Dictionary.emailString} />
          </div>
          <div className="w-33">
            <FormText id={"lastName"} name={"lastName"} label={Dictionary.lastnameString} placeholder={Dictionary.lastnameString} classDiv='d-flex flex-column mb-2' />
            <FormText id={"documentNumber"} name={"documentNumber"} label={Dictionary.identificationString} placeholder={Dictionary.identificationString} classDiv='d-flex flex-column mb-2' />
            <FormText id={"confirmEmail"} name={"confirmEmail"} label={Dictionary.confirmEmailString} placeholder={Dictionary.confirmEmailString}  />
          </div>
          <div className="w-33">
            <FormText id={"city"} name={"city"} label={Dictionary.cityString} placeholder={Dictionary.cityString}/>
            <FormText id={"phone"} name={"phone"} label={Dictionary.phoneString} placeholder={Dictionary.phoneString}  />
          </div>
        </div>
      </div>
    </div>
  )
}
