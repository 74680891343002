import * as Router from "react-router"

import CreateTest from "../CreateTest/CreateTest"
import CreateTestQuestions from "../CreateTestQuestions/CreateTestQuestions"
import CreateSkills from "../CreateSkills/CreateSkills"
import CreateTestQuestionOptions from "../CreateTestQuestionOptions/CreateTestQuestionOptions"
import CreatePermissions from "../CreatePermissions/CreatePermissions"
import CreateProfiles from "../CreateProfiles/CreateProfiles"
import MenuTemplate from "../../template/menu-template/menu-template"
import CreateOrganismForm from "../../../../document-type/domain/organisms/create-organism-form/create-organism-form"
import CreateDepartamentOrganismForm from "../../../../departments/domain/organisms/create-departament-organism-form/create-department-organism-form";

export default function CreateItem () {
  const params = Router.useParams()
  const creatorSelector = () => {
    switch (params.section){
      case "skills":
        return <CreateSkills path={params.section} />

      case "user_permissions":
        return <CreatePermissions path={params.section} />

      case "profile":
        return <CreateProfiles path={params.section} />

      case "tests":
        if(params.id === "1"){
          return <CreateTest path={params.section} />
        } else {
          return <CreateTest path={params.section} defaultValue={params.id}/>
        }

      case "test_questions":
        if(params.id === "1"){
          return <CreateTestQuestions path={params.section} />
        } else {
          return <CreateTestQuestions path={params.section} defaultValue={params.id} />
        }

      case "test_question_options":
        if(params.id === "1"){  
          return <CreateTestQuestionOptions path={params.section} />
        } else {
          return <CreateTestQuestionOptions path={params.section} defaultValue={params.id}/>
        }
      
      case 'document_type':
        return <CreateOrganismForm path={params.section} />
      
      case 'departament':
        return <CreateDepartamentOrganismForm path={params.section} />
    }
  }
  return (
    <>
    <div>
      <MenuTemplate/>
      {creatorSelector()}
    </div>
    </>
  )
}