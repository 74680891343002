import "../../../progress-circle.css"

export default function ProgressCircularTwo({value}) {
  return (
    <div className={`progress-circle2 progress-${Math.round(value)} m-0`}>
      <span className="fs-8">
        {value + "%"}
      </span>
    </div>
  );
};