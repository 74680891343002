import * as Dictionary from "../../constants/dictionary";
import * as AuthServices from "../../services/AuthServices";
import * as Entities from "../../entities/user";

import { passwordExpression } from "../../constants/regularExpressions";

import ErrorMsg from "../../../../common/domain/atoms/ErrorMsg/ErrorMsg";
import InputForm from "../../../../common/domain/atoms/InputForm/InputForm";
import Button from "../../../../common/domain/atoms/Button/Button";

export default function AuthOrganismFormChangePassword({setComplete}) {
  let confirmPassWord : boolean = null;
  const onSubmit = (e: any): void => {
    e.preventDefault();
    e.target[1].value === e.target[2].value ? confirmPassWord = true : confirmPassWord = false;
    let data : Entities.ChangePassword = {
      actualPassword: e.target[0].value,
      newPassword: e.target[1].value,
    }
    if(confirmPassWord){
      AuthServices.changePassword(data);
      confirmPassWord = null
      setComplete(true)
    }
  };
  return (
    <>
    <h3 className="text-center">{Dictionary.changePassword}</h3>
      <form onSubmit={(e)=>onSubmit(e)}>
        <div className="col-12">
          <InputForm  classNameI={"form-control mb-4 shadow-sm"} classNameL={"form-label"} text={Dictionary.actualPassword} type={Dictionary.typePassword} infoMsg={Dictionary.passwordError} expression={passwordExpression} required={true} />
        </div>
        <div className="col-12 col-lg-12">
          <InputForm classNameI={"form-control mb-4 shadow-sm"} classNameL={"form-label"} text={Dictionary.newPassword} type={Dictionary.typePassword} expression={passwordExpression} infoMsg={Dictionary.passwordError} required={true}/>
        </div>
        <div className="col-12 col-lg-12">
          <InputForm classNameI={"form-control mb-4 shadow-sm"} classNameL={"form-label"} text={Dictionary.confirmNewPassword} expression={null} type={Dictionary.typePassword} required={true}/>
          {confirmPassWord === false && <ErrorMsg errorMsg={Dictionary.confirmPasswordError} />}
        </div>
        <div className="text-center">
          <Button className={"btn btn-primary px-md-5 mt-4 shadow-sm"} text={Dictionary.recoveryText} />
        </div>
      </form>
    </>
  )
}
