import * as Redux from "react-redux";
import * as Crud from "../../../../common/domain/organism/crud-CrudGenerator/crud-CrudGenerator";
import * as ReactRouter from "react-router";
import * as React from "react";
import { myStorage } from "../../../../common/redux/constants";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import UserIcon from "../../../../common/domain/atoms/UserIcon/UserIcon";
import Image from "../../../../common/domain/atoms/Image/Image";

import "./tests-candidate-organism-header.scss";

const logoBlanco = require("../../../../assets/logoYLetrasBlanco.png");
const logoPartner1 = require("../../../../assets/partner_1.jpg");
const logoPartner2 = require("../../../../assets/partner_2.jpg");

export default function TestCandidateOrganismHeader() {
  const params = ReactRouter.useParams()
  React.useEffect(()=>{
    Crud.getTabTests(`attempt/${params.attemptId}`)
  }, [params.attemptId]);
  const data = Redux.useSelector(state => state["tabTests"])
  const [sec, setSec] = React.useState(parseInt(myStorage.getItem("sec")));
  const [min, setMin] = React.useState(parseInt(myStorage.getItem("min")));
  const [hrs, setHrs] = React.useState(parseInt(myStorage.getItem("hrs")));

  if(Number.isNaN(sec)) myStorage.setItem("sec", `00`);
  if(Number.isNaN(min)) myStorage.setItem("min", `00`);
  if(Number.isNaN(hrs)) myStorage.setItem("hrs", `01`);
  
  const tick = () => {
    if(min >= 0){
      myStorage.setItem("sec", (sec - 1).toString());  
      setSec(sec - 1)
      if(parseInt(myStorage.getItem("sec")) < 0){
        myStorage.setItem("sec", `59`);
        myStorage.setItem("min", (min - 1).toString());
        setSec(59);
        setMin(min-1)
        if(parseInt(myStorage.getItem("min")) < 0){
          myStorage.setItem("min", "59");
          myStorage.setItem("hrs", (hrs-1).toString());
          setHrs(hrs - 1);
        };
      };
    };
  }
  const timer = () => {
    setTimeout(tick, 1000);
  };

  if(myStorage.getItem("activeTimer") === "true") {
    timer();
  };
  return (
    <div className="bg-primary w-100 vh-50">
      <div className="w-100 d-flex justify-content-between">
        <div>
          <Image className={"img-testsUser-logo m-3 mb-1"} src={logoBlanco} alt="img not found" />
        </div>
        <div className="d-flex flex-column align-items-end m-3">
          <div className="text-light fs-5 d-flex align-items-center m-0 d-flex flex-column">
            <div className="d-flex align-items-center">
              <Paragraph contentTwo={data?.user?.name.toUpperCase()} className="m-0 fs-6" />
              <div className="fs-2 ms-2">
                <UserIcon />
              </div>
            </div>
            <div>
              {hrs} : {min} : {sec}
            </div>
          </div>
        </div>
      </div>
      <div className="p-0">
        <Paragraph contentTwo={"Área de tecnología"} className="text-center fs-2 text-light m-0"/>
      </div>
    </div>
  )
}
