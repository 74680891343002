import * as React from "react"
import * as RouterDom from "react-router-dom"
import * as Redux from "react-redux"
import * as Dictionary from "../../constants/dictionary"
import * as Crud from "../../organism/crud-CrudGenerator/crud-CrudGenerator"

import FormsGenerator from "../../organism/FormsGenerator/FormsGenerator"

export default function Updata() {
  const detail = Redux.useSelector(state =>  state["detail_item_table"])
  let optionsSelectorTypes = Redux.useSelector(state => state["testQuestionTypes"])
  const params = RouterDom.useParams()
  React.useEffect(()=>{
    Crud.getTestQuestionTypes()
  },[])
  React.useEffect(()=>{
      Crud.getItemDetail(`${params.section}`, params.id)
  },[params.section])
  optionsSelectorTypes = optionsSelectorTypes.map((option) => {
    if(Dictionary.terms.hasOwnProperty(option.name)) {
      return {
        name: Dictionary.terms[option.name],
        uuid: option.uuid
      }
    } else {
      return {
        name: option.name,
        uuid: option.uuid
      }
    }
  })

  let json : any = {}

  if(Object.keys(detail).length > 0){
    switch (params.section){
      case "skills":
        json = {
          title: Dictionary.skillUpdateString,
          data: [
            {
              "tests_questions_type.name": "InputFormText",
              name: "newName",
              text: Dictionary.newNameString,
              infoMsg: Dictionary.completeFormString,
              defaultValue: detail?.data["name"],
              strict: true,
            }
          ]
        }
      break;

      case "profiles":
        json = {
          title: Dictionary.profilesUpdateString,
          data: [
            {
              "tests_questions_type.name": "InputFormText",
              name: "newName",
              test: Dictionary.newNameString,
              infoMsg: Dictionary.completeFormString,
              defaultValue : detail?.data["name"],
              strict: true
            }
          ]
        }
      break;

      case "user_permissions":
        json = {
          title: Dictionary.permissionsUpdateString,
          data: [
            {
              "tests_questions_type.name": "InputFormText",
              name: "newName",
              test: Dictionary.newNameString,
              infoMsg: Dictionary.completeFormString,
              defaultValue : detail?.data["name"],
              strict: true
            }
          ]
        }
      break;
  
      case "tests":
        json = {
          title: Dictionary.testUpdateString,
          data: [
            {
              "tests_questions_type.name": "InputFormText",
              name: "newName",
              text: Dictionary.newNameString,
              infoMsg: Dictionary.completeFormString,
              defaultValue: detail?.data["name"],
              strict: true
            }
          ]
        }
      break;
  
      case "test_questions":
        json = {
          title: Dictionary.testQuestionUpdateString,
          data: [
            {
              "tests_questions_type.name": "InputFormText",
              name: "newName",
              text: Dictionary.newNameString,
              infoMsg: Dictionary.completeFormString,
              defaultValue: detail?.data["name"],
              strict: true
            },
            {
              "tests_questions_type.name": "InputFormText",
              name: "newText",
              text: Dictionary.descriptionString,
              infoMsg: Dictionary.completeFormString,
              defaultValue: detail?.data["text"] || '',
              strict: true
            },
            {
              "tests_questions_type.name": "SelectInput",
              text: Dictionary.selectQuestionTypeString,
              options: optionsSelectorTypes,
              name: "newTestQuestionTypeId",
              strict: true
            }
          ]
        }
      break;
  
      case "test_question_options":
        json = {
          title: Dictionary.testQuestionOptionUpdateString,
          data: [
            {
              "tests_questions_type.name": "InputFormText",
              name: "newName",
              text: Dictionary.newNameString,
              infoMsg: Dictionary.completeFormString,
              defaultValue: detail?.data["name"],
              strict: true
            }
          ]
        }
    }
  };
  
  let path = `${params.section}/${params.id}`
  return (
    <>
    {
      detail &&
      <div className="">
        <FormsGenerator json={json} path={path} method={Crud.updateItem} />
      </div>
    }
    </>
  )
}
