import * as Redux from "react-redux";
import * as Dictionary from "../../../domain/constants/dictionary";

import { myStorage } from "../../../../common/redux/constants";

export default function AuthOrganismErrorMsgLogin() {
  const token : boolean = Redux.useSelector((state) => state["token"]);
  setTimeout(() => {
    if ( token ) {
      window.location.replace("/dashboard");
    } else if(myStorage.getItem("token")) {
      myStorage.clear();
      window.location.reload()
    }
  }, 500)
  return (
    <>
    {!token && token !== null && (
      <div className="alert alert-danger">{Dictionary.errorUserPassword}</div>
    )}  
    </>
  )
}
