import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";

import TemplateHeader from "../../../../common/domain/template/template-header/template-header";
import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";

import "./skills-section-template-header.scss";
export default function SkillsSectionTemplateHeader() {
  const data = Redux.useSelector(state => state["skills"]);

  return (
    <>
    <div className="d-flex w-100 justify-content-between mb-4">
      <TemplateHeader title={Dictionary.skills_string} subTitle={Dictionary.skillsActivedString + " :"} />
      <div className="d-flex flex-column border rounded-4 justify-content-center align-items-center me-5 px-3 py-2">
        <Paragraph contentTwo={data?.data?.length} className="m-0 px-2 fs-skills-section-template-header-0 color-blue" capital={false}/>
        <Paragraph contentTwo={Dictionary.skills_string} className="m-0 fs-skills-section-template-header-1" />
        <Paragraph contentTwo={Dictionary.activeString.toLowerCase()} className="m-0 fs-skills-section-template-header-1" capital={false} />
      </div>
    </div>
    </>
  )
}
