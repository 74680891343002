import * as React from "react";
import * as Dictionary from "../../constants/dictionary";

import moment from "moment";

export default function TimeSetToCandidatesDataBSectionInputs({method, setState}) {
  let [initialDate, setInitialDate] = React.useState(null);
  let [finalDate, setFinalDate] = React.useState(null);
  function onChangeInitialDate(e : any):void{
    setInitialDate(e.target.value);
  };

  function onChangeFinalDate(e : any) : void {
    setFinalDate(e.target.value);
    setState([initialDate, e.target.value]);
  };  
  return (
    <div className="d-flex flex-column ">
      <div className="fs-7 my-2">
        <label htmlFor="initialDate" className="">{Dictionary.initialDateString}</label>
        <input className="form-control fs-7" type="date" id="initialDate" name="initialDate" onChange={e=>onChangeInitialDate(e)} max={moment().format('YYYY-MM-DD')}/>
      </div>
      <div className="fs-7 my-2">
        <label htmlFor="finalDate">{Dictionary.finalDateString}</label>
        <input className="form-control fs-7" type="date" id="finilDate" name="finalDate" onChange={e => onChangeFinalDate(e)} min={initialDate} max={moment().format('YYYY-MM-DD')} disabled={typeof initialDate != 'string'} />
      </div>
    </div>
  )
}
