import * as Redux from "react-redux"
import * as Crud from "../../organism/crud-CrudGenerator/crud-CrudGenerator"
import * as React from "react"
import * as Dictionary from "../../constants/dictionary"

import FormsGeneratorTwo from "../../organism/FormsGeneratorTwo/FormsGeneratorTwo";

export default function CreateTestQuestions ({path, defaultValue = ""}) {
  const url2 = `tests`;
  const optionsSelector = Redux.useSelector(state => state["tableOptions"]);
  let optionsSelectorTwo = Redux.useSelector(state => state["testQuestionTypes"]);
  React.useEffect(()=>{
    Crud.getTableOptions(url2);
    Crud.getTestQuestionTypes();
  },[url2]);
  optionsSelectorTwo = optionsSelectorTwo.map((option) => {
    if(Dictionary.terms.hasOwnProperty(option.name)) {
      return {
        name: Dictionary.terms[option.name],
        uuid: option.uuid
      };
    } else {
      return {
        name: option.name,
        uuid: option.uuid
      };
    };
  });
  const json = {
    title: "Crear una pregunta",
    data: [
      {
        "tests_questions_type.name": "InputFormText",
        name: "name",
        text: Dictionary.nameString,
        strict: true,
      },
      {
        "tests_questions_type.name": "InputFormText",
        name: "text",
        text: Dictionary.textString,
        strict: true,
      },
      {
        "tests_questions_type.name": "SelectInput",
        text: Dictionary.selectTestString,
        options: optionsSelector,
        name: "testId",
        strict: true,
        defaultValue : defaultValue,
      },
      {
        "tests_questions_type.name": "SelectInput",
        text: Dictionary.selectQuestionTypeString,
        options: optionsSelectorTwo,
        name: "testQuestionTypeId",
        strict: true,
      },
      {
        "tests_questions_type.name": "SelectRadio",
        name: "strict",
        options: [
          {
            name: Dictionary.yesString, value: "true"
          }, 
          {
            name: Dictionary.noString, value: "false"
          }
        ],
        text: Dictionary.isRequired,
        strict: true
      },
      {
        "tests_questions_type.name": "InputFile",
        name: "image"
      }
    ]
  };
  
  return(
    <div>
      <div className="d-flex flex-column align-items-end w-100 vh-100">
        <div className="h-15 w-86"></div>
        <div className="w-86 h-85">
          <FormsGeneratorTwo json={json} path={path} method={Crud.createNewItem} reload={true}/>
        </div>
      </div>
    </div>
  )
}