import * as Dictionary from "../../common/domain/constants/dictionary";

import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";
import SessionsTemplateContent from "../domain/templates/sessions-template-content/sessions-template-content";

export default function Sessions() {
  return (
    <>
    <MenuTemplate section={Dictionary.sessionsString} />
    <div className="d-flex flex-column align-items-end w-100">
      <div className="vh-15 w-86"></div>
      <LoadingSpinner div={<div className="w-86"><SessionsTemplateContent/></div>}/>
    </div>
    </>
  )
}
