import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import FormText from "../../../../common/domain/atoms/FormText/FormText";
import FileInput from "../../../../common/domain/atoms/FileInput/FileInput";
import BriefcaseIcon from "../../../../common/domain/atoms/BriefcaseIcon/BriefcaseIcon";

import "./position-edit-organism-generalData.scss";

export default function PositionEditOrganismGeneralData() {
  return (
    <div className="h-55">
      <Paragraph contentTwo={Dictionary.generalDataString + " :"} className="ms-2 fs-position-edit-general-0 mb-2" />
      <div className="border-top d-flex w-100 h-80">
        <div className="w-30 d-flex flex-column aling-items-center justify-content-end mt-4">
          <div className="wr-previewIcon hr-previewIcon mx-previewIcon d-flex align-items-center justify-content-center rounded-4">
            <BriefcaseIcon className="bi bi-briefcase color-blue fsp-0" />
          </div>
          <FileInput label={Dictionary.updataIconString} id='updata-position-picture' accept='image/*' />
        </div>
        <div className="w-70 mt-4 d-flex justify-content-center align-items-end">
          <div className="w-50">
            <FormText id={"name"} name={"name"} label={Dictionary.nameString} placeholder={Dictionary.nameString} />
            <FormText id={"city"} name={"city"} label={Dictionary.cityString} placeholder={Dictionary.cityString} />
            <FormText id={"contact"} name={"contact"} label={Dictionary.contactString} placeholder={Dictionary.mailExampleString} />
          </div>
          <div className="w-50">
            <FormText id={"category"} name={"category"} label={Dictionary.categoryString} placeholder={Dictionary.categoryString} />
            <FormText id={"validity"} name={"validity"} label={Dictionary.validityString} placeholder={Dictionary.validityExampleString} />
            <FormText id={"phone"} name={"phone"} label={Dictionary.phoneString} placeholder={Dictionary.phoneString}  />
          </div>
        </div>
      </div>
    </div>
  )
}
