import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";

import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import OrderButton from "../../../../common/domain/molecule/order-button/order-button";
import UserEditModal from "../../../feature-user-edit/feature-user-edit";
import FilterSelector from "../../../../common/domain/molecule/filter-selector/filter-selector";

import "./users-section-organism-mainMenu.scss"

export default function UsersSectionOrganismMainMenu({state, setState}) {
  let data = Redux.useSelector(state => state["table"]);
  //BUSCADOR----------------------------------------------------------------------------------------
  const searchUsersFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchUsers: undefined});
    } else {
      let newUsers = data?.filter((user : any) => {
        user.name = user.name.toLowerCase();
        return user.name.includes(e.target.value.toLowerCase());
      });
      setState({...state, searchUsers: newUsers});
    };
  };
  //------------------------------------------------------------------------------------------------
 
  //ORDENAR-----------------------------------------------------------------------------------------
  const orderFunction = () => {
    if(state.order) {
      data = data.sort((a : any, b : any) => {
        if (a.name > b.name) {return -1}
        if (a.name < b.name) {return 1}
        return 0;
      })
      setState({...state, order: false});
    } else {
      data = data.sort((a : any, b : any) => {
        if (a.name > b.name) {return 1}
        if (a.name < b.name) {return -1}
        return 0;
      })
      setState({...state, order: true});
    }
  }
  //------------------------------------------------------------------------------------------------
  
  //FILTRADO----------------------------------------------------------------------------------------
  const optionsSelectFilter = []
  //------------------------------------------------------------------------------------------------
  
  return (
    <div className="ps-5 d-flex w-100">
      <div className="me-3">
        <UserEditModal />
      </div>
      <div className="border me-3"></div>
      <div className="me-3">
        <SearchBar onKeyUp={e => searchUsersFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchBarUser"} name={"searchBarUser"}/>
      </div>
      <div className="me-3">
        <FilterSelector onChange={()=>{}} options={optionsSelectFilter} firstOption={Dictionary.userTypeString}/>
      </div>
      <OrderButton onClick={(e) => orderFunction()} text={Dictionary.orderString}/>
    </div>
  )
}