import * as React from "react";
import * as Redux from "react-redux";

import * as Dictionary from "../../../../common/domain/constants/dictionary";
import EditOrganismHeader from "../../organisms/edit-organism-header/edit-organism-header";
import EditOrganismFooter from "../../organisms/edit-organism-footer/edit-organism-footer";
import EditOrganismEvaluatorCard from "../../organisms/edit-organism-evaluator-card/edit-organism-evaluator-card";
import EditOrganismInformation from "../../organisms/edit-organism-information/edit-organism-information";
import * as SessionServices from "../../services/SessionServices";

import "./edit-template-form.scss";
import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
export default function EditTemplateForm() {
  const [evaluatorsState, setEvaluatorsState] = React.useState([]);

  function createData(e : any) : any{
    let data={evaluators: evaluatorsState}
    for(let i : number = 0; i <= 8; i++){
      if(e.target[i])
        if(e.target[i].value)
          data[e.target[i].id] = e.target[i].value
    };
    return data;
  }

  function onSubmit(e:any){
    e.preventDefault();
    let data = createData(e); 
    // Ruta
    SessionServices.createSession(data);
  }
  
  return (
    <form onSubmit={(e : any) => onSubmit(e)} className="modal-dialog modal-fullscreen tl-session-edit">
      <div className="modal-content vh-session-edit vw-session-edit px-5 rounded-4 vh-85">
        <EditOrganismHeader/>
        <div className="modal-body h-75">
          <Paragraph contentTwo={Dictionary.informationString} className="border-bottom mt-1" />
          <EditOrganismInformation />
        </div>
        <EditOrganismFooter/>
      </div>
    </form>
  )
}
