import * as Dictionary from "../../../../common/domain/constants/dictionary";

import FormText from "../../../../common/domain/atoms/FormText/FormText";

import "./profile-edit-organism-generalData.scss";

export default function ProfileEditOrganismGeneralData() {
  const classDiv = "d-flex flex-column w-100 align-items-center";
  const classInput = "text-center w-75 py-2 rounded-10px border fs-label-user-edit mb-2 m-0";
  const classLabel = "fs-label-user-edit mb-1 fw-bold w-75";
  return (
    <div className="mx-auto d-flex w-100 justify-content-around">
      <FormText id='name' name='name' label={Dictionary.nameString} placeholder={Dictionary.nameString} classDiv={classDiv} classInput={classInput} classLabel={classLabel}/>
      <FormText id='type' name='type' label={Dictionary.typeString} placeholder={Dictionary.typeString} classDiv={classDiv} classInput={classInput} classLabel={classLabel}/>
      <FormText id='area' name='area' label={Dictionary.areaString} placeholder={Dictionary.areaString} classDiv={classDiv} classInput={classInput} classLabel={classLabel}/>
    </div>
  )
}
