import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as TestServices from "../../services/TestServices";

import Button from "../../../../common/domain/atoms/Button/Button";

export default function AssignTestsGroupModalOrganismFooter({setTestGroup, setAssign, information, testGroup}) {
  const onSubmit = () => {
    let data : any = {
      userId : information.uuid,
      testId : "",
      testGroupId: testGroup
    }
    TestServices.linkUserTest('test_attempt', data);
    setTestGroup("");
    setAssign(true);
    setTimeout(() => {
      window.location.reload()
    }, 1000);
  }
  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="w-50 d-flex justify-content-evenly mb-3 ms-0">
        <Button onClick={onSubmit} className="btn border rounded-4 shadow fs-8 p-2 px-3" text={Dictionary.acceptChangesString} />
        <div className="border"></div>
        <button type="button" className="btn border rounded-4 shadow px-5 fs-8" data-bs-dismiss="modal">{Dictionary.cancelString}</button>
      </div>
    </div>
  )
}
