import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";

import "./candidate-edit-modal-organism-header.scss";

export default function CandidateEditModalOrganismHeader() {
  return (
    <div className="rounded-bottom rounded-4 h-10 d-flex align-items-end justify-content-center">
      <Paragraph contentTwo={Dictionary.editionString + " " + Dictionary.ofString + " " + Dictionary.candidateString} className="m-0 fs-candidate-edit-title" />
    </div>
  )
}
