import * as React from "react";
import * as Redux from "react-redux";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, BarElement } from 'chart.js';
import { Bar, Line, Pie } from "react-chartjs-2";
import * as DashboardServices from "../../services/DashboardServices"
import { DASHBOARD_CONST } from "../../constants/DashboardConstants";

ChartJS.register(ArcElement, Tooltip, Legend, LinearScale, BarElement);

export default function Dashboard () {
  React.useEffect(() => {
    DashboardServices.getAllBusinessChart()
    DashboardServices.getNextProcessBusiness()
    DashboardServices.getNextProcessBusinessChart()
    DashboardServices.getNextProcessBusinessHeadquartersChart()
    DashboardServices.getNormalDistributionChart()
  }, [])

  let dashboardAllBusiness = Redux.useSelector(state => state['dashboardAllBusiness'])
  let dashboardNextProcessBusiness = Redux.useSelector(state => state['dashboardNextProcessBusiness'])
  let dashboardNextProcessBusinessChart = Redux.useSelector(state => state['dashboardNextProcessBusinessChart']);
  let dashboardNextProcessBusinessHeadquartersChart = Redux.useSelector(state => state['dashboardNextProcessBusinessHeadquartersChart']);
  let dashboardNormalDistributionChart = Redux.useSelector(state => state['dashboardNormalDistributionChart']);

  if(dashboardAllBusiness.datasets) dashboardAllBusiness.datasets.map(item => item.backgroundColor = DASHBOARD_CONST.chartColors);
  if(dashboardNextProcessBusinessChart.datasets) dashboardNextProcessBusinessChart.datasets.map(item => item.backgroundColor = DASHBOARD_CONST.chartColors);
  if(dashboardNextProcessBusinessHeadquartersChart.datasets) dashboardNextProcessBusinessHeadquartersChart.datasets.map(item => item.backgroundColor = DASHBOARD_CONST.chartColors);
  if(dashboardNormalDistributionChart.datasets) dashboardNormalDistributionChart.datasets.map(item => item.backgroundColor = DASHBOARD_CONST.chartColors);

  return (
    <>
    <div className="vh-100 p-3">
      <div className="row">
        <div className="col-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Personas registradas / empresa</h5>
              <p className="card-text">
                {
                  <Pie data={dashboardAllBusiness} />
                }
                
              </p>
            </div>
          </div>
        </div>
        <div className="col-8">
          <table className="table table-striped">
            <th>Empresa</th>
            <th>No. candidatos</th>
            <th>No. aptos</th>
            <th>Formación</th>
            <th>Entrevista</th>
            {
              dashboardNextProcessBusiness.map(row => (
                <tr>
                  <td>{row.name}</td>
                  <td>{row.amount}</td>
                  <td>{row.competent}</td>
                  <td>{row.trainingForWork}</td>
                  <td>{row.interview}</td>
                </tr>
              ))
            }
          </table>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Siguiente proceso</h5>
              <p className="card-text">
                {
                  dashboardNextProcessBusinessChart.datasets ? <Bar data={dashboardNextProcessBusinessChart} /> : DASHBOARD_CONST.EMPTY_DATA
                }
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Siguiente proceso / sede</h5>
              <p className="card-text">
                {
                  dashboardNextProcessBusinessHeadquartersChart.datasets ? <Bar data={dashboardNextProcessBusinessHeadquartersChart} /> : DASHBOARD_CONST.EMPTY_DATA
                }
              </p>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Distribución normal</h5>
              <p className="card-text">
                {
                  dashboardNormalDistributionChart.datasets ? <Line data={dashboardNormalDistributionChart} /> : DASHBOARD_CONST.EMPTY_DATA
                }
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}