import * as Dictionary from "../../constants/dictionary";
import * as Redux from "react-redux";
import * as RouterDom from "react-router-dom";

import Button from "../../../../common/domain/atoms/Button/Button";

export default function AuthOrganismSuccessMsgRecovery() {
  const alertMsg = Redux.useSelector(state => state["alertMsg"]);  
  return (
    <>
    {
      alertMsg === "Se ha enviado una nueva contraseña al correo" &&
      <>
      <div className="alert alert-success text-center m-2">{alertMsg}</div>
      <RouterDom.Link to="/login" >
        <Button className={"btn btn-primary m-2"} text={Dictionary.toLogin} />
      </RouterDom.Link>
      </>
    }
    </>  
  )
}
