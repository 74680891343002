import * as Dictionary from "../../../../common/domain/constants/dictionary";

import TestValorationBCard from "../../../../common/domain/molecule/TestValorationBCards/TestValorationBCards";
import { REPORT_TERMS } from "../../constants/reportsConstants";
import "./result-report-organism-valorarionCardB.scss";
export default function ResultReportOrganismValorationCardsB({information, title}) {
  const data = [];

  for(var i in information){
    let obj = information[i]
    obj.title = i
    data.push(obj)
  }

  return (
    <div className="container">
      <p className="fs-5">Prueba: { title }</p>
      <div className="row">
        {
          data.map((item: any) => (
            <div className="col-6 mt-2">
              <TestValorationBCard data={item} title={ REPORT_TERMS[item.title] } />
            </div>
          ))
        }
      </div>
    </div>
  )
}
