import * as Dictionary from "../../constants/dictionary";

import Button from "../../atoms/Button/Button";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import TextArea from "../../atoms/TextArea/TextArea";

export default function InputText({question,  state, stateKey, setActive, setState}) {
  const response = (e : any) : void => {
    e.preventDefault();
    setActive(true);
    setTimeout(()=>{
      setState([{
        ...state[0],
        [stateKey]: {
          ...state[0][stateKey],
          questionResponseValue: e.target[0].value,
        }
      }]);
    }, 500);
    setTimeout(()=>{
      e.target[0].value = "";
      setActive(false);
    }, 500);
  };

  
  return (
    <div className="container h-100 d-flex flex-column text-center">
      <div className="mb-3 mt-5 pb-2">
        <Paragraph contentTwo={question} className={"fs-7 fw-bold"} />
      </div>
      <form onSubmit={e => response(e)}>
        <div className="row pt-3 px-3">
          <TextArea rows={3} />
        <Button text={Dictionary.next} />
        </div>
s      </form>
    </div>
  );
};
