import * as Dictionary from "../../common/domain/constants/dictionary";

import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";
import Dashboard from "../domain/templates/Dashboard/Dashboard";

export default function FeatureDashboard() {
  return (
    <>
    <MenuTemplate section={Dictionary.dashboard} />
    <div className="d-flex flex-column align-items-end w-100">
      <div className="vh-10 w-86"></div>
      <LoadingSpinner div={<div className="w-86"><Dashboard/></div>}/>
    </div>
    </>
  )
}
