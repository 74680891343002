import * as Redux from "react-redux";
import * as React from "react";
import * as ProfileServices from "../../services/ProfileService";

import ProfileCardTwo from "../../../../common/domain/molecule/ProfileCardTwo/ProfileCardTwo";

export default function ProfilesOrganismCards({state}) {
  const profiles = Redux.useSelector(state => state["profilesTable"]);
  React.useEffect(() => {
    ProfileServices.getProfiles(`profiles`);
  }, []);
  (() => {
    let count : number = 0;
    profiles?.data?.map((profile : any)=>{
      switch(count){
        case (0):
          profile['icon'] = "bi bi-robot";
          count++;
        break;
        case(1):
          profile['icon'] = "bi bi-chat-square-dots";
          count++;
        break;
        case(2):
          profile['icon'] = "bi bi-mic";
          count++;
        break;
        case(3):
          profile['icon'] = "bi bi-puzzle";
          count++;
        break;
      }
    })
  })()
  return (
    <div className="d-flex flex-wrap ms-5 h-40 mt-3">
      {
        state.searchProfiles !== undefined ? state.searchProfiles?.map((d : any) => (
          <div className="me-5">
            <ProfileCardTwo data={d}/>
          </div>
        ))
        :
        profiles?.data?.length > 0 && (typeof profiles?.data !== "string") && profiles?.data?.map((d : any) => (
          <div className="me-5">
            <ProfileCardTwo data={d}/>
          </div>
        ))
      }
    </div>
  )
}