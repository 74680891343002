import * as React from "react";
import PositionSectionOrganismTestCards from "../../organisms/positionSection-organism-testCards/positionSection-organism-testCards";
import "./position-section-template-testCards.scss";
export default function PositionSectionTemplateTestCards() {
  const [state, setState] = React.useState({
    searchSkills: undefined,
  })
  return (
    <div className="w-100 vh-20 ms-2 m-0">
      <PositionSectionOrganismTestCards state={state} setState={setState}/>
    </div>
  )
}
