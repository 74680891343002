import * as Dictionary from "../../common/domain/constants/dictionary";

import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";
import CustomersTemplateHeader from "../domain/templates/customers-template-header/customers-template-header";
import TemplateHeader from "../../common/domain/template/template-header/template-header";
import CustomersTemplateBody from "../domain/templates/customers-template-body/customers-template-body";

import "./feature-customers.scss";

export default function Customers() {
  return (
    <>
    <MenuTemplate section={Dictionary.customersString} />
    <div className="d-flex flex-column align-items-end w-100">
      <div className="vh-15 w-86"></div>
      <LoadingSpinner div={<div className="w-86"><TemplateHeader title={Dictionary.customersString} subTitle={Dictionary.customersRegisteredString + " :"}/><CustomersTemplateBody/></div>} />        
    </div>
    </>
  )
}
