import * as Dictionary from "../../../../common/domain/constants/dictionary";

import CreateButton from "../../../../common/domain/molecule/create-button/create-button";

import "./user-edit-organism-trigger.scss";

export default function UserEditOrganismTrigger() {
  return (
    <CreateButton target={'userEdit'} paragraph={Dictionary.create + " " + Dictionary.user_string.toLowerCase()+" +"} />
  )
}
