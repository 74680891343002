import BoxesIcon from "../BoxesIcon/BoxesIcon";
import "../../../progress-circle.css";

export default function ProgressCircular({value, icon = "bi bi-boxes m-0 fs-5"}) {
  return (
    <div className={`progress-circle progress-${value} m-0`}>
      <span className="">
      </span>
    </div>
  );
};
