import * as AuthServices from "../domain/services/AuthServices";

import Paragraph from "../../common/domain/atoms/Paragraph/Paragraph";
import {logoutDone} from "../domain/constants/dictionary";

let count : number = 0;

export default function Logout() {
  if(count === 0) {
    AuthServices.logout();
    count++
    setTimeout(()=>{
      window.location.replace("/login")
    }, 1500)
  } 
  return (
    <div className="container w-25 text-center pt-5">
      <Paragraph contentTwo={logoutDone} className={"alert alert-success"} />
    </div>
  )
}