import UserEditTemplateTrigger from "../domain/templates/user-edit-template-trigger/user-edit-template-trigger";
import UserEditTemplateForm from "../domain/templates/user-edit-template-form/user-edit-template-form";

import "./feature-user-edit.scss";

export default function UserEditModal() {     
  return (
    <>
    <UserEditTemplateTrigger />
    <div className="modal fade" id="userEdit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true"> 
      <UserEditTemplateForm />
    </div>
    </>
  )
}
