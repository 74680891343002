import * as Redux from "react-redux";
import * as Dictionary from "../../../../common/domain/constants/dictionary";

import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import FilterSelector from "../../../../common/domain/molecule/filter-selector/filter-selector";
import OrderButton from "../../../../common/domain/molecule/order-button/order-button";
import CustomersEdit from "../../../feature-customers-edit/feature-customers-edit";

import "./customers-organism-mainmenu.scss";

export default function CustomersOrganismMainmenu({state, setState}) {
  let data = Redux.useSelector(state => state["table"]);
  //BUSCADOR----------------------------------------------------------------------------------------
  const searchCustomersFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchCustomers: undefined});
    } else {
      let newCustomers = data?.filter((customer : any) => {
        customer.name = customer.name.toLowerCase();
        return customer.name.includes(e.target.value.toLowerCase());
      });
      setState({...state, searchCustomers: newCustomers});
    };
  };
  //------------------------------------------------------------------------------------------------
 
  //ORDENAR-----------------------------------------------------------------------------------------
  const orderFunction = () => {
    if(state.order) {
      data = data.sort((a : any, b : any) => {
        if (a.name > b.name) {return -1}
        if (a.name < b.name) {return 1}
        return 0;
      })
      setState({...state, order: false});
    } else {
      data = data.sort((a : any, b : any) => {
        if (a.name > b.name) {return 1}
        if (a.name < b.name) {return -1}
        return 0;
      })
      setState({...state, order: true});
    }
  }
  //------------------------------------------------------------------------------------------------
  
  //FILTRADO----------------------------------------------------------------------------------------
  const optionsSelectFilter = []
  //------------------------------------------------------------------------------------------------
  
  return (
    <div className="ps-5 d-flex w-100">
      <div className="me-3">
        <CustomersEdit />
      </div>
      <div className="border me-3"></div>
      <div className="me-3">
        <SearchBar onKeyUp={e => searchCustomersFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchBarCustomer"} name={"searchBarCustomer"}/>
      </div>
      <div className="me-3">
        <FilterSelector onChange={()=>{}} options={optionsSelectFilter} firstOption={Dictionary.filterString} className={"text-center w-100 py-2 wr-btn-12 rounded-4 color-blue fw-bold fs-btn-label m-0 border"}/>
      </div>
      <OrderButton onClick={(e) => orderFunction()} text={Dictionary.orderString} className="btn border rounded-4 fs-btn-label py-2 px-5 fw-bold color-blue wr-btn-12 d-flex align-items-center justify-content-center" />
    </div>
  )
}
