import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";

import "./profile-edit-organism-header.scss";

export default function ProfileEditOrganismHeader() {
  return (
    <div className="rounded-bottom rounded-4 h-10 d-flex align-items-end justify-content-center mb-3 mt-2">
      <Paragraph contentTwo={Dictionary.editionString + " " + Dictionary.ofString + " " + Dictionary.profilesString.toLocaleLowerCase()} className="m-0 fs-profile-edit-header-0" />
    </div>
  )
}
