import Paragraph from "../Paragraph/Paragraph";

import * as Dictionary from "../../constants/dictionary";

export default function ImageQuestionOptionCard({element, number}) {
  return (
    <div className="text-center align-middle">
      <Paragraph contentTwo={Dictionary.optionString + " " + number} className="fs-7 fw-bold" />
      <img src={element} className={"img-option-question"} alt="Img not found" />
    </div>
  )
}
