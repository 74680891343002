import { get, post, put, deleted } from "../../../common/domain/services/api";
import store from "../../../common/redux/store";
import * as Constants from "../../../common/redux/constants";

export function createNewDepartment ( data : any){
  post('departament', data)
  .then(res => store.dispatch({type: Constants.CREATE_ITEM_IN_TABLE, payload : res.data}))
  .catch(error => {throw new Error(error)})
};

export function getAllDepartments(){
  get('departament')
  .then(res => store.dispatch({type: Constants.GET_ALL_DEPARTMENTS, payload : res.data}))
  .catch(error => {throw new Error(error)})
}