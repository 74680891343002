import { data } from "jquery";
import { myStorage } from "../../redux/constants";
import * as apiServices from "./api";

export function set (token:string){
  localStorage.setItem("token", token);
}
export function get(){
  return localStorage.getItem('token');
}
export function validTokenNoLogin(redirect:string){
  apiServices.get('verification_token')
  .then(res => {
    if(res.data === 'Token valido'){
      window.location.href = redirect
    }
  })
}
export function validTokenLogin(redirect:string){
  apiServices.get('verification_token')
  .then(res => {
    if(res.data === 'Token expirado' || res.data === 'Token invalido'){
      window.location.href = redirect
    }    
  })
}