import * as React from "react";
import * as Redux from "react-redux";
import * as UserServices from "../../services/UserServices";

import PersonCard from "../../../../common/domain/molecule/person-card/person-card";
import Pagination from "../../../../common/domain/atoms/Pagination/Pagination";

import "./users-section-organism-body.scss";

export default function UsersSectionOrganismBody({state}) {
  let data = Redux.useSelector(state => state["users"]);
  let numberPage : number = 0, imgNumber : number = 0, windowHeight : number = window.innerHeight * 73 / 100, windowWidth : number = window.innerWidth, cardHeight : number = 358, cardWidth : number = 288;
  let itemsPerPage : number = Math.floor(windowHeight / cardHeight) * Math.floor((windowWidth * 86 / 100)/cardWidth);
  const [actualPage, setActualPage] = React.useState(1)
  let setData = {
    limit: itemsPerPage,
    offset: (itemsPerPage*actualPage) - itemsPerPage,
  } 
  React.useEffect(()=>{
    UserServices.getUsers(setData);
  },[actualPage]);

  if(windowWidth >= 1700 && windowWidth < 1900) {
    cardHeight = 432;
    cardWidth = 320;
  } else if (windowWidth >= 1900) {
    cardHeight = 592;
    cardWidth = 404; 
  };
  if(data?.amount > 0){
    numberPage = Math.ceil(data?.amount/itemsPerPage)
    data.users = data.users.map((user:any) => {
      let img : string;
      switch(imgNumber){
        case (0):
          img = "https://picsum.photos/id/1005/300/300";
          imgNumber++;
        break;
        case (1):
          img = "https://picsum.photos/id/177/300/300"
          imgNumber++;
        break;
        case (2):
          img = "https://picsum.photos/id/513/300/300"
          imgNumber++;
        break;
        case (3):
          img = "https://picsum.photos/id/602/300/300"
          imgNumber++;
        break;
        case (4):
          img = "https://picsum.photos/id/64/300/300"
          imgNumber = 0;
        break;
      }      
      return {
        ...user,
        img : img
      }
    })
  };
  if(numberPage === 1)numberPage = 0
  else if(Number.isNaN(numberPage))numberPage = 0;
  
  return (
    <>
    <div className="mx-4 mt-2 d-flex flex-wrap">
      {
        state.searchUsers !== undefined ?
          state.searchUsers?.map((d : any) => (
            <div className="m-4">
              <PersonCard information={d} />
            </div>
          )) 
          :
          data?.users?.length > 0 && (typeof data !== "string") && data.users.map((d : any) => (
            <div className="m-4">
              <PersonCard information={d} />
            </div>
          ))
      }
    </div>
    <div className="mx-4">
    {
      numberPage !== 0 && state.searchUsers === undefined &&
      <Pagination pages_number={numberPage} state={actualPage} setState={setActualPage} />
    }
    </div>
    </>
  )
}
