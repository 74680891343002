import * as Dictionary from "../../constants/dictionary";

import Icon from '../../atoms/Icon/Icon';
import Paragraph from '../../atoms/Paragraph/Paragraph';
import EditButton from "../edit-button/edit-button";

import "./ProfileCardTwo.scss";

export default function ProfileCardTwo({data, setState = null}) {
  const classNameCard = "d-flex flex-column align-items-center justify-content-center h-100";
  function selectedFunction () {setState(data?.uuid)}
  return (
    <div id={data?.uuid} onClick={selectedFunction} className="border wr-profileCard-two hr-profileCard-two rounded-4 mt-3 position-relative shadow-card-profile-two">
      <div className={classNameCard}>
        <div className="h-45 fs-icon-profile-card-two-0 color-blue d-flex align-items-center">
          <Icon className={data.icon} />
        </div>
        <div className="h-20">
          <Paragraph contentTwo={Dictionary.terms[data?.name]} className="fs-name-profile-card-two-2 fw-bold text-center" />
        </div>
        <div className="h-20">
          <EditButton paragraph={Dictionary.editString} target='profileEdit' />
        </div>
      </div>
    </div>
  )
}
