export default function Input({type, placeholder = "", id, name, expression = null, className="form-control mb-4 shadow-sm bg-body", 
required = false, title = "", defaultValue="", onChange = (e) => {}, onKeyUp = (e) => {}}) {
  if(defaultValue.length === 0){
    defaultValue = null
  };

  return (
    <>
      <input
        onChange={(e)=>{onChange(e)}}
        onKeyUp={(e)=>{onKeyUp(e)}}
        className={className}
        id={id}
        name={name}
        type={type}
        defaultValue={defaultValue}
        placeholder={placeholder}
        required={required}
        pattern={expression}
        title={title}
      />
    </>
  );
};
