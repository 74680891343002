export const selectTable : string = "Selecciona una tabla";
export const genderString : string = 'Género';
export const domainantHandString : string = 'Mano dominante';
export const municipalityString : string = 'Municipio';
export const graduationYearString : string = 'Año de graduación';
export const neighborhoodString : string = 'Barrio';
export const weightString : string = 'Peso';
export const heightString : string = 'Altura';
export const state : string = 'Estado';
export const startTime : string = 'Hora de inicio';
export const date : string = 'Fecha';
export const finishTime : string = 'Hora de finalización';
export const participantsString : string = 'Participantes';
export const actionsString : string = 'Acciones';
export const sessionString : string = 'sesión';
export const sessionDetail : string = 'detalle de la sesión';
export const mailExampleString : string = "ejemplo@mail.com";
export const updataPhotoString : string = "Subir foto";
export const updataIconString : string = 'Subir ícono';
export const contactPersonString : string = "Persona de contacto";
export const lastnameString : string = "Apellido";
export const candidateString : string = "Candidato";
export const selectTestsToAssignString : string = "Selecciona las pruebas que quieres asignar";
export const selectTestsGroupToAssignString : string = "Selecciona el grupo de pruebas que quieres asignar";
export const CandidatesWithPendingTestsString : string = 'Candidatos con pruebas pendientes';
export const assignTestsString : string = "Asignar pruebas";
export const assignTestsStringTwo : string = "Asignación de pruebas";
export const individualTestString : string = "Prueba individual";
export const assignTestsGroupString : string = "Asignación de pruebas agrupadas";
export const groupTestsString : string = "Grupo de pruebas";
export const assignProfilesString : string = "Asignar perfil";
export const profileString : string = "Perfil";
export const mathString : string = "Matemáticas";
export const reportString : string = "Informe";
export const mathDescriptionString : string = "Ciencia que estudia las propiedades de los números y las relaciones que se establecen entre ellos";
export const logicString : string = "Lógica";
export const logicDescriptionString : string = "Método o razonamiento en el que las ideas o la sucesión de los hechos se manifiestan o se desarrollan de forma coherente y sin que haya contradicciones entre ellas";
export const addString : string = "Agregar";
export const categoryString : string = "Categoria"
export const assignPermissionsString = "Asignar permiso";
export const selectColumns : string = "Selecciona las columnas";
export const selectNumberItemsPerPage : string = "Selecciona el número de ítems por página";
export const tableGenerator : string = "Generar la tabla";
export const newTableGenerator : string = "Generar una nueva tabla";
export const editionString : string = "Edición";
export const editString : string = "Editar";
export const main : string = "Inicio";
export const cityString : string = "Ciudad"
export const emailString : string = "Email"
export const cancelString : string = "Cancelar";
export const acceptChangesString : string = "Aceptar cambios";
export const saveChangesString : string = "Guardar cambios";
export const permissionsString = "Permisos";
export const sessionsString : string = "Sesiones";
export const profilesString : string = "Perfiles";
export const permissionString : string = "Permiso";
export const forms : string = "Formularios";
export const customersString : string = "Clientes";
export const customerString : string = "Cliente";
export const tables : string = "Tablas";
export const skills_string : string = "Destrezas";
export const skillString : string = "Destreza";
export const skillsMatrixTests : string = "Matriz de destrezas y pruebas";
export const skillsMatrix : string = "Matriz de destrezas";
export const testMatrixString : string = "Matriz de pruebas individuales";
export const matrixString : string = "Matriz";
export const testsGroupMatrixString : string = "Matriz de pruebas agrupadas";
export const users_string : string = "Usuarios";
export const modifyRolesString : string = 'Modifica los roles de cada usuario'
export const ofString : string = "de";
export const positionString : string = "Cargo";
export const positionsString : string = "Cargos";
export const modifiesPositionsString : string  = "Modifica los cargos, pruebas y destrezas";
export const candidates_string : string = "Candidatos";
export const evaluationsString : string = "Evaluaciones";
export const findTheReportsString : string = "Aquí encontrarás los informes";
export const user_string : string = "Usuario";
export const test_string : string = "Prueba";
export const testsGroupStringTwo : string = "Grupo de pruebas";
export const test_questions_string : string = "Preguntas";
export const test_question_options_string : string = "Opciones";
export const close : string = "Cerrar";
export const create : string = "Crear";
export const createTestString : string = "Crear prueba";
export const createTestGroupString : string = "Crear grupo de pruebas";
export const createNewItemName = "Nombre para el nuevo item";
export const deleteWord : string = "Borrar";
export const deleteQuestion : string = "¿Deseas borrar el ítem?";
export const viewItemTable : string = "Ver";
export const updataItemTable : string = "Actualizar";
export const deleteItemTable : string = "Borrar";
export const previous : string = "Anterior";
export const profile : string = "Perfil";
export const next : string = "Siguiente";
export const optionTable : string = "Opciones";
export const optionString : string = "Opción";
export const skillUpdateString : string = "Actualizar destreza";
export const completeFormString : string = "Llene el formulario";
export const testUpdateString : string = "Actualizar prueba";
export const testQuestionUpdateString : string = "Actualizar pregunta";
export const testQuestionOptionUpdateString : string = "Actualizar opción";
export const permissionsUpdateString : string = "Actualizar permiso";
export const profilesUpdateString : string = "Actualizar perfil";
export const newNameString : string = "Nuevo nombre";
export const nameString : string = "Nombre";
export const descriptionString : string = "Texto";
export const dataString : string = "Dato"
export const completedNameString : string = "Nombre completo"
export const celphoneString : string = "Teléfono movil";
export const phoneString : string = "Teléfono";
export const andressString : string = "Dirección"
export const identificationString : string = "Identificación";
export const validityString : string = 'Vigencia';
export const validityExampleString : string = 'DD/MM/AAAA';
export const textString : string = "Texto";
export const isRequired : string = "¿Es obligatorio?";
export const isCorrectOption : string = "¿Es la respuesta correcta?"
export const selectSkillString : string = "Selecciona una destreza";
export const selectUserString : string = "Selecciona un usuario";
export const selectQuestionString : string = "Selecciona una pregunta";
export const selectTestString : string = "Selecciona una prueba";
export const SelectTheTestThatUWantLinkToSkillString : string = "Selecciona una prueba y agrégala a la destreza";
export const selectPermissionsString : string = "Seleccione un permiso";
export const selectAnOptionString : string = "Seleccione una opción";
export const filterString : string = "Filtrar";
export const userTypeString : string = "Tipo de usuario";
export const typeString : string = "Tipo";
export const areaString : string = "Área";
export const orderString : string = "Ordenar por";
export const selectProfilesString : string = "Seleccione un perfil";
export const selectQuestionTypeString : string = "Selecciona el tipo de pregunta";
export const yesString : string = "Si";
export const noString : string = "No";
export const testsString : string = "Pruebas";
export const createNewSkillString : string = "Crear una destreza";
export const createNewPermissionsString : string = "Crear un permiso";
export const createNewProfilesString : string = "Crear un perfil";
export const welcome : string = "Bienvenido";
export const registeredUsers : string = "Usuarios registrados";
export const totalUsersRegisteredString : string = "Total usuarios registrados";
export const totalCandidatesRegisteredString : string = "Total candidatos registrados";
export const candidatesRegisteredString : string = "Aquí encontrarás los candidatos registrados";
export const customersRegisteredString : string = "Aquí encontrarás los clientes registrados";
export const skillsActivedString : string = "Aquí encontrarás las destrezas activas";
export const modifyTheRolesOfEachProfileString : string = "Modifica los roles de cada perfil";
export const termsAndConditions : string = "políticas y procedimientos para el tratamiento y protección de datos personales";
export const positionNameString : string = "Nombre cargo";
export const searchString : string = "Buscar";
export const iAgreeString : string = "Estoy de acuerdo";
export const iNotAgreeString : string = "No estoy de acuerdo";
export const userProfileString : string = "Perfil del usuario";
export const jobProfileOneString : string = "1° perfil ideal";
export const jobProfileTwoString : string = "2° perfil ideal";
export const jobProfileThreeString : string = "3° perfil ideal";
export const personalInformationString : string = "Información personal";
export const informationString : string = "Información";
export const contactString : string = "Contacto";
export const generalString : string = "General";
export const theOneString : string = "la";
export const registedDateString : string = "Fecha de registro";
export const resumeString : string = "Resumen";
export const totalScoreString : string = "Puntuación total";
export const testsGroupString : string = "Pruebas agrupadas";
export const overcomeString : string = "Realizada";
export const statusString : string = "Estado";
export const noOvercomeString : string = "No realizada";
export const resultsString : string = "Resultados";
export const resultsReportString : string = "Informe de resultados";
export const theProfileForWhichItfitsMoreString : string = "El perfil para el cual se ajusta más";
export const specificTestDefinitionString : string = "De acuerdo a su conocimiento, la persona presenta capacidades para desempeñarse en:"
export const lateralThinkingDefinitionString : string = "Es una habilidad que permite buscar soluciones novedosas a problemas de una manera creativa y atípica, evaluando la creatividad, razonamiento y la rapidez de su pensamiento"
export const lateralThinkindString : string = "Pensamiento lateral";
export const specificTestString : string = "Prueba específica";
export const processingOfPersonalDataString : string = "Tratamiento de datos personales";
export const thereIsNotDataString : string = "No hay datos";
export const noSkillsAssessed : string = "No hay destrezas relacionadas";
export const noTestsAssigned : string = "No hay pruebas asignadas";
export const testAssignedSuccessfully : string = "Prueba asignada con éxito";
export const responsesSendedSuccessfullyString : string = "Respuestas enviadas";
export const generalDataString : string = "Datos generales";
export const profilesManagementString : string = "Administración de perfiles";
export const activeString : string = "activas";
export const totalString : string = "Total";
export const confirmEmailString : string = "Confirmar e-mail";
export const academicLevelString : string = 'Nivel académico';
export const thankYouMessageString : string = "¡Gracias, has finalizado!";
export const todayString : string = "Hoy";
export const thisMonthString : string = "Este mes";
export const thisWeekString : string = "Esta semana";
export const selectTimeRangeString : string = "Seleccionar rango de tiempo";
export const initialDateString : string = "Fecha inicial";
export const finalDateString : string = "Fecha final";
export const acceptString : string = "Aceptar";
export const orString : string = "ó";
export const hiString : string = "Hola";
export const sessionsActivedString : string = 'Sesiones activas';
export const hereYouWillFindAllActiveSessions : string = 'Aquí encontrarás todas las sesiones activas';
export const selectADepartmentString : string = 'Seleccione un departamento';
export const dateBirthString : string = 'Fecha de nacimiento';
export const departamentString : string = 'Departamento';
export const experienceString : string = 'Experiencia';
export const familyNucleusString : string = 'Núcleo familiar';

export const alertCompleteForm : string = "Formulario completado con éxito";
export const alertNocompleteForm : string = "Debe llenar todos los espacios obligatorios";
export const alertNoCompleteSelect : string = "Debe de escoger al menos un ítem por columna";
export const strictModeForm : string = "Todos los campos marcados con * son obligatorios";
export const buttonSend : string = "Enviar";
export const dashboard: string = "Dashboard"

export const individualUrl: string = "/valoration_response/"
export const siseUrl: string = "/data_sise/session/"

export const passwordString: string = 'Contraseña inicial'

export const terms = {
    users: "Usuarios",
    enable: "Activo",
    created_at: "Fecha de creación",
    name: "nombre",
    updated_at: "Última actualización",
    skills: "Destrezas",
    tests: "pruebas",
    test_questions: "preguntas",
    test_question_options: "opciones",
    test_question_name: "tipo",
    text: "Pregunta",
    "tests_questions_type.name": "tipo",
    InputFormText: "Formulario de texto",
    InputFormNumber: "Formulario de número",
    SelectInput: "Selector",
    SelectRadio: "Selector de tipo radio",
    Checkbox : "Caja de confirmación",
    user_permissions: "Permisos",
    profiles : 'Perfiles',
    'tests.name' : "Nombre",
    candidates : "Candidatos",
    positions: "Cargos",
    imageQuestion: "Pregunta de imagen",
    done: "Realizado",
    noDone: "Pendiente",
    all: "Todos",
    discontinuity: "Discontinuidad",
    fixings: "Fijaciones",
    improvement: "Creatividad",
    candidate: "Candidato",
    administrator: "Administrador",
    evaluators: 'Evaluadores',
    date: 'Fecha',
    startTime: 'Hora de inicio',
    finishTime: 'Hora de finalización',
    ubicate: 'Lugar',
    city: 'Ciudad',
    andress: 'Dirección',
    state: 'Estado',
    actions: 'Acciones',
};

export const habeasDataParagraphOne : string = "El titular, quien además afirma ser titular de la información compartida, autoriza a CAUSA & EFECTO S.A.S, de forma libre, expresa, voluntaria y por escrito para que de acuerdo con lo dispuesto en la Ley 1581 de 2012 recolecta, recaude, almacene, use, circule, suprima, procese, compile, intercambie, dé tratamiento, actualice y disponga de sus datos personales que han sido suministrados en virtud de la presente solicitud, los cuales serán incorporados en distintas bases o bancos de datos de titularidad de CAUSA & EFECTO S.A.S. Dicha información será utilizada exclusivamente en el desarrollo de las funciones propias de su receptor, como empresa dedicada a la prestación de servicios de formación, selección por destreza y consultoría a pequeñas, medianas y grandes empresas. Para tales fines, CAUSA & EFECTO S.A.S podrá publicitar directa o indirectamente su información, siendo así que sus datos de contacto serán utilizados para el envío de comunicaciones, informes de selección por destreza, reportes, boletines, noticias u otros relacionados con sus servicios."
export const habeasDataParagraphTwo : string = "También autorizo para que se incluya en el proceso de Selección por destreza, para efectos de elaboración de informes, reproducción y comunicación pública; las fotografías, vídeos, grabaciones sonoras y registros en cualquier tipo de soporte, realizados durante y para el mencionado proceso, así como para utilizar mi imagen en el proceso para los fines y dentro de los propósitos establecidos por Causa & Efecto S.A.S. Los derechos aquí autorizados se dan sin limitación geográfica o territorial alguna. De igual forma la autorización de uso aquí establecida no implicará exclusividad, por lo que me reservo el derecho de otorgar autorizaciones de uso similares en los mismos términos en favor de terceros. La autorización que aquí se concede sobre este material es exclusiva para el proceso de Selección por destreza."
export const habeasDataParagraphThree : string = "No obstante lo anterior, su titular podrá en cualquier momento, de forma expresa, directa, inequívoca y por escrito solicitarle a CAUSA & EFECTO S.A.S que sus datos sean suprimidos de las bases o banco de datos pertinentes al correo natalia.jaramillo@causayefecto.com.co."