import * as Dictionary from "../../constants/dictionary";

import Button from "../../atoms/Button/Button";
import moment from "moment";

export default function ThisWeekBotton({method, setState}) {
  function onClick (e : any) : void {
    setState([
      moment().subtract(1, 'week').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
    ]);
    method(moment().subtract(1, 'week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
  }
  
  
  return (
    <Button onClick={e => onClick(e)} text={Dictionary.thisWeekString} className="btn border rounded-4 px-4 py-1 my-1 fs-7" />
  )
}
