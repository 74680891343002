import Paragraph from "../../atoms/Paragraph/Paragraph";
import Icon from "../../atoms/Icon/Icon";

import "./PermissionMiniCard.scss";

export default function PermissionMiniCard({data, state, setState}) {
  function onClickPermission () {
    if(state.includes(data.uuid)){
      let newPermissions = state.filter((permissionUuid : any) => {
        return permissionUuid !== data.uuid
      });
      setState(newPermissions);
    } else {
      setState([...state, data.uuid])
    };
  };  
  
  return (
    <div onClick={onClickPermission} className="d-flex align-items-center justify-content-evenly pointer">
      <Paragraph contentTwo={data?.name} className="m-0 w-60 text-truncate fs-7" />
      <Icon className={state.includes(data.uuid) ? "bi bi-toggle-on fs-5 color-blue" : "bi bi-toggle-off fs-5 color-blue"} />
    </div>
  )
}
