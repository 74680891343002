import * as Router from "react-router";

import { myStorage } from "../../../redux/constants";

import "./Tabs.scss";

export default function Tabs ({data}){
  const params = Router.useParams();
  const tests = myStorage.getItem("tests").split(",");
  return (
    <ul className="nav nav-tabs w-100">
      {
        data?.map((d : any)=>(
          <a key={d.uuid} aria-current="page" className={params.testId !== d.uuid ? tests.includes(d.uuid) ? "nav-link disabled bg-color-light-gray text-decoration-none text-dark fs-8" : "nav-link text-decoration-none text-dark fs-8" : "nav-link active text-decoration-none text-dark fs-8"} href={d.href}>
            <li className="nav-item">
              {d.name}
            </li>
          </a>
        ))
      }
    </ul>
  )
}