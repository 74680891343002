import * as Dictionary from "../../constants/dictionary";

import Paragraph from "../../atoms/Paragraph/Paragraph";
import ProgressCircular from "../../atoms/ProgressCircular/ProgressCircular";

import "./CandidateDetailResume.scss";

export default function CandidateDetailResume ({information}) {
  information.skills = [
    {
      name: "trabajo en equipo",
      value: 50,
    },
    {
      name: "habilidad manual",
      value: 50,
    },
    {
      name: "fuerza fisica",
      value: 50,
    },
    {
      name: "inteligencia",
      value: 50,
    },
  ]
  information.data = [
    {
      name: "data 1:",
      value: "50",
    },
    {
      name: "data 2:",
      value: "50",
    },
    {
      name: "data 3:",
      value: "50",
    },
    {
      name: "data 4:",
      value: "50",
    },
    {
      name: "data 5:",
      value: "50",
    },
    {
      name: "data 6:",
      value: "50",
    },
  ]
  return(
    <div className="vh-20 border border-2 rounded-4 d-flex w-100">
      <div className="d-flex w-30 py-2">
        <div className="row w-75 ms-3 align-items-center">
          {
            information.data?
            information.data.map((d) => (
              <div key={d.name} className="col-6">
                <Paragraph contentTwo={d.name} className="fs-candidate-detail-resume fw-bold m-0"/>
                <Paragraph contentTwo={d.value} className="fs-candidate-detail-resume m-0 text-truncate"/>
              </div>
            ))
            :
            (
              <Paragraph contentTwo={Dictionary.thereIsNotDataString} className="text-center"/>
            )
          }
        </div>
      </div>
      <div className="border-start h-85 align-self-center"></div>
      <div className="w-65 ms-4">
        <div className="row h-100 w-100 d-flex align-items-center">
          {
            information.skills?
            information.skills.map((s) => (
              <div key={s.name} className="col-3 text-center p-0 ">
                <Paragraph contentTwo={s.name} className="fs-candidate-detail-resume color-blue m-0 my-2 fw-bold text-truncate"/>
                <ProgressCircular value={s.value} icon={s.icon}/>
                <Paragraph contentTwo={s.value + "/100"} className="fs-candidate-detail-resume mt-2 fw-bold" />
              </div>
            ))
            :
            (
              <Paragraph contentTwo={Dictionary.noSkillsAssessed} className="text-center" />
            )
          }
        </div>
      </div>
    </div>
  )
}