import * as Constants from "../../redux/constants";

import axios from "axios";

export function get(url: string){
  return axios.get(`${process.env.REACT_APP_API_URL}/${url}`, {
    headers: {
      'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
    }
  })
}
export function post(url: string, data: any){
  return axios.post(`${process.env.REACT_APP_API_URL}/${url}`, data, {
    headers: {
      'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
    }
  })  
}
export function postBlob(url: string, data: any){
  return axios.post(`${process.env.REACT_APP_API_URL}/${url}`, data, {
    responseType: 'blob',
    headers: {
      'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
    }
  })  
}
export function put(url: string, data: any){
  return axios.put(`${process.env.REACT_APP_API_URL}/${url}`, data, {
    headers: {
      'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
    }
  })
}
export function deleted(path: string){
  return axios.delete(`${process.env.REACT_APP_API_URL}/${path}`, {
    headers: {
      'Authorization' : `Bearer ${Constants.myStorage.getItem("token")}`
    }
  })
}