import * as Dictionary from "../../constants/dictionary";

let count:number;
let initPagination:number;
let finishPagination:number;

export default function Pagination({pages_number, state, setState, method = null }) {
  let pagination: number[]=[];
  count = state;
  if(count === 1){
    initPagination = 1;
    finishPagination = pages_number;
    if(finishPagination > 5){
      finishPagination = 5;
    };
    count = 1;
  };

  for(let i = initPagination; i <= finishPagination; i++){
    pagination.push(i);
  };

  const nextPage = () : void => {
    if(state < pages_number){
      setState((old:number) : number => old + 1);
    };
    if(state - initPagination > 1 && pages_number - initPagination > 4) {
      initPagination++;
    };
    if(finishPagination - state < 3 && finishPagination < pages_number){
      finishPagination++;
    };
    if(method !== null){
      method(null, null, state + 1);
    }
  };

  const prevPage = () : void => {
    if(state > 1){
      setState(old => old - 1);
    };
    if(state - initPagination < 3 && initPagination > 1 ){
      initPagination--;
    };
    if(finishPagination - state > 1 && finishPagination > 5 ){
      finishPagination--;
    };
    if(method !== null){
      method(null, null, state - 1);
    }
  };

  const onPage = (p: number ) : void => {
    setState(p);
    if(p > 2 && (p < pages_number - 1)){
      finishPagination = p + 2;
      initPagination = p - 2;
    } else if(p === 2 ) {
      initPagination = p - 1;
      if( p + 3 < pages_number ) finishPagination = p + 3;
    } else if( p === pages_number - 1 ) {
      finishPagination = p + 1;
      if(p - 3 > 0) initPagination = p - 3;
    };
    if(method !== null){
      method(null, null, p);
    }
  };
  return (
    <>
      <nav aria-label="Page navigation">
        <ul className="pagination">
          <li className="page-item pointer"><span className="page-link fs-8" onClick={prevPage} >{Dictionary.previous}</span></li>
          {
            pagination?.map((page)=> (
              <li key={page} className='page-item pointer'><span className="page-link fs-8" onClick={()=> onPage(page)}>{page}</span></li>
            ))
          }
          <li className="page-item pointer"><span className="page-link fs-8" onClick={nextPage} >{Dictionary.next}</span></li>
        </ul>
      </nav>
    </>
  );
};
