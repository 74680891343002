import { validTokenLogin } from "../../../../common/domain/services/token";
let count : number = 0;
const GuardedRouteRegister = ({ isLoggedIn, children }) => {
  while (count === 0) {
    validTokenLogin('/login');
    count++;
  }
  return children;
};

export default GuardedRouteRegister;

