import "./FormText.scss";

export default function FormText({id, name, label = "", placeholder = "", defaultValue = "", classLabel = "fs-form-text-0 mx-4 mb-1 fw-bold", required=false,
  classInput = "text-center w-85 py-2 rounded-4 border fs-form-text-0 mb-2 mx-3", classDiv= "d-flex flex-column"}) {
  if(defaultValue.length === 0){
    defaultValue = null
   };
  
  return (
    <div className={classDiv}>
      <label htmlFor={`${id}`} className={classLabel}>{label}</label>
      {
        required ? 
        <input id={`${id}`} name={`${name}`} placeholder={placeholder} defaultValue={defaultValue} className={classInput} type="text" required />
        : <input id={`${id}`} name={`${name}`} placeholder={placeholder} defaultValue={defaultValue} className={classInput} type="text" />
      }
      
    </div>
  )
}
