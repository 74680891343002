import * as Redux from "react-redux";
import * as React from "react";
import * as PositionServices from "../../services/PositionServices";
import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import SkillCard from "../../../../common/domain/molecule/SkillCard/SkillCard";
import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import CreateButton from "../../../../common/domain/molecule/create-button/create-button";
import CheckIcon from "../../../../common/domain/atoms/CheckIcon/CheckIcon";

import "./position-edit-organism-skills.scss";

export default function PositionEditOrganismSkills({selected, setSelected}) {
  React.useEffect(()=>{
    PositionServices.getAllSkills();
  },[]);
  const [state, setState] = React.useState({
    searchSkills : undefined
  });
  function onClick (information) {
    if(selected.includes(information['uuid'])){
      let newSkills = selected.filter((skillId : any)=> {
        return skillId !== information['uuid']
      });
      setSelected(newSkills);
    } else {
      setSelected([...selected, information['uuid']])
    }
  }
  const skillsData = Redux.useSelector(state => state['skills']);
  const searchSkillsFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchSkills: undefined});
    } else {
      let newUsers = skillsData?.data?.filter((skill : any) => {
        skill.name = skill.name.toLowerCase();
        return skill.name.includes(e.target.value.toLowerCase());
      });
      setState({...state, searchSkills: newUsers});
    };
  };
  return (
    <div className="h-40">
      <Paragraph contentTwo={Dictionary.skills_string + " :"} className="mb-2 ms-2 fs-general-title border-bottom" />
      <div className="d-flex">
        <SearchBar onKeyUp={searchSkillsFunction} type='text' placeholder={Dictionary.searchString} id='searchSkill' name='searchSkill' className="rounded-4 wr-searchbar ps-5 pe-2 py-2 fs-btn-label border me-3"/>
        <CreateButton target={'editSkills'} paragraph={Dictionary.create + " " + Dictionary.skillString.toLowerCase() + " +"} />
      </div>
      <div className="h-80 d-flex flex-wrap overflow-auto">
        {
          state.searchSkills === undefined ?
          skillsData?.data?.map((p : any) => (
            <div onClick={() => onClick(p)} className={selected.includes(p.uuid) ? "m-2 rounded-4 shadow pointer position-relative" : 'm-2 pointer'}>
              <SkillCard information={p}/>
              {
                selected.includes(p['uuid']) &&
                <div className="position-absolute end-0 top-0 translate-check-icon">
                  <CheckIcon className="bi bi-check-circle-fill color-blue "/>
                </div>
              }
            </div>
          )) :
          state.searchSkills?.map((p : any) => (
            <div onClick={() => onClick(p)} className={selected.includes(p.uuid) ? "m-2 rounded-4 shadow pointer position-relative" : 'm-2 pointer'}>
              <SkillCard information={p}/>
              {
                selected.includes(p['uuid']) &&
                <div className="position-absolute end-0 top-0 translate-check-icon">
                  <CheckIcon className="bi bi-check-circle-fill color-blue "/>
                </div>
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}
