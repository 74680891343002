import * as Dictionary from "../../constants/dictionary";

import MegaphoneIcon from "../../atoms/MegaphoneIcon/MegaphoneIcon";
import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./test-card-two.scss";

export default function TestCardTwo({information = {}}) {
  let candidateOvercome : string = "";
  let bgColor : string = "";
  if (information["status"]) {
    candidateOvercome = Dictionary.overcomeString;
    bgColor = "bg-color-green";
  } else {
    candidateOvercome = Dictionary.noOvercomeString
    bgColor = "bg-color-orange";
  }
  return (
    <div className="wr-test-card-two hr-test-card-two border border-2 rounded-4 p-2 d-flex flex-column align-items-center">
      <MegaphoneIcon className="bi bi-megaphone mt-1 color-blue fs-test-card-two-0" />
      <div className="w-90 h-30">
        <Paragraph contentTwo={information["name"]} className="fs-test-card-two-1 m-0 fw-bold text-wrap text-center" />
      </div>
      <Paragraph contentTwo={candidateOvercome} className={`m-0 fs-test-card-two-1 ${bgColor} border px-3 py-1 text-white rounded-4 mb-1`} />
      {
        information["updated_at"] !== null ?
        <div className="w-90">
          <Paragraph contentOne={Dictionary.terms["updated_at"] + " : " + information["updated_at"]} capital={false} className={"text-secondary fs-test-card-two-2 m-0 text-truncate"}/>
        </div>
        :
        <div className="w-90">
          <Paragraph contentOne={Dictionary.terms["updated_at"] + " : " + information["created_at"]} capital={false} className={"text-secondary fs-test-card-two-2 m-0 text-truncate"}/>
        </div>
      }
    </div>
  )
}
