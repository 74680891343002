import * as Dictionary from "../../constants/dictionary";

import Paragraph from "../../atoms/Paragraph/Paragraph";

export default function SkillsEditModalBodyTestsHeader() {
  return (
    <div className="">
      <Paragraph contentTwo={Dictionary.SelectTheTestThatUWantLinkToSkillString + ":"} className="fs-6 text-center border-bottom" />
    </div>
  )
}
