import CheckedTestCard from "../checked-test-card/checked-test-card";
import CheckIcon from "../../atoms/CheckIcon/CheckIcon";

import "./SkillsEditModalBodyTestsBody.scss";

export default function SkillsEditModalBodyTestsBody({state, dataToShow, data, tests, setTests}) {
  function onClick(uuid : string){
    if(tests.includes(uuid)){
      let newTests = tests.filter((t) => {return t !== uuid });
      setTests(newTests);
    }else{
      setTests([...tests, uuid]);
    }
  }  
  return (
    <div className="overflow-auto d-flex flex-wrap h-70 my-3 me-3">
      {
        state.searchTests !== undefined ?
          state.searchTests?.map((d : any) => (
            <div onClick={() => onClick(d.uuid)} className={tests.includes(d.uuid) ? "my-2 mx-4 wr-checked-test-card hr-checked-test-card rounded-4 pointer shadow position-relative" : "my-2 mx-4 wr-checked-test-card hr-checked-test-card rounded-4 pointer"}>
              <CheckedTestCard information={d} checked={false} />
            </div>
          )) 
          :
          dataToShow?.length > 0 && (typeof data !== "string") && dataToShow.map((d : any) => (
            <div onClick={() => onClick(d.uuid)} className={tests.includes(d.uuid) ? "my-2 mx-4 wr-checked-test-card hr-checked-test-card rounded-4 pointer shadow position-relative" : "my-2 mx-4 wr-checked-test-card hr-checked-test-card rounded-4 pointer"}>
              <CheckedTestCard information={d} checked={false}/>
              {
                tests.includes(d.uuid) &&
                <CheckIcon className="bi bi-check-circle-fill position-absolute color-blue top-0 fs-skills-edit-test-body-0 tl-skills-edit-body-test"  />
              }
            </div>
          ))
      }
    </div>
  )
}
