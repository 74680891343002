import FormsGenerator from "../../organism/FormsGenerator/FormsGenerator"
import * as Crud from "../../organism/crud-CrudGenerator/crud-CrudGenerator"
import * as Dictionary from "../../constants/dictionary"


export default function CreateSkills ({path}) {
  const json = { 
    title: Dictionary.createNewSkillString,
    data: [{
      "tests_questions_type.name": "InputFormText",
      text: Dictionary.nameString,
      name: "name", 
      strict: true,
    }]
  };
  
  return (
    <div>
      <FormsGenerator json={json} path={path} method={Crud.createNewItem} />
    </div>
  )
};