import Paragraph from "../../atoms/Paragraph/Paragraph"
import "./DiscCard.scss";

export default function DiscCard({title, description, type}) {
  return (
    <div className="rounded-4 bg-white w-80 maxh-35 shadow container py-3 position-relative d-flex flex-column justify-content-center">
      <Paragraph contentTwo={title} className="m-0 text-primary fw-bold fs-7" />
      <Paragraph contentTwo={description} className="fs-disc-card-2 m-0 lh-1 w-80 text-justify mt-1" />
      <div id="hexagono2" className="position-absolute end-0 top-0 bottom-0 my-auto d-flex align-items-center justify-content-center">
        <Paragraph contentTwo={type} className="m-0 color-orange fs-disc-card-0 fw-bold text-center" />
      </div>
    </div>
  )
}
