import * as Redux from "react-redux"
import * as React from "react"
import * as Crud from "../crud-CrudGenerator/crud-CrudGenerator"

import CrudGenerator from "../CrudGenerator/CrudGenerator"

export default function ItemDetailOrganismTableDetail ({title, id}) {
  let table : string = "";
  let url : string = "";
  let urlDelete : string = "";
  let table2 : string = "";
  let url2 : string = "";
  let urlDelete2 : string = "";
  let table3 : string = "";
  let url3 : string = "";
  let urlDelete3 : string = "";
  let json = {};
  let json2 = {};
  let json3 = {};

  switch (title) {
    case "skills":
      table = "tests";
      url = `${table}/${title}/${id}`;
      urlDelete = `${table}`;
    break;
      
    case "users":
      table = "tests";
      url = `test_attempt/${id}`;
      urlDelete = `${table}`;
      table2 = "profiles";
      url2 = `${table2}/${title}/${id}`;
      urlDelete2 = `${table2}`;
      table3 = "user_permissions";
      url3 = `${table3}/${title}/${id}`;
      urlDelete3 = `${table3}`;
      
    break;
        
    case "tests" :
      table = "test_questions";
      url = `${table}/${title}/${id}`;
      urlDelete = `${table}`;
    break;
          
    case "test_questions":
      table = "test_question_options";
      url= `question/${table}/${id}`;
      urlDelete = `${table}`;
    break;
  }
  const data = Redux.useSelector(state => state["table"]);
  const data2 = Redux.useSelector(state => state["profilesTable"]);
  const data3 = Redux.useSelector(state => state["permissionsTable"]);

  React.useEffect(()=>{
    Crud.getTableGenerator(url);
    if(title === "users") {
      Crud.getTableProfiles(url2);
      Crud.getTablePermissions(url3);
    };
  },[url]);
  
  if(Array.isArray(data)){
    json={
      title: table,
      data,
      father: title
    };
  } else {
    json ={
      title: table,
      data: [],
      father: title
    };
  };

  if(Array.isArray(data2)){
    json2={
      title: table2,
      data: data2,
      father: title
    };
  } else {
    json2 ={
      title: table2,
      data: [],
      father: title
    };
  };

  if(Array.isArray(data3)){
    json3={
      title: table3,
      data: data3,
      father: title
    };
  } else {
    json3 ={
      title: table3,
      data: [],
      father: title
    };
  };

  return (
    <div className="w-100">
      {
        json["title"] !== "" && Array.isArray(json["data"]) &&
        <CrudGenerator json={json} path={url} uuidFather={id} pathDelete={urlDelete}/>
      }
      {
        title === "users" && 
        <>
        {
          json2["title"] !== "" && Array.isArray(json2["data"]) &&
          <CrudGenerator json={json2} path={url2} uuidFather={id} pathDelete={urlDelete2}/>
        }
        {
          json3["title"] !== "" && Array.isArray(json3["data"]) &&
          <CrudGenerator json={json3} path={url3} uuidFather={id} pathDelete={urlDelete3}/>
        }
        </>
      }
    </div>
  )
}