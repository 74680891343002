import * as React from "react";

import CustomersOrganismMainmenu from "../../organisms/customers-organism-mainmenu/customers-organism-mainmenu";
import CustomersOrganismCards from "../../organisms/customers-organism-cards/customers-organism-cards";

import "./customers-template-body.scss";

export default function CustomersTemplateBody() {
  const states = {order: false, searchCustomers: undefined};
  const [state, setState] = React.useState(states);
  return (
    <>
      <CustomersOrganismMainmenu state={state} setState={setState} />
      <CustomersOrganismCards state={state} />
    </>
  )
}
