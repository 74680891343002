import * as Redux from "react-redux";
import * as Router from "react-router";

import ItemDetailOrganismTableDetail from "../../organism/item-detail-organism-tableDetails/item-detail-organism-tableDetails";

export default function ItemDetailTemplateTableDetails() {
  const detail = Redux.useSelector(state =>  state["detail_item_table"]);
  const params = Router.useParams();
  let tableDetailSelect : boolean = false;
  const tableDetailSelector = () => {
    if(detail.title === "test_questions"){
      if(detail?.data["tests_questions_type.name"] === "SelectInput") {
        tableDetailSelect = true;
      };
    } else {
      tableDetailSelect = true;
    };
  };
  tableDetailSelector();  
  return (
    <>
    {tableDetailSelect && <ItemDetailOrganismTableDetail title={params.section} id={params.id} />}
    </>
  )
}
