import * as Redux from "react-redux"
import * as Dictionary from "../../../../auth/domain/constants/dictionary"
import * as RouterDom from "react-router-dom"

import Navbar from "../../organism/navbar-organism/navbar-organism"
import SideBar from "../../organism/sidebar-organism/sidebar-organism"
import UserIcon from "../../atoms/UserIcon/UserIcon"
import Title from "../../atoms/Title/Title"
import Paragraph from "../../atoms/Paragraph/Paragraph"
import Button from "../../atoms/Button/Button"

export default function UserProfile () {  
  const user = Redux.useSelector(state => state["userName"])
  return(
    <>
    {
      (typeof user !== "string") &&
      <>
      <Navbar />
      <SideBar />
      <div className="d-flex align-items-center flex-column justify-content-center mt-5 pt-5">
        <div>
          <div className="d-flex flex-column align-items-center">
            <UserIcon className={"bi bi-person-circle text-dark"} />
          </div>
          <div className="mb-3">
            <Title content={user?.name} />
          </div>
          <div className="">
            <Paragraph contentOne={"E-mail"} contentTwo={user.email} />
          </div>
          <div>
            <RouterDom.Link to={"/dashboard/user/profile/update"} >
              <Button text={Dictionary.editProfile} className={"btn btn-secondary"} />
            </RouterDom.Link>
          </div>
        </div>
      </div>
      </>
    }
    </>
  )
}