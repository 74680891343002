import * as React from "react";
import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";
import * as Router from "react-router";
import * as CandidateServices from "../../services/CandidatesServices";

import { myStorage } from "../../../../common/redux/constants";

import TestsUserHabeasData from "../../../../common/domain/molecule/TestsUserHabeasData/TestsUserHabeasData";
import TestsUserSliderForm from "../../../../common/domain/organism/TestsUserSliderForm/TestsUserSliderForm";
import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import TestsUserOrganismTabs from "../../../../common/domain/organism/tests-user-organism-tabs/tests-user-organism-tabs";

import "./test-candidate-template-body.scss";

let newQuestions = [];
let preValues = {};
export default function TestCandidateTemplateBody() {
  const [habeas, setHabeas] = React.useState(null);
  const data = Redux.useSelector(state => state["tabTests"]);
  const params = Router.useParams();
  const urlForm : string = `test/attempt/${params.attemptId}/receipt_form`;
  React.useEffect(()=>{
    CandidateServices.getTabTests(`${params.attemptId}`);
  }, [params.attemptId]);

  function createNewQuestion(question : any, test : any){
    
    let tests = myStorage.getItem("tests").split(",")
    let newQuestion = {
      question: question.text,
      uuid: question.uuid,
      data: question.questionOptions,
      questionType: question.questionType.name,
      image: null,
      testId: test.uuid,
    };
    if(question.questionType.name === "imageQuestion" || question.questionType.name === "imageTextQuestion"){
      newQuestion = {
        ...newQuestion,
        image: question.file
      };
    };
    newQuestions.push(newQuestion);
    if ( test.complete === true && !tests.includes(test.uuid) ){
      tests.push(test.uuid);
    }
    myStorage.setItem("tests", tests.toString());
  };
  const createJsonSet = () => {
    preValues = {
      userId: data?.user?.uuid,
      attemptId: params.attemptId
    };
  };
  createJsonSet();

  var complete
  if(data.tests){
    complete = data.tests.filter(item => !item.complete).length === 0
  }

  return (
    <div className="m-1 ">
      {
      myStorage.getItem("habeas") === null && habeas === null && !complete ? <TestsUserHabeasData setHabeas={setHabeas}/> : myStorage.getItem("habeas") === "false" 
        ? null : myStorage.getItem("completed") === "false" && !complete
        ?<>
          <div className="d-flex w-100 align-items-center">
            {
              data["tests"]?.length > 0 &&
              <TestsUserOrganismTabs method={createNewQuestion}/>
            }
          </div>
          <div className="d-flex h-100 flex-column justify-content-center my-5">
            {
              data?.tests?.length > 0 &&
              <TestsUserSliderForm data={newQuestions} preValues={preValues} method={CandidateServices.createResponses} urlResponse={urlForm} testsNumber={data["tests"]?.length}/>
            }
          </div>
          </>
        : <>
          <div className="alert alert-success d-flex align-items-center justify-content-center vh-50">
            <Paragraph contentTwo={Dictionary.thankYouMessageString} className="fs-5 fw-bold text-center m-0" />
          </div>
          </>
        }
    </div>
  )
}
