import * as Dictionary from "../../common/domain/constants/dictionary";


import MenuTemplate from "../../common/domain/template/menu-template/menu-template";
import TemplateHeader from "../../common/domain/template/template-header/template-header";
import ProfilesTemplateBody from "../domain/templates/profiles-template-body/profiles-template-body";
import LoadingSpinner from "../../common/domain/Loading/LoadingSpinner/LoadingSpinner";

import "./feature-profiles.scss";

export default function ProfilesSection() {
  return (
    <>
    <MenuTemplate section={Dictionary.profilesString} />
    <div className="d-flex flex-column align-items-end w-100">
      <div className="vh-15 w-86"></div>
      <LoadingSpinner div={<div className="w-86"><TemplateHeader title={Dictionary.profilesString} subTitle={Dictionary.modifyTheRolesOfEachProfileString + " :"} /><ProfilesTemplateBody/></div>}/>
    </div>
    </>
  )
}
