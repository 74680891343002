import * as ProfileServices from "../../services/ProfileService";
import * as React from "react";

import ProfileEditOrganismHeader from "../../organisms/profile-edit-organism-header/profile-edit-organism-header";
import ProfileEditOrganismGeneralData from "../../organisms/profile-edit-organism-generalData/profile-edit-organism-generalData";
import ProfileEditOganismPermissions from "../../../../common/domain/organism/profile-edit-organism-permissions/profile-edit-organism-permissions";
import ProfileEditOrganismFooter from "../../../../common/domain/molecule/profile-edit-organism-footer/profile-edit-organism-footer";

export default function ProfileEditTemplateForm() {
  const [permissions, setPermissions] = React.useState([]);
  function sendData (e : any) {
    let data = {
      permissions: permissions
    };
    for(let i : number = 0; i < 3; i++){
      data[e.target[i].name] = e.target[i].value
    }
    ProfileServices.createNewProfile(data);
  }
  
  return (
    <div className="modal fade" id="profileEdit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg modal-dialog-centered">
        <form onSubmit={e =>  sendData(e)} className="modal-content rounded-4 vh-80">
          <ProfileEditOrganismHeader />
          <div className="modal-body d-flex flex-column">
            <ProfileEditOrganismGeneralData />
            <ProfileEditOganismPermissions permissions={permissions} setPermissions={setPermissions} />
          </div>
          <ProfileEditOrganismFooter />
        </form>
      </div>
    </div> 
  )
}
