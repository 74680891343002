import * as React from "react";
import * as TestServices from "../../services/TestServices";

import TestEditOrganismHeader from "../../organisms/test-edit-organism-header/test-edit-organism-header";
import TestEditOrganismData from "../../organisms/test-edit-organism-data/test-edit-organism-data";
import TestEditOrganismFooter from "../../organisms/test-edit-organism-footer/test-edit-organism-footer";

export default function TestEditTemplateForm() {
  const [selected, setSelected] = React.useState('');
  function onSubmit(e : any){
    if(selected !== ''){
      let data = {
        name : e.target[0].value,
        skillId : selected,
      }
      TestServices.createNewTest(data);
    } else {
      e.preventDefault();
      alert('Debe llenar todos los campos');
    }
  }
  
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <form onSubmit={e => onSubmit(e)} className="modal-content rounded-4">
        <TestEditOrganismHeader />
        <TestEditOrganismData selected={selected} setSelected={setSelected} />
        <TestEditOrganismFooter />
      </form>
    </div>
  )
}
