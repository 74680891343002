import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as React from "react";
import * as PositionServices from "../../services/PositionServices";

import PositionCard from "../../../../common/domain/molecule/PositionCard/PositionCard";
import PositionEdit from "../../../feature-position-edit/feature-position-edit";
import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import FilterSelector from "../../../../common/domain/molecule/filter-selector/filter-selector";

import "./positionSection-organism-positionCards.scss";

export default function PositionSectionOrganismPositionCards({setState, state}) {
  const optionsSelectFilter = [];
  const dataPosition = [];
  React.useEffect(()=>{
    PositionServices.getAllTests();
    PositionServices.getAllSkills();
  },[])
  const searchPositionFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchPositions: undefined});
    } else {
      let newPositions = dataPosition?.filter((position : any) => {
        position.name = position.name.toLowerCase();
        return position.name.includes(e.target.value.toLowerCase());
      });
      setState({...state, searchPositions: newPositions});
    };
  };
  return (
    <>
    <div className="d-flex justify-content-around mb-3 w-100">
      <PositionEdit/>
      <SearchBar id={'positionSearhbar'} name={'positionSearhbar'} type='text' placeholder={Dictionary.searchString} onKeyUp={searchPositionFunction} />
      <FilterSelector onChange={null} options={optionsSelectFilter} firstOption={Dictionary.filterString} />
    </div>
    <div className="d-flex flex-wrap w-100 overflow-auto vh-65">
      {
        state.searchPositions === undefined ?
        dataPosition?.length > 0 && dataPosition.map((d : any) => (
          <div className="m-3">
            <PositionCard information={d} />
          </div>
        ))
        : state.searchPositions.map((d : any) => (
          <div className="m-3">
            <PositionCard information={d} />
          </div>
          ))
      }
    </div>
    </>
  )
}
