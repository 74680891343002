import * as Dictionary from "../../constants/dictionary";

import Paragraph from "../../atoms/Paragraph/Paragraph";
import EmailIcon from "../../atoms/EmailIcon/EmailIcon";
import FlagIcon from "../../atoms/FlagIcon/FlagIcon";
import PhoneIcon from "../../atoms/PhoneIcon/PhoneIcon";
import CalendarIcon from "../../atoms/CalendarIcon/CalendarIcon";

import "./customer-card.scss";

export default function CustomerCard({information={}}) {
information["phone"] = "Telefono";
information["sector"] = "Sector";
information["nit"] = "Número nit";
information["date"] = "16/06/2022";
  return (
    <div className="wr-customer-card hr-customer-card d-flex flex-column align-items-center justify-content-center border rounded-4 shadow-customer-card">
      <div className="w-60 d-flex flex-column justify-content-between h-80">
        <div className="w-100 d-flex flex-column align-items-center">
          <img className="mx-picture-customer-card wr-picture-customer-card rounded-4 mb-2" src={information["img"]} alt="Img not found" />
          <Paragraph contentTwo={information["name"]} className="fs-customer-card-0 m-0 fw-bold text-truncate" />
          <Paragraph contentTwo={information["sector"]} className={"border-dark text-truncate fs-customer-card-1 mb-2"}/>
          <button className="bg-blue text-white border rounded-4 fs-customer-card-2 fw-bold padding-edit-button">Editar</button>
        </div>
        <div className="border-top" ></div>
        <div className="">
          <div className="d-flex mb-2">
            <EmailIcon className="fs-customer-card-2 bi bi-envelope me-3" />      
            <Paragraph contentTwo={information["email"]} className="m-0 fs-customer-card-2 text-secondary text-truncate"/>
          </div>
          <div className="d-flex mb-2">
            <FlagIcon className="fs-customer-card-2 bi bi-flag me-3" />      
            <Paragraph contentTwo={`Nit. ${information["nit"]}`} className="m-0 fs-customer-card-2 text-secondary text-truncate"/>
          </div>
          <div className="d-flex mb-2">
            <PhoneIcon className="fs-customer-card-2 bi bi-phone me-3" />      
            <Paragraph contentTwo={`(+57) ${information["phone"]}`}className="m-0 fs-customer-card-2 text-secondary text-truncate"/>
          </div>
          <div className="d-flex">
            <CalendarIcon className="fs-customer-card-2 bi bi-calendar me-3" />
            <Paragraph contentTwo={information["date"]} className="m-0 fs-customer-card-2 text-secondary text-truncate"/>
          </div>

        </div>
      </div>
    </div>
  )
}