import * as React from "react";

import CandidatesOrganismCardList from "../../organisms/candidates-organism-cardList/candidates-organism-cardList";
import CandidatesOrganismMainMenu from "../../organisms/candidates-organism-mainmenu/candidates-organism-mainmenu"; 

export default function CandidatesSectionTemplateCardList() {
  const states = {order: false, searchCandidate: undefined, filter: null};
  const [state, setState] = React.useState(states);
  return (
    <div className="w-100">
      <CandidatesOrganismMainMenu state={state} setState={setState}  />
      <CandidatesOrganismCardList state={state}/>
    </div>
  )
}
