import * as Dictionary from "../../constants/dictionary";
import * as Redux from "react-redux";

import { myStorage } from "../../../redux/constants";

import Paragraph from "../../atoms/Paragraph/Paragraph";
import Button from "../../atoms/Button/Button";

export default function TestsUserHabeasData({setHabeas}) {
  const data = Redux.useSelector(state => state['tabTests']);
  const startClock = () => {
    let minStater : number, secStater : number ;
    if(data?.testTime === 0) {
      minStater = 59;
      secStater = 59;
    } else {
      minStater = 59 - Math.floor(data?.testTime/60);
      secStater = 60 - (data?.testTime - (Math.floor(data?.testTime/60)* 60));
    }
    myStorage.setItem("tests", "");
    myStorage.setItem("sec", `${secStater}`);
    myStorage.setItem("min", `${minStater}`);
    myStorage.setItem("hrs", "0");
    myStorage.setItem("activeTimer", "true");
  };
  const iAgreeFunction = () => {
    myStorage.clear();
    startClock();
    setHabeas(true);
    myStorage.setItem("habeas", "true");
    myStorage.setItem("completed", "false");
  };
  const iNotAgreeFunction = () => {
    setHabeas(false);
    myStorage.setItem("habeas", "false");
  };  
  return (
    <div className="container my-5 d-flex flex-column align-items-center justify-content-center">
      <Paragraph contentTwo={Dictionary.processingOfPersonalDataString} className="mb-4" />
      <Paragraph contentTwo={Dictionary.habeasDataParagraphOne} className={"fs-8"}/>
      <Paragraph contentTwo={Dictionary.habeasDataParagraphTwo} className="fs-8"/>
      <Paragraph contentTwo={Dictionary.habeasDataParagraphThree} className="fs-8"/>
      <div className="d-flex justify-content-around w-50">
        <div onClick={iAgreeFunction}>
          <Button text={Dictionary.iAgreeString} className="btn btn-primary fs-8"/>
        </div>
        <div onClick={iNotAgreeFunction}>
          <Button text={Dictionary.iNotAgreeString} className="btn btn-secondary fs-8"/>
        </div>
      </div>
    </div>
  )
}
