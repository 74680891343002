import "./App.css";
import * as RouterDom from "react-router-dom";

import { myStorage } from "./common/redux/constants";

//Guards
import GuardedRouteRegister from "./auth/domain/Guarded/GuardedRouteRegister/GuardedRouteRegister";
import GuardedRouteNoRegister from "./auth/domain/Guarded/GuardedRouteNoRegister/GuardedRouteNoRegister";

//Pages
import Register from "./auth/feature-register/Register";
import Recovery from "./auth/feature-recovery/Recovery";
import ChangePassword from "./auth/feature-changePassword/ChangePassword";
import Dashboard from "./dashboard/domain/templates/Dashboard/Dashboard";

import Detail from "./common/domain/pages/feature-item-detail/feature-item-detail";
import Update from "./common/domain/molecule/UpdateItem/UpdateItem";
import CreateItem from "./common/domain/molecule/CreateItem/CreateItem";
import PositionSection from "./position/feature-position-section/feature-position-section";
import UsersSection from "./users/feature-users-section/feature-users-section";
import FeatureCandidates from "./cadidates/feature-candidates/feature-candidates";
import FeatureEvaluations from "./cadidates/feature-evaluations/feature-evaluations";
import ProfilesSection from "./profiles/feature-profiles/feature-profiles";
import SkillsSection from "./skills/feature-skills-section/feature-skills-section";
import Customers from "./customers/feature-customers/feature-customers";
import Sessions from "./sessions/feature-sessions/feature-sessions";

import Logout from "./auth/feature-logout/Logout";
import UserProfile from "./common/domain/molecule/UserProfile/UserProfile";
import UserUpdate from "./common/domain/molecule/UserUpdate/UserUpdate";
import SelectorCrudGenerator from "./common/domain/pages/feature-crud-generator/feature-crud-generator";
import TestsCandidates from "./cadidates/feature-tests-candidates/feature-tests-candidates";

import Pruebas from "./common/domain/organism/Pruebas";
import FeatureLogin from "./auth/feature-login/feature-login";
import SessionDetails from "./sessions/feature-session-details/feature-session-details";
import FeatureDashboard from "./dashboard/feature-dashboard/feature-dashboard";

let isLoggin : any = myStorage.getItem("isLoggin");
isLoggin === "true" ? isLoggin = true : isLoggin = false;

function App() {
  return (
    <div>
      <RouterDom.Routes>
        <RouterDom.Route path="/dashboard" element={<GuardedRouteRegister isLoggedIn={isLoggin}><FeatureDashboard/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/dashboard/table/:section/create/:father/:id" element={<GuardedRouteRegister isLoggedIn={isLoggin}><CreateItem/></GuardedRouteRegister>}/>

        <RouterDom.Route path="/cargos" element={<GuardedRouteRegister isLoggedIn={isLoggin}><PositionSection/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/usuarios" element={<GuardedRouteRegister isLoggedIn={isLoggin}><UsersSection/></GuardedRouteRegister>}/>  
        <RouterDom.Route path="/candidatos" element={<GuardedRouteRegister isLoggedIn={isLoggin}><FeatureCandidates/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/evaluaciones" element={<GuardedRouteRegister isLoggedIn={isLoggin}><FeatureEvaluations/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/perfiles" element={<GuardedRouteRegister isLoggedIn={isLoggin}><ProfilesSection/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/destrezas" element={<GuardedRouteRegister isLoggedIn={isLoggin}><SkillsSection/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/clientes" element={<GuardedRouteRegister isLoggedIn={isLoggin}><Customers/></GuardedRouteRegister>}/>

        <RouterDom.Route path="/sesiones" element={<GuardedRouteRegister isLoggedIn={isLoggin}><Sessions/></GuardedRouteRegister>} />
        <RouterDom.Route path="/sesiones/:id" element={<GuardedRouteRegister isLoggedIn={isLoggin}><SessionDetails/></GuardedRouteRegister>} />

        <RouterDom.Route path="/dashboard/table/:section/detail/:id" element={<GuardedRouteRegister isLoggedIn={isLoggin}><Detail/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/dashboard/table/:section/update/:id" element={<GuardedRouteRegister isLoggedIn={isLoggin}><Update/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/dashboard/tablas/:section" element={<GuardedRouteRegister isLoggedIn={isLoggin}><SelectorCrudGenerator/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/dashboard/user/profile" element={<GuardedRouteRegister isLoggedIn={isLoggin}><UserProfile/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/dashboard/user/profile/update" element={<GuardedRouteRegister isLoggedIn={isLoggin}><UserUpdate/></GuardedRouteRegister>}/>
        <RouterDom.Route path="/attempt/:attemptId"  element={<TestsCandidates />} />
        <RouterDom.Route path="/attempt/:attemptId/test/:testId"  element={<TestsCandidates />} />

        <RouterDom.Route path="/login" element={<GuardedRouteNoRegister isLoggedIn={isLoggin}><FeatureLogin/></GuardedRouteNoRegister>}/>
        <RouterDom.Route path="/" element={<GuardedRouteNoRegister isLoggedIn={isLoggin}><FeatureLogin/></GuardedRouteNoRegister>}/>
        <RouterDom.Route path="/logout" element={<Logout />} />
        {/* <RouterDom.Route path="/register" element={<Register />} /> */}
        <RouterDom.Route path="/recovery" element={<Recovery />} />
        <RouterDom.Route path="/changePassword" element={<ChangePassword />} />
        
        <RouterDom.Route path="/pruebas" element={<Pruebas/>}  />
      </RouterDom.Routes>
    </div>
  );
}

export default App;
