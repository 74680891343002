import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./template-header.scss";
export default function TemplateHeader({title, subTitle}) {
  return (
    <div className="mb-2 mx-3 m-0 d-flex align-items-center">
      <Paragraph contentTwo={title} className="fs-header-title mx-4" />
      <Paragraph contentTwo={subTitle} className="fs-header-title2 border-start ps-4"/>
    </div>
  )
}
