import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import FormText from "../../../../common/domain/atoms/FormText/FormText";

import "./customers-edit-organism-generalData.scss";

export default function CustomersEditOrganismGeneralData() {
  const classDiv = "d-flex flex-column";
  const classInput = "text-center w-85 py-2 rounded-10px border fs-label-customer-edit mb-2 mx-3";
  const classLabel = "fs-label-customer-edit mx-4 mb-1 fw-bold";
  return (
    <div className="h-60">
      <Paragraph contentTwo={Dictionary.generalDataString + " :"} className="ms-2 fs-general-title mb-2" />
      <div className="border-top d-flex w-100 h-80">
        <div className="w-30 d-flex flex-column aling-items-center mt-4">
          <img className="rounded-4 wr-previewImg mb-2 mx-previewImg" src="https://picsum.photos/id/20/300/300" alt="Img not found" />
          <label className="btn fs-label-customer-edit color-blue fw-bold m-0 p-0" htmlFor="updata-customer-picture">Subir foto</label>
          <input id="updata-customer-picture" className="item-hidden" type="file" accept="image/*"/>
        </div>
        <div className="w-70 mb-2 mt-4 d-flex justify-content-center">
          <div className="w-50">
            <FormText id={"name"} name={"name"} label={Dictionary.nameString} placeholder={Dictionary.nameString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
            <FormText id={"documentNumber"} name={"documentNumber"} label={Dictionary.identificationString} placeholder={Dictionary.identificationString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
            <FormText id={"email"} name={"email"} label={Dictionary.emailString} placeholder={Dictionary.emailString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
          </div>
          <div className="w-50">
            <FormText id={"phone"} name={"phone"} label={Dictionary.phoneString} placeholder={Dictionary.phoneString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
            <FormText id={"andress"} name={"andress"} label={Dictionary.andressString} placeholder={Dictionary.andressString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
            <FormText id={"city"} name={"city"} label={Dictionary.cityString} placeholder={Dictionary.cityString} classDiv={classDiv} classInput={classInput} classLabel={classLabel} />
          </div>
        </div>
      </div>
    </div>
  )
}
