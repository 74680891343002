import * as Dictionary from "../../constants/dictionary";
import * as React from "react";
import * as Crud from "../../organism/crud-CrudGenerator/crud-CrudGenerator";
import * as Redux from "react-redux";

import FormsGeneratorTwo from "../../organism/FormsGeneratorTwo/FormsGeneratorTwo";
import MenuTemplate from "../../template/menu-template/menu-template";

export default function CreateTestQuestionOptions({path, defaultValue = ""}) {
  const url2 = `test_questions`
  let optionsSelector = Redux.useSelector(state => state["tableOptions"])
  React.useEffect(()=>{
    Crud.getTableOptions(url2)
  },[url2])
  optionsSelector = optionsSelector?.filter((option)=>{
    return option["test_question_name"] === "SelectInput"
  })
  const json = {
    title: "Crear una opción",
    data: [
      {
        "tests_questions_type.name": "InputFormText",
        name: "name",
        text: Dictionary.nameString,
        strict: true
      },
      {
        "tests_questions_type.name": "SelectInput",
        text: Dictionary.selectQuestionString,
        options: optionsSelector,
        name: "testQuestionId",
        strict: true,
        defaultValue
      },
      {
        "tests_questions_type.name": "InputFile",
        name: "image"
      },
      {
        "tests_questions_type.name": "SelectRadio",
        name: "valid",
        options: [
          {
            name: Dictionary.yesString, value: "true"
          }, 
          {
            name: Dictionary.noString, value: "false"
          }
        ],
        text: Dictionary.isCorrectOption,
        strict: true
      }
    ]
  }
  return (
    <>
    <MenuTemplate />
    <div className="d-flex flex-column align-items-end w-100 vh-100">
      <div className="h-15 w-86"></div> 
      <div className="w-86">
        <FormsGeneratorTwo json={json} path={path} method={Crud.createNewItem} reload={true} />
      </div>
    </div>
    </>
  )
}
