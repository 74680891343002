import * as Dictionary from "../../constants/dictionary";

import AssignTestsGroupModal from "../../../../tests/feature-assign-testsGroup/feature-assign-testsGroup";
import AssignTestsModal from "../../../../tests/feature-assign-tests-modal/feature-assign-tests-modal";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import TestCardTwo from "../test-card-two/test-card-two";

import "./CandidateDetailTests.scss";


export default function CandidateDetailTests({information}) {
  return (
    <div className="border border-2 p-2 rounded-4">
      <div className="d-flex mt-2">
        <div className="d-flex flex-column wr-candidate-detail-test hr-candidate-detail-test justify-content-center ms-3 rounded-4 bg-color-blue align-items-center">
            <div className="my-2">
              <AssignTestsModal information={information} />
            </div>
            <div className="my-2">
              <AssignTestsGroupModal information={information} />
            </div>
        </div>
        <div className="w-80 ms-3 me-3 d-flex overflow-auto">
          {
            information.attemptTests?.length > 0 ?
              information.attemptTests?.map((test : any) => (
                <div className="me-3">
                  <TestCardTwo information={test}/>
                </div>
              ))
            :
            (
              <Paragraph contentTwo={Dictionary.noTestsAssigned} className="" />
            )
          }
        </div>
      </div>
    </div>
  )
};
