import * as Dictionary from "../../constants/dictionary";
import * as React from "react";
import * as Redux from "react-redux";

import Button from "../../atoms/Button/Button";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import InputForm from "../../atoms/InputForm/InputForm";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import SelectRadio from "../../atoms/SelectRadio/SelectRadio";
import InputFile from "../../atoms/InputFile/InputFile";


export default function FormsGeneratorTwo({json, path, method, reload = false, preValues={}}) {
  const switchAtoms = (d : any) => {
    switch (d["tests_questions_type.name"]) {
      case "InputFormText":
        let textInputFormText = d.text;
        if (d.strict === true){
          textInputFormText = textInputFormText + " *";
        };
        return (
          <div className="m-4">
            <InputForm id={d.uuid} name={d.name} text={textInputFormText} type={"text"} expression={null} infoMsg={d.infoMsg} required={d.strict} defaultValue={d.defaultValue} />
          </div>
        );

      case "InputFile":
        return (
          <div className="m-4">
            <InputFile name={d.name} />
          </div>
        );

      case "InputFormNumber":
        let textInputFormNumber = d.text;
        if (d.strict === true){
          textInputFormNumber = textInputFormNumber + " *"
        } ;
        return (
          <div className="m-4">
            <InputForm id={d.uuid} name={d.name} text={textInputFormNumber} type={"number"} expression={null} infoMsg={d.infoMsg} required={d.strict} defaultValue={d.defaultValue} />
          </div>
        );

      case "SelectInput":
        let textSelectInput : string = d.text;
        if(d.strict){
          textSelectInput = textSelectInput + " *"
        };
        return (
          <div className="m-4">
            <SelectInput id={d.uuid} name={d.name} text={textSelectInput} options={d.options} required={d.strict} defaultValue={d.defaultValue}/>
          </div>
        );
        
      case "SelectRadio":
        let titleRadio: string = d.text;
        if(d.strict) titleRadio = titleRadio + " *";
        return (
          <div className="m-4 mt-0">
            <SelectRadio id={d.uuid} text={titleRadio} options={d.options} name={d.name} required={d.strict} />
          </div>
        );

      case "Checkbox":
        let textCheckbox : string = d.text;
        if(d.strict) textCheckbox = textCheckbox + " *";
        return (
          <div className="m-4">
            <Checkbox id={d.uuid} name={d.name} text={textCheckbox} required={d.strict}/>
          </div>
        );

      default:
        return null;
    };
  };
  const [complete, setComplete] = React.useState(null);
  const onSubmit = (e : any) => {
    e.preventDefault();
    let data : any;
    let formData = new FormData();
    data = {};
    for( let i = 0; i < e.target.length - 1; i++){
      if(e.target[i].hasOwnProperty("checked")){
        if(e.target[i].checked && e.target[i].name !== "checkbox") {
          switch (e.target[i].value) {
            case "true":
              data[e.target[i].name] = true;
            break;

            case "false":
              data[e.target[i].name] = false;
            break;

            default:
              data[e.target[i].name] = e.target[i].value;
            break;
          }
        }
      } else {
        if(e.target[i].name === "image"){
          formData.append("file", e.target[i].files[0]);
        } else {
          data[e.target[i].name] = e.target[i].value;
        }; 
      };
    };
    formData.append("data", JSON.stringify(data));
    method(path, formData);
    setComplete(true);
    if(reload){
      setTimeout(()=>{
        window.location.reload();
      },1500);
    };
  };
  return (
    <>
      {
        complete
        ?
        <div className="d-flex flex-column align-items-center">
          <p className="alert alert-success p-5 mt-5">{Dictionary.alertCompleteForm}</p> 
        </div>
        :
        <div className="d-flex flex-column align-items-center">
          <h3 className="fs-5">{json.title}</h3>
          <h6 className="fs-8">{Dictionary.strictModeForm}</h6>
          <form className="w-50 p-3" onSubmit={(e)=>onSubmit(e)}>
            {
              json.data?.map((d:any) => (
                  <div key={d["tests_questions_type.name"]}>
                    {switchAtoms(d)}
                  </div>
              ))
            }
            <Button text={Dictionary.buttonSend} className="btn btn-primary mx-4 px-5 mb-5 fs-8" />
          </form>
          {
            complete === false &&
            <div className="alert alert-danger">{Dictionary.alertNocompleteForm}</div>
          }
        </div>
      }
    </>
  );
};
