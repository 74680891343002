import * as Dictionary from "../../constants/dictionary";
import * as Redux from "react-redux";
import * as AuthServices from "../../services/AuthServices";
import * as Entities from "../../entities/user";

import InputForm from "../../../../common/domain/atoms/InputForm/InputForm";
import Button from "../../../../common/domain/atoms/Button/Button";

export default function AuthOrganismFormRecovery() {
  const alertMsg = Redux.useSelector(state => state["alertMsg"])
  const onSubmit = (e: any): void => {    
    e.preventDefault()
    let data : Entities.RecoveryPassword = {
      email: e.target[0].value
    }
    AuthServices.recoveryPassword(data);
  }  
  return (
    <>
    {
    alertMsg === "Correo invalido" || alertMsg === "" &&
    <>
      <h3 className="text-center">{Dictionary.recoveryPassWord}</h3>
      <form onSubmit={(e: any) => onSubmit(e)}>
        <InputForm text={Dictionary.email} type={Dictionary.typeEmail} expression={null} infoMsg={Dictionary.mailError} required={true}/>
        <div className="text-center">
          <Button className={"btn btn-primary px-md-4"} text={Dictionary.recoveryText} />
        </div>
      </form>
    </>
    }
    </>
  )
}
