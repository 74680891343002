import * as Dictionary from "../../../../common/domain/constants/dictionary";

import CreateButton from "../../../../common/domain/molecule/create-button/create-button";

import "./profile-edit-organism-trigger.scss";

export default function ProfileEditOrganismTrigger() {
  return (
    <CreateButton target={'profileEdit'} paragraph={Dictionary.create + " " + Dictionary.profileString.toLowerCase() + " +"}/>
  )
}
