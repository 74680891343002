import * as Dictionary from "../../../../common/domain/constants/dictionary";

import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import TimeSetToCandidatesDataASection from "../../../../common/domain/organism/TimeSetToCandidatesDataASection/TimeSetToCandidatesDataASection";
import TimeSetToCandidatesDataBSection from "../../../../common/domain/organism/TimeSetToCandidatesDataBSection/TimeSetToCandidatesDataBSection";
import TimeSetToCandidatesDataTitle from "../../../../common/domain/molecule/TimeSetToCandidatesDataTitle/TimeSetToCandidatesDataTitle";

import "./evaluations-organism-setData.scss";

export default function EvaluationsOrganismSetData({method, setDate, setCompleteTest, completeTest, date}) {
  function onChangeTestCompeted():void{setCompleteTest(!completeTest)}
  function sendData():void{method(date[0], date[1])};  
  return (
    <div className="border rounded-4 p-4 w-50 mb-2">
      <TimeSetToCandidatesDataTitle />
      <div className="d-flex justify-content-around align-items-center">
        <TimeSetToCandidatesDataASection method={method} setState={setDate} />
        <Paragraph contentTwo={Dictionary.orString} className="m-0" />
        <TimeSetToCandidatesDataBSection method={method} setState={setDate}/>
      </div>
      <div className="text-center">
        <input id={"candidatesCheckbox"} onChange={onChangeTestCompeted} type="checkbox" className="me-2"/>
        <label htmlFor="candidatesCheckbox" className="">{Dictionary.CandidatesWithPendingTestsString}</label>
      </div>
      <div className="text-center">
        <button className="fs-7 btn border rounded-4" onClick={sendData} disabled={typeof date[0] != 'string' || typeof date[1] != 'string'}>{Dictionary.buttonSend}</button>
      </div>
    </div>
  )
}
