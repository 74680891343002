import Navbar from "../../organism/navbar-organism/navbar-organism";
import SideBar from "../../organism/sidebar-organism/sidebar-organism";

export default function MenuTemplate({section = ""}) {
  return (
    <>
    <Navbar />
    <SideBar section={section} />
    </>
  )
}
