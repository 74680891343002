import * as Dictionary from "../../constants/dictionary";

import Paragraph from "../../atoms/Paragraph/Paragraph";
import Icon from "../../atoms/Icon/Icon";
import CheckIcon from "../../atoms/CheckIcon/CheckIcon";

import "./ProfileCard.scss";

export default function ProfileCard({data, selected = "", setState = null}) {
  const classNameCard = "d-flex flex-column align-items-center justify-content-center h-100";
  function selectedFunction () {
    setState(data.uuid)
  }
  return (
    <div id={data?.uuid} onClick={selectedFunction} className="border wr-profileCard hr-profileCard rounded-4 mx-auto mt-3 position-relative cursor-pointer">
      {
        selected === data.uuid &&
        <div className="position-absolute end-0 top-0 translate-check-icon">
          <CheckIcon className="bi bi-check-circle-fill color-blue "/>
        </div>
      }
      <div className={selected === data.uuid ? classNameCard + " shadow-card-profile rounded-4" : classNameCard}>
        <div className="h-45 fs-icon-profile-card color-blue d-flex align-items-center">
          <Icon className={data.icon} />
        </div>
        <div className="h-20">
          <Paragraph contentTwo={Dictionary.terms[data?.name]} className="fs-name-profile-card fw-bold text-center" />
        </div>
        <div className="h-20">
          <button type="button" className="fs-10 border rounded-4 py-1 px-4 text-white bg-color-blue">{Dictionary.editString}</button>
        </div>
      </div>
    </div>
  )
};
