export default function  ParagraphTwo({contentOne = "", contentTwo = "", className = "", capital=true}) {
  if(capital){
    if(contentOne !== "") {
      contentOne = contentOne?.charAt(0).toUpperCase() + contentOne?.slice(1);
    };
    contentTwo = contentTwo?.charAt(0).toUpperCase() + contentTwo?.slice(1);
  };
  return (
    <p className={className}>{ contentOne }<br/>{contentTwo}</p>
  );
};