import * as Dictionary from "../../constants/dictionary";

import Paragraph from "../../atoms/Paragraph/Paragraph";
import EmailIcon from "../../atoms/EmailIcon/EmailIcon";
import PhoneIcon from "../../atoms/PhoneIcon/PhoneIcon";
import CandidateDetail from "../../../../cadidates/feature-candidate-detail/feature-candidate-detail";
import GeoAltIcon from "../../atoms/GeoAltIcon/GeoAltIcon";
import BriefcaseIcon from "../../atoms/BriefcaseIcon/BriefcaseIcon";
import PlusIcon from "../../atoms/PlusIcon/PlusIcon";

import "./candidate-card.scss";

export default function CandidateCard({information = {}}) {
  return (
    <div className="wr-candidate-card hr-candidate-card d-flex justify-content-center align-items-center border rounded-4">
      <div className="w-90 d-flex justify-content-between align-items-center">
        <div className="position-relative">
          <PlusIcon className="bi bi-plus color-blue position-absolute fs-5 tl-candidate-card-0 start-0 top-0" />
          <img className="wr-candidate-picture rounded-circle" src={information["img"]} alt="Img not found" />
          <PlusIcon className="bi bi-plus color-blue position-absolute bottom-0 tl-candidate-card-1 fs-candidate-card-0 fw-bold" />
        </div>
        <div className="w-30 h-100">
          <div className="d-flex justify-content-between align-items-end my-2 h-50">
            <Paragraph contentTwo={information["name"]} className={"m-0 fs-candidate-card-1 text-wrap fw-bold"}/>
            <Paragraph contentTwo="Disponible" className="m-0 fs-candidate-card-3 text-white bg-green border rounded-3 px-2 py-1" />
          </div>
          <div className="border-top"></div>
          <div className="py-2 d-flex align-items-center">
            <div className="d-flex align-items-center w-50">
              <GeoAltIcon className="bi bi-geo-alt color-blue me-2 fs-candidate-card-0" />
              <Paragraph contentTwo="Ciudad" className="m-0 fs-candidate-card-2" />
            </div>
            <div className="d-flex align-items-center w-50">
              <BriefcaseIcon className="bi bi-briefcase color-blue me-2 fs-candidate-card-0" />
              <Paragraph contentTwo="Modalidad" className="m-0 fs-candidate-card-2" />
            </div>
          </div>
        </div>
        <div className="w-30 h-100">
          <div className="d-flex justify-content-between align-items-end my-2 h-50">
            <Paragraph contentTwo={Dictionary.informationString} className={"m-0 fs-candidate-card-1 text-wrap fw-bold"}/>
          </div>
          <div className="border-top"></div>
          <div className="py-2 d-flex align-items-center">
            <div className="d-flex align-items-center w-55">
              <EmailIcon className="bi bi-envelope color-blue me-2 fs-candidate-card-0" />
              <Paragraph contentTwo={information["email"]} className="m-0 fs-candidate-card-2 text-truncate" />
            </div>
            <div className="d-flex align-items-center ms-1">
              <PhoneIcon className="bi bi-phone color-blue me-2 fs-candidate-card-0" />
              <Paragraph contentTwo={`(+57) ${information["phone"]}`} className="m-0 fs-candidate-card-2" />
            </div>
          </div>
        </div>
        <div className="w-16 d-flex flex-column h-100">
          <div className="h-50 w-100 d-flex align-items-end my-1">
            <button className="btn fs-candidate-card-2 border-color-blue rounded-4 color-blue fw-bold w-100" >Editar</button>
          </div>
          <div className="h-50 w-100 my-1">
            <CandidateDetail information={information}/>
          </div>
        </div>
      </div>
    </div>
  )
}
