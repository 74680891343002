import * as React from "react";
import * as Dictionary from "../../constants/dictionary";
import * as ReactRouter from "react-router";

import { myStorage } from "../../../redux/constants";

import MultipleSelection from "../../molecule/MultipleSelection/MultipleSelection";
import ImageMultipleSelection from "../../molecule/ImageMultipleSelection/ImageMultipleSelection";
import ImageTextMultipleSelection from "../../molecule/ImageTextMultipleSelection/ImageTextMultipleSelection";
import InputText from "../../molecule/InputText/InputText";
import InputNumber from "../../molecule/InputNumber/InputNumber";
import Button from "../../atoms/Button/Button";
import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./TestsUserSliderForm.scss";

let count = 0, countTwo : number = 0;
let states = {};
let statesTimer = {};

export default function TestsUserSliderForm({data, preValues = {}, method, urlResponse = "", testsNumber}) {
  const params = ReactRouter.useParams();
  
  if(data?.length > 0 && count < 2){
    for (let i = 0; i < data.length; i++){
      states[data[i].uuid] = {
        questionId: data[i].uuid,
        question: data[i].question,
        questionOptions: data[i]?.data,
        questionResponseValue: null,
        children: false,
        questionType: data[i].questionType,
        image: null,
        testId: data[i].testId
      };
      if(data[i].questionType === "imageQuestion" || data[i].questionType === "imageTextQuestion") {
        states[data[i].uuid] = {
          ...states[data[i].uuid],
          image: data[i].image
        };
      };
      statesTimer[data[i].uuid] = {
        timeResponse : 0,
      };
      data[i].data?.forEach((option : any) => {
        if(option.options?.length !== 0){
          states[option.uuid] = {
            questionId: data[i].uuid,
            questionOptionId: option.uuid,
            question: option.name,
            questionOptions: option.options,
            questionResponseValue: null,
            children: true,
            father: data[i].uuid,
            testId: data[i].testId
          };
          statesTimer[option.uuid] = {
            timeResponse : 0,
          };
        };
      });
    };
    count++;
  };
  
  const [state, setState] = React.useState([states]);
  const [active, setActive] = React.useState(false);
  const [stateTimer, setStateTimer] = React.useState([statesTimer]);
  const [sendResponses, setSendResponses] = React.useState(false);

  function selectQuestion(){
    if(params.testId === undefined) {
      return(
        <div className="w-100 d-flex justify-content-center align-items-center">
          <iframe src="https://view.genial.ly/62e3d03dfa93660018c0f7eb" className="vh-70 vw-70"></iframe>
        </div>
      )
    } else {
      let i : number = 0;
        for(let key in state[0]){
          if(state[0][key].questionResponseValue === null && state[0][key].testId === params.testId && (state[0][key].children !== true || state[0][state[0][key].father].questionResponseValue === state[0][key].questionOptionId)){
            switch (state[0][key].questionType) {
              case "imageTextQuestion":
                return(
                  <div className={active ? "animate__animated animate__backOutLeft animate__faster":"animate__animated animate__backInRight animate__faster"}>
                    <ImageTextMultipleSelection image={state[0][key].image} options={state[0][key].questionOptions} question={state[0][key].question} state={state} setState={setState} stateKey={key} setActive={setActive} stateTimer={stateTimer} setStateTimer={setStateTimer}/>
                  </div> 
                );

              case "InputFormText":
                return(
                  <div className={active ? "animate__animated animate__backOutLeft animate__faster":"animate__animated animate__backInRight animate__faster"}>
                    <InputText question={state[0][key].question} state={state} stateKey={key} setActive={setActive} setState={setState} />
                  </div> 
                );
              
                case "InputFormNumber":
                return(
                  <div className={active ? "animate__animated animate__backOutLeft animate__faster":"animate__animated animate__backInRight animate__faster"}>
                    <InputNumber question={state[0][key].question} state={state} stateKey={key} setActive={setActive} setState={setState}/>
                  </div> 
                );
    
              case "imageQuestion":
                return(
                  <div className={active ? "animate__animated animate__backOutLeft animate__faster":"animate__animated animate__backInRight animate__faster"}>
                    <ImageMultipleSelection image={state[0][key].image} options={state[0][key].questionOptions} question={state[0][key].question} state={state} setState={setState} stateKey={key} setActive={setActive} stateTimer={stateTimer} setStateTimer={setStateTimer}/>
                  </div> 
                );
              
              default:
                return (
                  <div className={active ? "animate__animated animate__backOutLeft animate__faster":"animate__animated animate__backInRight animate__faster"}>
                    <MultipleSelection options={state[0][key].questionOptions} question={state[0][key].question} state={state} setState={setState} stateKey={key} setActive={setActive} stateTimer={stateTimer} setStateTimer={setStateTimer} />
                  </div> 
                );
            };
          } else {
          i++
        };
      };
      if(sendResponses){
        return (
          <div className="alert-success d-flex align-items-center justify-content-center  ">
            <Paragraph contentTwo={Dictionary.responsesSendedSuccessfullyString} className="m-0 p-5" />
          </div>
        );
      }
      return (
        <div onClick={submitForm} className="text-center">
          <Button text={"Enviar formulario"} />
        </div>
      );
    };
  };

  function createData (state, key, responses){
    let data = {
      userId: preValues["userId"],
      attemptId: preValues["attemptId"],
      questionId: state[key].questionId,
      value: null,
      questionOptionId: null,
      timeResponse: stateTimer[0][key].timeResponse
    };
    if(state[key].questionType === "SelectInput" || state[key].questionType === "imageQuestion" || state[key].questionType === "imageTextQuestion"){data.questionOptionId = state[key].questionResponseValue}
    else data.value = state[key].questionResponseValue;
    responses.push(data);
  }

  function submitForm(){
    let responses = [];
    if(Object.keys(preValues).length > 0){
      for(let key in state[0]){
        if(state[0][key].testId === params.testId) {
          createData(state[0], key, responses);
        };
      };
    };
    let tests = myStorage.getItem("tests").split(",");
    tests.push(params.testId);
    myStorage.setItem('tests', tests.toString())
    method(urlResponse, responses);
    setSendResponses(true);
    responses = [];    
    setTimeout(() => {
      window.location.replace(`/attempt/${params.attemptId}`);
    }, 2000)

    if(testsNumber + 1  === tests.length){
      myStorage.setItem("completed", "true");
      setTimeout(() => {
        myStorage.clear();
      },2000)
    }

    if(myStorage.getItem('min') === "0" && myStorage.getItem('sec') === "0"){
      myStorage.setItem("completed", "true");
      setTimeout(() => {
        let tests = myStorage.getItem("tests").split(",")
        for (let key in state[0]) {
          if(!tests.includes(state[0][key].testId)){
            createData(state[0], key, responses);
          };
        };
        method(urlResponse, responses);
        setSendResponses(true);
      },1000)
    };
  };
  if(myStorage.getItem('min') === "0" && myStorage.getItem('sec') === "0" && countTwo === 0) {
    submitForm();
    countTwo++;
  };
  return (
    <div>
      {
        Object.keys(state[0]).length > 0 &&
        selectQuestion()
      }
    </div>
  );
};
