import * as Dictionary from "../../constants/dictionary";

import CreateButton from "../create-button/create-button";
import SearchBar from "../search-bar/search-bar";
import FilterSelector from "../filter-selector/filter-selector";

import "./SkillsEditModalBodyTestsMainMenu.scss";

export default function SkillsEditModalBodyTestsMainMenu({state, setState, data}) {
  const searchTestsFunction = (e : any) => {
    if(e.target.value === "") {
      setState({...state, searchTests: undefined});
    } else {
      let newTests = data?.filter((test : any) => {
        test.name = test.name.toLowerCase();
        return test.name.includes(e.target.value);
      });
      setState({...state, searchTests: newTests});
    };
  };
    const optionsSelectFilter = []
  return (
    <form className="ms-2 d-flex w-85 justify-content-between">
      <SearchBar onKeyUp={e => searchTestsFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchBarTest"} name={"searchBarTest"}/>
      <CreateButton target={'testEditModal'} paragraph={Dictionary.createTestString + " +"} />
      <FilterSelector onChange={null} options={optionsSelectFilter} firstOption={Dictionary.filterString}/>
   </form>
  )
}
