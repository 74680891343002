import * as React from "react";
import * as PositionServices from "../../services/PositionServices";
import PositionEditOrganismGeneralData from "../../organisms/position-edit-organism-generalData/position-edit-organism-generalData";
import PositionEditOrganismSkills from "../../organisms/position-edit-organism-skills/position-edit-organism-skills";
import PositionEditOrganismFooter from "../../organisms/position-edit-organism-footer/position-edit-organism-footer";
import PositionEditOrganismHeader from "../../organisms/position-edit-organism-header/position-edit-organism-header";
import "./position-edit-template-form.scss";
export default function PositionEditTemplateForm() {
  const [selected, setSelected] = React.useState([]);
  function onSubmit(e){
    if(selected.length > 0){
      let data = {
        name: e.target[1].value,
        city: e.target[2].value,
        contact: e.target[3].value,
        category: e.target[4].value,
        valididy: e.target[5].value,
        phone: e.target[6].value,
        skillsIds: selected
      }
      PositionServices.createNewPosition(data);
      alert('enviado');
    } else {
      e.preventDefault();
      alert('Llena todos los campos')
    }
  }
  return (
    <form onSubmit={(e) => onSubmit(e)} className="modal-content px-5 rounded-4 vh-position-edit vw-position-edit d-flex align-items-center">
      <PositionEditOrganismHeader />
      <div className="modal-body w-80">
        <PositionEditOrganismGeneralData />
        <PositionEditOrganismSkills selected={selected} setSelected={setSelected} />
      </div>
        <PositionEditOrganismFooter />
    </form>
  )
}
