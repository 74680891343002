import * as React from "react";

import UserSectionOrganismBody from "../../organisms/users-section-organism-body/users-section-organism-body";
import UsersSectionOrganismMainMenu from "../../organisms/users-section-organism-mainMenu/users-section-organism-mainMenu";

export default function UserSectionTemplatesBody() {
  const states = {order: false, searchUsers: undefined};
  const [state, setState] = React.useState(states);
  return (
    <>
    <UsersSectionOrganismMainMenu state={state} setState={setState} />
    <UserSectionOrganismBody state={state}/>
    </>
  )
}
