export const REPORT_TERMS = {
  title: 'INFORME DE RESULTADOS',
  creativity: 'Creatividad',
  lateralThinking: 'Pensamiento lateral',
  lateralTinking: 'Pensamiento lateral',
  responseInhibition: 'Inhibición de respuestas',
  recoursion: 'Recursividad',
  numericEstimation: 'Estimación numérica',
  logicalThinking: 'Pensamiento lógico',
  selectiveAttention: 'Atención selectiva',
  earlyMemory: 'Memoria a corto plazo',
  probabilisticReasoing: 'Razonamiento probabilístico',
  disc: 'DISC'
}