import * as React from "react";
import * as Redux from "react-redux";
import * as AuthServices from "../../../../auth/domain/services/AuthServices";
import * as RouterDom from "react-router-dom";

import ParagraphTwo from "../../atoms/ParagraphTwo/ParagraphTwo";
import BellIcon from "../../atoms/BellIcon/BellIcon";
import ThreeDotsVertical from "../../atoms/ThreeDotsVerticalIcon/ThreeDotsVertical";
import GearIcon from "../../atoms/GearIcon/GearIcon";
import BoxArroyRight from "../../atoms/BoxArroyRight/BoxArroyRight";

import "./navbar-organism.scss";

const logo = require("../../../../assets/logoBlanco.png");

export default function Navbar () {
  let userName = Redux.useSelector(state => state["userName"]);
  React.useEffect(() => {
    AuthServices.getUser();
  }, []);
  return (
    <>
    <nav className="navbar bg-body border-bottom position-fixed p-0 w-86 h-10 end-0 index-10">
      <div className="container-fluid d-flex justify-content-between">
        <div className="ms-5 d-flex align-items-center w-25">
            <img className="logo" src={logo} alt="Img not found"/>
            <ParagraphTwo contentOne="Talent" contentTwo={"Blocks"} className={"m-0 fs-talent-blocks fw-bold text-center lh-1 text-wrap"}/>
        </div>
        <div className="d-flex align-items-center">
          <RouterDom.Link to={''} className='text-dark'>
            <BellIcon className="bi bi-bell mx-2 fs-navbar-button"/>
          </RouterDom.Link>
          <RouterDom.Link to={'/changePassword'} className='text-dark'>
            <GearIcon className="bi bi-gear mx-2 fs-navbar-button"/>
          </RouterDom.Link>
          <RouterDom.Link to={'/dashboard/user/profile'} className='text-dark'>
            <img className="mx-2 user-picture" src="https://picsum.photos/id/823/250/250" alt="" />
          </RouterDom.Link>
          <RouterDom.Link to={'/logout'} className='text-dark'>
            <BoxArroyRight className="bi bi-box-arrow-right mx-2 fs-navbar-button"/>
            {/* <ThreeDotsVertical className="bi bi-three-dots-vertical mx-2 fs-navbar-button"/> */}
          </RouterDom.Link>
        </div>
      </div>
    </nav>
    </>
  )
}