import { get, post } from "../../../common/domain/services/api";
import store from "../../../common/redux/store/index";
import * as Constants from "../../../common/redux/constants";

export function getAllTests (){
  get(`tests`)
  .then(res => store.dispatch({type: Constants.GET_ALL_TESTS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getAllSkills (){
  get(`skills`)
  .then(res => store.dispatch({type: Constants.GET_ALL_SKILLS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function createNewPosition(data){
  post('position', data)
  .then((res) => {store.dispatch({type: Constants.CREATE_ITEM_IN_TABLE, payload: res.data})})
  .catch(error => {throw new Error (error)})  
}