import * as Dictionary from "../../constants/dictionary";
import * as Router from "react-router";
import * as Redux from "react-redux";
import * as React from "react";
import * as Crud from "../crud-CrudGenerator/crud-CrudGenerator";

import Title from "../../atoms/Title/Title";
import Paragraph from "../../atoms/Paragraph/Paragraph";

export default function ItemDetailOrganismDetailCard () {
  const params = Router.useParams();
  const detail = Redux.useSelector(state =>  state["detail_item_table"]);
  React.useEffect(()=>{
      Crud.getItemDetail(`${params.section}`, params.id)
  },[params.section, params.id]);
  let title : string = "";
  if(Dictionary.terms.hasOwnProperty(detail.title)){
    title = Dictionary.terms[detail.title];
  };
  let newData = [];
  for( let key in detail.data){
    if(Dictionary.terms[key]){
      let newKey = Dictionary.terms[key]
      let newItemData = {
        key : newKey,
        value: detail.data[key],
      };
      newData.push(newItemData);
    } else {
      let newItemData = {
        key : key,
        value : detail.data[key] 
      };
      newData.push(newItemData);
    }
  }
  
  
  return (
    <div className="container w-75 border rounded-4">
      <Title key={title} className="text-center fs-6 p-2 border-bottom" content={title}/>
      <div className=" row">
        {
          newData?.map((d)=> (
          <div key={d.key} className="col-lg-4 col-12">
          {
            d.key !== undefined && d.key !== "uuid" &&
            (
              Dictionary.terms.hasOwnProperty(d.value) 
              ? <Paragraph  key={d.key} contentOne={d.key} contentTwo={Dictionary.terms[d.value]} className={"fs-8"}/>
              : <Paragraph  key={d.key} contentOne={d.key} contentTwo={d.value} className={"fs-8"}/>
            )
          }
          </div>
          ))
        }
      </div>
    </div>
  )
}