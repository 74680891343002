
import Paragraph from "../../atoms/Paragraph/Paragraph";

import "./create-button.scss";

export default function CreateButton({target, paragraph, className = "btn border color-blue rounded-4 fs-btn-label fw-bold d-flex align-items-center wr-btn-12 justify-content-center"}) {
  return (
    <button type="button" className={className} data-bs-toggle="modal" data-bs-target={`#${target}`}>
      <Paragraph contentTwo={paragraph} className="m-0" />
    </button>
  )
}
