import * as Dictionary from "../../constants/dictionary"
import * as Redux from "react-redux";
import * as React from "react";
import * as Crud from "../crud-CrudGenerator/crud-CrudGenerator";

import Paragraph from "../../atoms/Paragraph/Paragraph"
import PermissionMiniCard from "../../molecule/PermissionMiniCard/PermissionMiniCard"

export default function ProfileEditOganismPermissions({permissions, setPermissions}) {
  const data = Redux.useSelector(state => state["permissionsTable"]);
  const url : string = `user_permissions`;
  React.useEffect(()=>{
    Crud.getTablePermissions(url);
  },[url]);
  let permissionsList = [
    {
      uuid: "permission1",
      name: "permiso 1"
    },
    {
      uuid: "permission2",
      name: "permiso 2"
    },
    {
      uuid: "permission3",
      name: "permiso 3"
    },
    {
      uuid: "permission4",
      name: "permiso 4"
    },
    {
      uuid: "permission5",
      name: "permiso 5"
    },
    {
      uuid: "permission6",
      name: "permiso 6"
    },
    {
      uuid: "permission7",
      name: "permiso 7"
    },
    {
      uuid: "permission8",
      name: "permiso 8"
    },
    {
      uuid: "permission9",
      name: "permiso 9"
    },
    {
      uuid: "permission10",
      name: "permiso 10"
    },
    {
      uuid: "permission11",
      name: "permiso 11"
    },
    {
      uuid: "permission12",
      name: "permiso 12"
    },
  ];
  return (
    <div className="w-90 mx-auto">
      <Paragraph contentTwo={Dictionary.permissionsString + " :"} className="mt-4 mb-2 pb-2 border-bottom fw-bold fs-7" />
      <div className="d-flex justify-content-between flex-wrap">
        {
          permissionsList?.map((permission : any) => (
            <div className="w-30">
              <PermissionMiniCard data={permission} state={permissions} setState={setPermissions} />
            </div>
          ))
        }
      </div>
    </div>
  )
}
