import FormsGeneratorTwo from "../../../../common/domain/organism/FormsGeneratorTwo/FormsGeneratorTwo";
import * as DepartmentServices from "../../services/DepartamentSevices";
import * as Dictionary from "../../../../common/domain/constants/dictionary";

export default function CreateDepartamentOrganismForm({path}) {
  const json = {
    title: "Crear un departamento",
    data: [
      {
        "tests_questions_type.name": "InputFormText",
        name: "name",
        text: Dictionary.nameString,
        strict: true,
      },
    ]
  };
  
  return (
    <div>
      <div className="d-flex flex-column align-items-end w-100 vh-100">
        <div className="h-15 w-86"></div>
        <div className="w-86 h-85">
          <FormsGeneratorTwo json={json} path={path} method={DepartmentServices.createNewDepartment} reload={true}/>
        </div>
      </div>
    </div>
  )
}
