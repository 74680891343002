import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as TestSerivices from "../../services/TestServices";

import Button from "../../../../common/domain/atoms/Button/Button";

export default function AssignTestModalOrganismFooter({setTest, setAssign, information, test}) {
  const onSubmit = (e : any) : void => {
    let data : any = {
      userId : information.uuid,
      testId : test,
      testGroupId : ""
    };
    TestSerivices.linkUserTest('test_attempt', data);
    setTest("");
    setAssign(true);
    setTimeout(() => {
      window.location.reload()
    }, 1000);
  };
  
  return (
    <div className="w-100 d-flex justify-content-evenly m-0 mb-3 border-top pt-2">
      <div className="w-45 text-center">
        <Button onClick={(e)=> onSubmit(e)} text={Dictionary.acceptChangesString} className="btn border rounded-4 shadow fs-8 p-2 px-3" />
      </div>
      <div className="border"></div>
      <div className="w-45 text-center">
        <button type="button" className="btn border rounded-4 shadow px-5 fs-8" data-bs-dismiss="modal">{Dictionary.cancelString}</button>
      </div>
    </div>
  )
};