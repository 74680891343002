import * as UserServices from "../../../../users/domain/services/UserServices";
import * as Redux from "react-redux";

import CandidateEditModalOrganismHeader from "../../organisms/candidate-edit-modal-organism-header/candidate-edit-modal-organism-header";
import CandidateEditModalOrganismBody from "../../organisms/candidate-edit-modal-organism-body/candidate-edit-modal-organism-body";
import CandidateEditModalOrganismFooter from "../../organisms/candidate-edit-modal-organism-footer/candidate-edit-modal-organism-footer";

import "./candidate-edit-template-form.scss";
export default function CandidateEditTemplateForm() {
  const profiles = Redux.useSelector(state => state["profilesTable"]);
  const filterProfiles = profiles?.data?.filter((p) => {
    return p.name === "candidate";
  });
  
  function onSubmit (e : any) { 
    var data = {
      trash: false,
      enable: false
    }

    for(var i in e.target){
      if(Number.isInteger(parseInt(i))){
        if(e.target[i].id){
          if(e.target[i].value){
            data[e.target[i].id] = e.target[i].value
          }
        }
      }
    }
    data['password'] = ''
    UserServices.createNewUser(data);
    // let message : boolean = false
    // while(message === false){
    //   if(createFeedback !== ''){
    //     alert(createFeedback);
    //     message = true;
    //   }
    // }
    
  }  
  
  return (
    <form onSubmit={(e : any) => onSubmit(e)} className="modal-dialog modal-fullscreen tl-candidate-edit">
      <div className="modal-content vh-candidate-edit vw-candidate-edit px-5 rounded-4">
        <CandidateEditModalOrganismHeader/>
        <div className="modal-body h-75">
          <CandidateEditModalOrganismBody />
        </div>
        <CandidateEditModalOrganismFooter/>
      </div>
    </form>
  )
}
