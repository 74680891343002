import * as React from "react";
import * as Redux from "react-redux"
import * as Dictionary from "../../common/domain/constants/dictionary";
import * as CandidateServices from "../domain/services/CandidatesServices";

import ResultReportTemplateBody from "../domain/templates/result-report-template-body/result-report-template-body";
import Spiner from "../../common/domain/atoms/Spiner/Spiner";
import ResultsReportTemplateHeader from "../domain/templates/results-report-template-header/results-report-template-header";

import "./feature-results-report.scss";

export default function ResultsReportModal({attemptUuid, status}) {
  const userResponses = Redux.useSelector(state => state["userTestResponse"]);
  const verification : boolean =  Object.keys(userResponses).includes("user");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(()=> {
    CandidateServices.getUserTestResponse(attemptUuid);
  },[attemptUuid]);
  function onClick(){
    CandidateServices.getUserTestResponse(attemptUuid);
    loadingFunction();
  };
  function loadingFunction () : void {
    if(!Array.isArray(userResponses)){
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };
  return (
    <>
    {
      verification && 
      <>
      <button onClick={onClick} type="button" className={status ? "btn border fs-8":"btn border fs-8 disabled"} data-bs-toggle="modal" data-bs-target={`#e${attemptUuid}`}>
        {Dictionary.reportString}
      </button>

      <div className="modal fade" id={`e${attemptUuid}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-fullscreen">
          <div className="vh-100 modal-content overflow-auto">
            <ResultsReportTemplateHeader setLoading={setLoading}/>
            {
              loading 
              ? <div className="w-100 d-flex justify-content-center align-items-center"><Spiner /></div>
              : <ResultReportTemplateBody userResponses={userResponses} />
            }
          </div>
        </div>
      </div>
      </>
    }
    </>
  )
}
