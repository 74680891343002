import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";

import CandidateEditModal from "../../../feature-candidate-edit/feature-candidate-edit";
import SearchBar from "../../../../common/domain/molecule/search-bar/search-bar";
import FilterSelector from "../../../../common/domain/molecule/filter-selector/filter-selector";
import OrderButton from "../../../../common/domain/molecule/order-button/order-button";

import "./candidates-organism-mainmenu.scss";

import { post } from "../../../../common/domain/services/api"

let data : any;
export default function CandidatesOrganismMainMenu({state, setState}) {
  let information = Redux.useSelector(state => state['candidates']);
  information.candidates && (data = information.candidates); 
  //BUSCADOR----------------------------------------------------------------------------------------
  const searchCandidatesFunction = (e : any) => {
    let searchTerm: string = e.target.value.toLowerCase()
    if(e.keyCode === 13){
      if(searchTerm === "") {
        setState({...state, searchCandidate: undefined});
      } else {
        // let newCadidates = data?.filter((candiate : any) => {
        //   candiate.name = candiate.name.toLowerCase();
        //   return candiate.name.includes(e.target.value.toLowerCase());
        // });
  
        post('user_search', {
          search: e.target.value.toLowerCase(),
          limit: 1000,
          offset: 0
        }).then(response => {
          setState({
            ...state,
            searchCandidate: response['data']['candidates'],
            candidates: response['data']
          })
        })
      }
    }
  };
  //------------------------------------------------------------------------------------------------
 
  //ORDENAR-----------------------------------------------------------------------------------------
  const orderFunction = () => {
    if(state.order) {
      data.sort((a : any, b : any) => {
        if (a.name > b.name) {return -1}
        if (a.name < b.name) {return 1}
        return 0;
      })
      setState({...state, order: false});
    } else {
      data.sort((a : any, b : any) => {
        if (a.name > b.name) {return 1}
        if (a.name < b.name) {return -1}
        return 0;
      })
      setState({...state, order: true});
    };
  };
  //------------------------------------------------------------------------------------------------
  
  //FILTRADO----------------------------------------------------------------------------------------
  const optionsSelectFilter = [
    {
      uuid: true,
      name: "Link enviado"
    },
    {
      uuid: false,
      name: "Link no enviado"
    },
  ];
  function filterFunction(e : any) {
    let filter : boolean;
    if(e.target.value === "true") filter = true
    else if(e.target.value === "false") filter = false
    else filter = null;
    setState({...state, filter: filter})
  }
  //------------------------------------------------------------------------------------------------
  

  return (
    <div className="ps-5 d-flex ">
      <div className="me-3">
        <CandidateEditModal />
      </div>
      <div className="border me-3"></div>
      <div className="me-5">
        <SearchBar onKeyUp={e => searchCandidatesFunction(e)} placeholder={Dictionary.searchString} type="text" id={"searchBarCandidate"} name={"searchBarCandidate"} className={"rounded-4 wr-candidate-searchbar ps-5 pe-2 py-2 fs-btn-label border"}/>
      </div>
      <div className="me-5">
        <FilterSelector onChange={e => filterFunction(e)} options={optionsSelectFilter} firstOption={Dictionary.filterString} />
      </div>
      <OrderButton onClick={(e) => orderFunction()} text={Dictionary.orderString}  />
    </div>
  )
};
