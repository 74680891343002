import CustomersEditTemplateTrigger from "../domain/templates/customers-edit-template-trigger/customers-edit-template-trigger";
import CustomersEditTemplateBody from "../domain/templates/customers-edit-template-body/customers-edit-template-body";

import "./feature-customers-edit.scss";

export default function CustomersEdit() {
  return (
    <>
    <CustomersEditTemplateTrigger/>
    <div className="modal fade" id="customerEdit" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <CustomersEditTemplateBody/>
    </div>
    </>
  )
}
