
export default function Modal({textBtnP, textTitle, textBody, textBtnO, textBtnT, classNameBtnP = "", method = null, url = "", id= "", uuid = "" }) {
  const onClick = () : void => {
    method(url, uuid)
    setTimeout(() => {
      window.location.reload();
    }, 200);
  };
  return (
    <div>
      <button type="button" className="fs-8" data-bs-toggle="modal" data-bs-target={`#${id}`}>
        {textBtnP}
      </button>

      <div className="modal fade" id={`${id}`} tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">{textTitle.toUpperCase()}</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              {textBody}
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">{textBtnO}</button>
              <button type="button" className="btn btn-primary" onClick={(e)=>onClick()}>{textBtnT}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
