import * as Dictionary from "../../../../common/domain/constants/dictionary";
import * as Redux from "react-redux";

import JoystickIcon from "../../../../common/domain/atoms/JoystickIcon/JoystickIcon";
import FormText from "../../../../common/domain/atoms/FormText/FormText";
import Paragraph from "../../../../common/domain/atoms/Paragraph/Paragraph";
import SkillCard from "../../../../common/domain/molecule/SkillCard/SkillCard";
import CheckIcon from "../../../../common/domain/atoms/CheckIcon/CheckIcon";

export default function TestEditOrganismData({selected, setSelected}) {
  const skillsData = Redux.useSelector(state => state['skills']);
  function onClick (information : any){
    setSelected(information['uuid']);
  }
  const category = [
    {
      uuid: "category1",
      name: "Categoria 1"
    },
    {
      uuid: "category2",
      name: "Categoria 2"
    },
    {
      uuid: "category3",
      name: "Categoria 3"
    },
  ];
  const questionType = [
    {
      uuid: "questionType1",
      name: "Tipo de prueba 1"
    },
    {
      uuid: "questionType2",
      name: "Tipo de prueba 2"
    },
    {
      uuid: "questionType2",
      name: "Tipo de prueba 2"
    },
  ];
  
  return (
    <div className="modal-body h-100 d-flex flex-column p-5">
      <div className="d-flex">
        <div className="d-flex justify-content-center align-items-center">
          <JoystickIcon className="bi bi-joystick fs-0 rounded-4 m-0 color-blue" />
        </div>
        <div className="d-flex flex-column ms-4 w-50 h-50 justify-content-between">
          <FormText id={"name"} name={"name"} label={Dictionary.nameString} placeholder={Dictionary.nameString}/>
        </div>
      </div>
      <div className="mt-4">
        <Paragraph contentTwo={Dictionary.skills_string + " :"} className="border-bottom fw-bold fs-6" />
      </div>
      <div className="d-flex flex-wrap overflow-auto vh-35">
        {
          skillsData?.data?.map((skill) => (
            <div onClick={() => onClick(skill)} className={selected.includes(skill.uuid) ? "m-2 rounded-4 shadow pointer position-relative" : 'm-2 pointer'}>
              <SkillCard information={skill} />
              {
                selected.includes(skill['uuid']) &&
                <div className="position-absolute end-0 top-0 translate-check-icon">
                  <CheckIcon className="bi bi-check-circle-fill color-blue "/>
                </div>
              }
            </div>
          ))
        }
      </div>

    </div>
  )
}
