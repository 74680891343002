export const connection_backend:string = "https://api.causatalento.com";
// export const connection_backend:string = "https://api.qa.causatalento.com"

export const SELECTION_OPTIONS = {
  tableSelector: [
    {name: 'Preguntas', uuid: 'test_questions'},
    {name: 'Opciones de preguntas', uuid: 'test_question_options'},
    {name: 'Tipo de documento', uuid: 'document_type'},
    {name: 'Departamento', uuid: 'departament'},
    {name: 'Municipio', uuid: 'municipaly'}
  ]
}
