import store from "../../../redux/store";
import { get, put, deleted, post } from "../../services/api";
import * as Constants from "../../../redux/constants";

//GET------------------------------------------------------------------------------------

export function getTableGenerator (path:string) {
  get(path)
  .then((res) => {store.dispatch({type: Constants.GET_TABLE, payload: res.data})})
  .catch(error => {throw new Error(error)})
};
export function getTableProfiles (path:string) {
  get(path)
  .then((res) => {store.dispatch({type: Constants.GET_TABLE_PROFILES, payload: res.data})})
  .catch(error => {throw new Error(error)})
};
export function getTablePermissions (path:string) {
  get(path)
  .then((res) => {store.dispatch({type: Constants.GET_TABLE_PERMISSIONS, payload: res.data})})
  .catch(error => {throw new Error(error)})
};

export function getTabTests ( path:string ){
  get(`test_attempt/${path}`)
  .then(res => store.dispatch({type: Constants.GET_TAB_TABLES, payload: res.data}))
  .catch(error => {throw new Error(error)})
};

export function getTableOptions (path:string){
  get(path)
  .then((res) => {store.dispatch({type: Constants.GET_TABLE_OPTIONS, payload: res.data})})
  .catch(error => {throw new Error(error)})
};

export function getOptionsProfiles (path:string){
  get(path)
  .then((res) => {store.dispatch({type: Constants.GET_OPTIONS_PROFILES, payload: res.data})})
  .catch(error => {throw new Error(error)})
};

export function getOptionsPermissions (path:string){
    get(path)
    .then((res) => {store.dispatch({type: Constants.GET_OPTIONS_PERMISSIONS, payload: res.data})})
    .catch(error => {throw new Error(error)})
};

export function getOptionsTests (path:string){
  get(path)
  .then((res) => {store.dispatch({type: Constants.GET_OPTIONS_TESTS, payload: res.data})})
  .catch(error => {throw new Error(error)})
};

export function getTestQuestionTypes (){
  get(`test_question_types`)
  .then(res => store.dispatch({type: Constants.GET_TEST_QUESTION_TYPES, payload: res.data}))
  .catch(error => {throw new Error(error)})
};

export function getItemDetail (path: string, name : string){
  get(`${path}/${name}`)
  .then(res => store.dispatch({type: Constants.GET_ITEM_DETAIL_TABLE, payload: res.data}))
  .catch(error => {throw new Error(error)})
};

export function getQuestionOptions (path : string){
  get(path)
  .then(res => store.dispatch({type: Constants.GET_QUESTION_OPTIONS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getUserTestResponse (attemptUuid: string) {
  get(`valoration_development/${attemptUuid}`)
  .then(res => store.dispatch({type: Constants.GET_USER_TEST_RESPONSE, payload: res.data}))
  .catch(error => {throw new Error ( error )})
};

export function getListUser (path : string) {
  get(path)
  .then(res => store.dispatch({type: Constants.GET_LIST_USER, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getAllSkills (){
  get(`skills`)
  .then(res => store.dispatch({type: Constants.GET_ALL_SKILLS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getAllTestsSkill (skillUuid : string) {
  get(`skills/${skillUuid}`)
  .then(res => store.dispatch({type: Constants.GET_ALL_TESTS_OF_SKILL, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getAllTests (){
  get(`tests`)
  .then(res => store.dispatch({type: Constants.GET_ALL_TESTS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getAllTestsGroup () {
  get(`test_group`)
  .then(res => store.dispatch({type: Constants.GET_ALL_TESTS_GROUP, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

export function getCandidatesAmount () {
  get('user/candidate/length')
  .then(res => store.dispatch({type: Constants.GET_CANDIDATES_AMOUNT, payload: res.data}))
  .catch(error => {throw new Error(error)})
};

//POST------------------------------------------------------------------------------------

export function getExcelReport(data : any){
  post('user/candidate/excel', data)
  .then(res => store.dispatch({type: Constants.GET_EXCEL_REPORT, payload: res.data }))
  .catch(error => {throw new Error(error)})
}

export function getCandidatesWithFilter (data : any) {
  post('user/candidate', data)
  .then( res => store.dispatch({type: Constants.GET_CANDIDATES_WITH_FILTER, payload: res.data}))
  .catch( error => {throw new Error(error)}) 
};

export function getCandidatesFilterAndPagination (data: any){
  post('user/candidate/filter', data)
  .then(res => {store.dispatch({type: Constants.GET_CANDIDATES_FILTER_AND_PAGINATION, payload: res.data})})
  .catch(error => {throw new Error(error)})
};

export function createNewItem ( path : string, data : any){
  post(path, data)
  .then(res => store.dispatch({TYPE: Constants.CREATE_ITEM_IN_TABLE, payload : res.data}))
  .catch(error => {throw new Error(error)})
};

export function createNewCandidate (data: any) {
  post('user', data)
  .then(res => store.dispatch({type: Constants.CREATE_NEW_CANDIDATE, paryload: res.data}))
  .catch(error => {throw new Error(error)});
};

export function createNewItemTwo ( path : string, data : any){
  post(path, data)
  .then(res => store.dispatch({TYPE: Constants.CREATE_ITEM_IN_TABLE, payload : res.data}))
  .catch(error => {throw new Error(error)})
};

export function createResponses (path : string, data : any) {
  post(path, data)
  .then(res => store.dispatch({type: Constants.CREATE_RESPONSES, payload: res.data}))
  .catch(error => {throw new Error(error)})
};

export function linkUserTest (path : string, data : any){
  post(path, data)
  .then(res => store.dispatch({type: Constants.CREATE_LINK_USER_TESTS, payload: res.data}))
  .catch(error => {throw new Error (error)})
};

//PUT-------------------------------------------------------------------------------------

export function updateItem (path : string, data : any){
  put(path, data)
  .then(res => store.dispatch({type: Constants.UPDATA_ITEM_IN_TABLE, payload: res.data}))
  .catch(error => {throw new Error(error)})  
};

//DELETE----------------------------------------------------------------------------------

export function deleteItem (path : string, id: string){
  deleted(`${path}/${id}`)
  .then(res => store.dispatch({type: Constants.DELETE_ITEM_IN_TABLE, payload: res.data}))
  .catch(error => {throw new Error(error)})
};