import * as Dictionary from "../../constants/dictionary";
import * as RouterDom from "react-router-dom";

import Paragraph from "../../atoms/Paragraph/Paragraph";
import MegaphoneIcon from "../../atoms/MegaphoneIcon/MegaphoneIcon";

import "./test-card-one.scss";

export default function TestCardOne ({information = {}, detail = true, overcome = false}) {
  let candidateOvercome : string = ""
  information["name"] = information["name"]?.toLowerCase();
  if (information["status"]) {
    candidateOvercome = Dictionary.overcomeString
  } else {
    candidateOvercome = Dictionary.noOvercomeString
  }

  return (
    <div className="wr-test-card hr-test-card d-flex justify-content-center align-items-center border rounded-4 ">
      <div className={ detail ? "w-90 h-100 d-flex justify-content-between align-items-center m-1 p-1" : "w-90 d-flex justify-content-around align-items-center m-1 p-1" }>
        <MegaphoneIcon className="bi bi-megaphone fs-test-card-one-0 color-blue"/>
        {
          !detail &&
          <div className="border-start p-3 ms-5"></div>
        }
        <div className="h-100 w-50">
          <div className="h-55 mt-2 overflow-hidden">
            <Paragraph contentTwo={information['name']} className={"fs-test-card-one-2 fw-bold m-0 text-truncate text-wrap lh-sm"}/>
          </div>
          <div>
          {
            information["updated_at"] !== null ?
            <div className="d-flex">
              <Paragraph contentOne={Dictionary.terms["updated_at"]} className={"text-secondary text-wrap fs-test-card-one-4 m-0 me-1"}/>
              {/* <Paragraph contentTwo={information["updated_at"]} capital={false} className={"text-secondary fs-test-card-one-4 m-0"}/> */}
              <Paragraph contentTwo={'10/10/2022'} capital={false} className={"text-secondary fs-test-card-one-4 m-0"}/>
            </div>
            :
            <div className="d-flex">
              <Paragraph contentTwo={Dictionary.terms["updated_at"] + ":"} className={"text-secondary fs-test-card-one-4 m-0 me-1 text-wrap"}/>
              {/* <Paragraph contentTwo={information["created_at"]} capital={false} className={"text-secondary fs-test-card-one-4 m-0"}/> */}
              <Paragraph contentTwo={'10/10/2022'} capital={false} className={"text-secondary fs-test-card-one-4 m-0"}/>
            </div>
          }
          </div>
        </div>
        {
          detail &&
          <>
          <div className="text-center my-auto">
            <RouterDom.Link to={`/dashboard/table/tests/detail/${information["uuid"]}`} className={"text-decoration-none fs-test-card-one-3 text-dark fw-bold m-0 color-blue align-middle"} >
              {Dictionary.viewItemTable} {Dictionary.test_string.toLowerCase()}
            </RouterDom.Link>
          </div>
          </>
        }
        {
        overcome &&
        <>
        <div className="border-start border-start"></div>
        <div className="text-center my-auto">
          <Paragraph contentTwo={candidateOvercome} className="fs-test-card-one-1 text-dark fw-bold m-0"/>
        </div>
        </>
        }
      </div>
    </div>
  )
}