import { Chart as ChartJS, CategoryScale, RadialLinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler} from "chart.js";
import { Radar } from "react-chartjs-2";
import * as Dictionary from "../../constants/dictionary";
import Paragraph from "../../atoms/Paragraph/Paragraph";
import "./RadarCard.scss";

ChartJS.register( CategoryScale, RadialLinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

export default function RadarCard({valoration}) {
  let labels : string[] = [];
  let data : number[] = [];
  let highFormation = []
  for(let key in valoration.analysis?.formation) {
    labels.push(key);
    data.push(valoration.analysis.formation[key]);
  }
  for(let key in valoration.analysis?.highFormation){
    highFormation.push({key : key , value : valoration.analysis?.highFormation[key]});
  }
  highFormation = highFormation.sort(function (a, b) {
    if (a.value > b.value) {return -1}
    if (a.value < b.value) {return 1}
    return 0;
  });
  const options = {
    responsive: true,
    scales: {
      r: {
        min: 0,
        pointLabels: {
          font: {
            size: 11,
          },
          padding: 0
        },
        ticks: {
          font: {
            size: 10
          }
        }
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  const dataRadar = {
    labels,
    datasets: [
      {
        label: 'Valoración',
        data : data,
        fill: false,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgb(255,157,0)',
        pointBackgroundColor: 'rgb(255,157,0)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgb(255, 99, 132)'
      },
    ]
  };
  return (    
    <div className="w-100 h-100 px-4 py-2 rounded-4 shadow d-flex flex-column justify-content-center position-relative">
      <Paragraph contentTwo={Dictionary.resultsString} className="m-0 text-center fs-radar-card-0 fw-bold position-absolute top-0 mt-5 mx-auto w-90" />
      <div className="my-1">
        <Radar data={dataRadar} options={options} />
      </div>
      <div className="d-flex justify-content-between w-90 position-absolute mb-4 bottom-0">
      <div className="w-30">
        <Paragraph contentTwo={Dictionary.jobProfileOneString} className="fw-bold m-0 fs-radar-card-1"/>
        <Paragraph contentTwo={highFormation[0].key} className={"m-0 fw-bold text-primary fs-radar-card-1"}/>
      </div>
      <div className="w-30">
        <Paragraph contentTwo={Dictionary.jobProfileTwoString} className="fw-bold m-0 fs-radar-card-1"/>
        <Paragraph contentTwo={highFormation[1].key} className={"m-0 fw-bold text-primary fs-radar-card-1"}/>
      </div>
      <div className="w-30">
        <Paragraph contentTwo={Dictionary.jobProfileThreeString} className="fw-bold m-0 fs-radar-card-1"/>
        <Paragraph contentTwo={highFormation[2].key} className={"m-0 fw-bold text-primary fs-radar-card-1"}/>
      </div>
      </div>
    </div>
  )
}