import * as Dictionary from "../../../../common/domain/constants/dictionary";

import ModalHeader from "../../../../common/domain/molecule/ModalHeader/ModalHeader";

export default function SkillsEditOrganismHeader() {
  return (
    <div className="mt-3">
      <ModalHeader section={Dictionary.skills_string} />
    </div>
  )
}
