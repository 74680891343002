import * as React from "react";
import PositionSectionOrganismSkillCards from "../../organisms/positionSection-organism-skillCards/positionSection-organism-skillCards";
import "./position-section-template-skillCards.scss";
export default function PositionSectionTemplateSkillCards() {
  const [state, setState] = React.useState({
      searchSkills: undefined,
    })
  return (
    <div className="w-100 vh-50 ms-2 mt-2">
      <PositionSectionOrganismSkillCards state={state} setState={setState} />
    </div>
  )
}