import ThisMonthButton from "../../molecule/ThisMonthButton/ThisMonthButton";
import ThisWeekButton from "../../molecule/ThisWeekButton/ThisWeekButton";
import TodayButton from "../../molecule/TodayButton/TodayButton";

export default function TimeSetToCandidatesDataASection({method, setState}) {
  return (
    <div className="d-flex flex-column align-items-center">
      <TodayButton method={method} setState={setState} />
      <ThisWeekButton method={method} setState={setState} />
      <ThisMonthButton method={method} setState={setState} />
    </div>
  )
}
