import TestEditTemplateTrigger from "../domain/templates/test-edit-template-trigger/test-edit-template-trigger";
import TestEditTemplateForm from "../domain/templates/test-edit-template-form/test-edit-template-form";

export default function TestEditModal() {
  return (
    <>
    <TestEditTemplateTrigger/>
    <div className="modal fade" id="testEditModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
      <TestEditTemplateForm />
    </div>
    </>
  )
}
